import React from 'react';
import { Field } from 'formik';
import LocalizedStrings from 'react-localization';
import { Link, Redirect } from 'react-router-dom';

import backgroundImage from '../../assets/images/background/local-shop-interior2.jpg';
import logoText from '../../assets/images/logo-text.png';
import { getStateAndCityFromZipCode } from '../../functions';
import { useSelector } from 'react-redux';

const ThirdWizardForm = (props: any): JSX.Element => {
  const {errorFirebase} = props;
  const [userLanguage] = useSelector((s: any) => [ s.userLanguage ]);
  const localize = new LocalizedStrings({
    us: {
      welcome: 'Let\'s team up and ',
      welcome_: ' bring your business to new heights',
      previous: 'Previous step',
      title: 'Business Address',
      title_contact: 'Contact',
      title_business: 'Business',
      title_address: 'Address',
      placeholder_street_address: 'Street Address',
      placeholder_suite_number: 'Unit / Apt / Suite Number (Optional)',
      placeholder_zip_code: 'Zip Code',
      placeholder_city: 'City',
      placeholder_state: 'State',
      placeholder_homepage: 'Business URL (Optional)',
      submit: 'Submit Application',
      errors: {
        min: 'Please enter at least {min_length} characters',
        max: 'Up to {max_length} characters including spaces',
        required: 'Required field',
        empty_city: 'Please enter the city your business is registered in',
        empty_state: 'Please enter the state your business is registered in',
        invalid_url: 'Missing domain in continue url',
        invalid_zip_code: 'The zip code you entered is invalid',
      }
    },
    jp: {
      welcome: 'ビジネスを新たな高みへと導くため',
      welcome_: 'チームをセットアップしましょう',
      previous: '前の画面に戻る',
      title: '所在地を入力',
      title_contact: '連絡先',
      title_business: '基本情報',
      title_address: '所在地',
      placeholder_street_address: '番地',
      placeholder_suite_number: 'ビル名・階数・部屋番号',
      placeholder_zip_code: '郵便番号',
      placeholder_city: '市区町村',
      placeholder_state: '都道府県',
      placeholder_homepage: 'ホームページ',
      submit: '申請する',
      errors: {
        min: '{min_length}文字以上で入力してください。',
        max: '{max_length}字以内で入力してください。',
        required: '必須項目。',
        empty_city: '市を入力してください。',
        empty_state: '州を選択してください。',
        invalid_url: '接続URLのドメインが見つかりません。',
        invalid_zip_code: 'この郵便番号は無効です。',
      }
    },
  });
  localize.setLanguage(userLanguage === 1 ? 'jp': 'us');

  if (!props.values.officer_name) {
    return <Redirect to="/signup" />;
  }

  const { errors, touched } = props;
  const errors_ = {
    address1: errors.address1,
    address2: errors.address2,
    zip_code: errors.zip_code,
    city: errors.city,
    state: errors.state,
    homepage: errors.homepage,
  };
  const touched_ = {
    address1: touched.address1,
    address2: touched.address2,
    zip_code: touched.zip_code,
    city: touched.city,
    state: touched.state,
    homepage: touched.homepage,
  };

  const onBlur = async (event) => {
    const {handleBlur, setFieldError, setFieldValue, values} = props;
    handleBlur(event);
    if (values.zip_code?.length >= 5) {
      try {
        const info = await getStateAndCityFromZipCode(values.zip_code, userLanguage===1 ? 'ja' : 'en');
        setFieldValue('city', info.city);
        setFieldValue('state', info.state);
        setFieldValue('region', info.region);
      } catch (err) {
        console.error(err);
        setFieldError('zip_code', {message:'invalid_zip_code'});
      }
    } else {
      setFieldValue('city', '');
      setFieldValue('state', '');
    }
  };

  return (
    <section id="wrapper">
      {/** User region */}
      <Field type="hidden" name="region" style={{display: 'none'}} />
      <img className="img-background" src={backgroundImage}/>
      <div className="login-register" >
        <div className="register-box card" style={{borderRadius:'4px'}}>
          <div className="card-body row">
            {/** Form title */}
            <div className="col-md-6">
              <a href="/" className="text-center db" onClick={event => event.preventDefault()}>
                <br />
                <img src={logoText} alt="Home" className="register-login-img" />
              </a>
              <h2 className="text-center register-login-title">ZOOO Local Rewards</h2>
              <p className="text-center business-tagline">
                {localize.welcome}<br />{localize.welcome_}
              </p>
              <div className="text-center">
                <i className="mdi mdi-arrow-left text-danger" />
                {' '}
                <Link to="/signup/basic">{localize.previous}</Link>
              </div>
            </div>

            {/** Form */}
            <div className="col-md-6">
              {/** Wizard Step */}
              <div className="row m-t-20 m-b-20">
                <div className="col-4">
                  <p>1. {localize.title_contact}</p>
                </div>

                <div className="col-4">
                  <p>2. {localize.title_business}</p>
                </div>

                <div className="col-4">
                  <p className="text-danger font-weight-bold">3. {localize.title_address}</p>
                </div>
              </div>

              {/** Input Form Name */}
              <hr className="divider-dots" />
              <h3 className="m-t-20 m-b-10">{localize.title}</h3>

              {/** Fields */}
              <div className="form-horizontal form-material">
                <div className="form-group">
                  <Field type="text" name="address1" placeholder={localize.placeholder_street_address} className="form-control" />
                  {
                    errors.address1 && touched.address1 &&
                    <p className="text-danger">{localize.formatString(localize.errors[errors.address1.message], {min_length: errors.address1.length , max_length: errors.address1.length})}</p>
                  }
                </div>
                <div className="form-group">
                  <Field type="text" name="address2" placeholder={localize.placeholder_suite_number} className="form-control" />
                  {
                    errors.address2 && touched.address2 &&
                    <p className="text-danger">{localize.formatString(localize.errors[errors.address2.message], {min_length: errors.address2.length , max_length: errors.address2.length})}</p>
                  }
                </div>
                <div className="row">
                  <div className="col-4">
                    <div className="form-group">
                      <Field type="text" name="zip_code" placeholder={localize.placeholder_zip_code} className="form-control" onBlur={onBlur} />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-group">
                      <Field type="text" name="city" disabled placeholder={localize.placeholder_city} className="form-control" />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-group">
                      <Field type="text" name="state" disabled placeholder={localize.placeholder_state} className="form-control" />
                    </div>
                  </div>
                  {
                    errors.zip_code &&
                    <div className="col-10" style={{marginTop:'0 !important',marginBottom:'0 !important'}}>
                      <p className="text-danger">{localize.formatString(localize.errors[errors.zip_code.message], {min_length: errors.zip_code.length , max_length: errors.zip_code.length})}</p>
                    </div>
                  }
                </div>
                <div className="form-group">
                  <Field type="text" name="homepage" placeholder={localize.placeholder_homepage} className="form-control" />
                  {
                    errors.homepage && touched.homepage &&
                    <p className="text-danger">{localize.formatString(localize.errors[errors.homepage.message], {min_length: errors.homepage.length , max_length: errors.homepage.length})}</p>
                  }
                </div>
                {
                  errorFirebase.error &&
                    <p className="text-danger">{errorFirebase.message}</p>
                }
                {/** Next page */}
                <div className="form-group text-center">
                  <button
                    disabled={
                      JSON.stringify(touched_) === JSON.stringify({}) || JSON.stringify(errors_) !== JSON.stringify({})
                    }
                    type="submit"
                    className="btn btn-info btn-lg btn-block text-uppercase waves-effect waves-light btn-zooo"
                  >
                    {localize.submit}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ThirdWizardForm;
