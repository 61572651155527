import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

export default class Firebase {
  auth: app.auth.Auth;

  store: app.firestore.Firestore;

  constructor() {
    this.auth = app.auth();
    this.store = app.firestore();
  }

  /** Merge Auth and DB User API */
  onAuthUserListener =
    (next: (u: app.User) => Promise<void>, fallback: any) => this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        next(authUser);
      } else {
        fallback();
      }
    });

  /** Auth API */

  doSignInWithEmailAndPassword = async (
    email: string, password: string,
  ) => {
    const ret = await this.auth.signInWithEmailAndPassword(email, password);
    await ret.user?.getIdToken(true);
  };

  doSignOut = async () => {
    await this.auth.signOut();
  };

  doPasswordReset = (email: string) => this.auth.sendPasswordResetEmail(email);

  /** User API */

  user = (uid: string) => this.store.collection('users').doc(uid).get();

  getCredentialWithEmail = (email: string) => this.store.collection('users').where('email', '==', email).limit(1).get();

  // users = () => {} // this.db.ref('users');
}
