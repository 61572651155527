import React, { useState } from 'react';
import LocalizedStrings from 'react-localization';
import { Form, Input } from 'usetheform';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import Firebase, { withFirebase } from '../Firebase';
import { Link } from 'react-router-dom';

import home from '../../assets/images/logo-text.png';
import wall from '../../assets/images/background/login-register.jpg';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { ACTIVATE_LOADING, DEACTIVATE_LOADING } from '../component/overlay-loading';

const SignIn = (props: any) => {
  document.title = 'Business Manager | Zooo Login';
  const [currentView, setView] = useState(0);
  const [currentState, setState] = useState({
    email: '',
    rememberMe: false,
    failedSignInError: new Error(),
    failedResetError: new Error(),
    sentResetMail: false,
  });
  if (localStorage['remember-me'] && !currentState.rememberMe) {
    setState({ ...currentState, rememberMe: true, email: localStorage['email'] });
  }

  // Language configuration
  const userLanguage = useSelector((s: any) => s.userLanguage);
  const dispatch = useDispatch();
  const localize = new LocalizedStrings({
    en: {
      save_me: 'Remember me',
      forgot_pass: 'Forgot password?',
      sent_reset_mail: 'Sent an email. For password reset, please check your email.',
      reset: 'Reset',
      recover_password: 'Recover Password',
      recover_password_: 'Enter your email and instructions will be sent to you!',
      have_an_account: 'Don\'t have an account?',
      placeholder_username: 'Username',
      placeholder_password: 'Password',
      login: 'Log In',
      sign_up: 'Sign Up',
    },
    jp: {
      save_me: 'ログイン状態を保存する',
      forgot_pass: 'パスワードをお忘れの場合',
      sent_reset_mail: 'パスワードリセットのURLを入力されたアドレスへ送付しました。',
      reset: 'リセット',
      recover_password: 'パスワードを復旧',
      recover_password_: 'パスワードリセットのため、メールアドレスを入力して下さい。',
      have_an_account: 'アカウントを作成しますか？',
      placeholder_username: 'ユーザー名',
      placeholder_password: 'パスワード',
      login: 'ログイン',
      sign_up: '新規登録',
    },
  });
  localize.setLanguage(userLanguage === 1 ? 'jp': 'en');

  // Update language setting
  const updateLanguage = (event: any, language: number) => {
    event.preventDefault();
    dispatch({ type: language===1 ? 'set_japanese' : 'set_english' });
    setView(currentView);
  };

  // Submit for sign-in
  const onSubmitSignIn = (formState: any) => {
    dispatch({ type: ACTIVATE_LOADING });

    const firebase = props.firebase as Firebase;
    const history = props.history;
    const { email, password, isRemember } = formState;

    if (isRemember) {
      localStorage.setItem('remember-me', 'true');
      localStorage.setItem('email', email);
    } else {
      localStorage.setItem('remember-me', '');
      localStorage.setItem('email', '');
    }

    firebase.doSignInWithEmailAndPassword(email, password)
      .then(() => history.push('/'))
      .catch((failedSignInError) => {
        setState({ ...currentState, failedSignInError });
      })
      .finally(() => {
        dispatch({ type: DEACTIVATE_LOADING });
      });
  };

  // Submit for reset password
  const onSubmitResetPassword = (formState: any) => {
    dispatch({ type: ACTIVATE_LOADING });

    const firebase = props.firebase as Firebase;
    const { forgot_email } = formState;

    firebase.doPasswordReset(forgot_email)
      .then(() => {
        setState({ ...currentState, sentResetMail: true });
      })
      .catch((failedResetError) => {
        setState({ ...currentState, failedResetError });
      })
      .finally(() => {
        dispatch({ type: DEACTIVATE_LOADING });
      });
  };

  const signInForm = (
    <Form className="form-horizontal form-material" onSubmit={onSubmitSignIn}>
      {/** Title */}
      <a href="/" onClick={(event) => event.preventDefault()} className="text-center db">
        <img src={home} alt="Home" className="register-login-img" />
      </a>
      <br />
      <h2 className="text-center register-login-title">Business Manager</h2>

      {/* Email Address Field */}
      <div className="form-group m-t-40">
        <div className="col-xs-12">
          <Input
            className="form-control"
            value={currentState.email}
            name="email"
            type="text"
            placeholder={localize.placeholder_username}
          />
        </div>
      </div>

      {/* Password field */}
      <div className="form-group">
        <div className="col-xs-12">
          <Input
            className="form-control"
            name="password"
            type="password"
            placeholder={localize.placeholder_password}
          />
        </div>
      </div>

      {/* Remember me && Reset password form */}
      <div className="form-group">
        <div className="d-flex no-block align-items-center">
          <div className="checkbox checkbox-primary p-t-0">
            <Input id="checkbox-remember-me" name="isRemember" type="checkbox" checked={currentState.rememberMe} />
            <label htmlFor="checkbox-remember-me">{localize.save_me}</label>
          </div>
          <div className="ml-auto">
            <a href="/" onClick={(event) => { event.preventDefault(); setView(1); }} id="to-recover" className="text-muted">
              <i className="fa fa-lock m-r-5" />{' ' + localize.forgot_pass}
            </a>
          </div>
        </div>
      </div>

      {/* Submit button */}
      <div className="form-group text-center m-t-20">
        <div className="col-xs-12">
          <button type="submit"
            className="btn btn-inverse btn-lg btn-zooo btn-block text-uppercase waves-effect waves-light">
            {localize.login}
          </button>
        </div>
      </div>

      {/* Switch locale */}
      <div className="row">
        <div className="col-xs-12 col-sm-12 m-t-10 text-center">
          <div>
            <button onClick={(event) => updateLanguage(event, 2)} className="btn btn-outline-secondary" data-toggle="tooltip" title="Switch to English">
              <i aria-hidden="true" className="flag-icon flag-icon-us" />
            </button>
            {'  '}
            <button onClick={(event) => updateLanguage(event, 1)} className="btn btn-outline-secondary" data-toggle="tooltip" title="Switch to Japanese">
              <i aria-hidden="true" className="flag-icon flag-icon-jp" />
            </button>
          </div>
        </div>
      </div>

      {/** Sign-up link */}
      <div className="form-group m-b-0">
        <div className="col-sm-12 text-center m-t-20">
          {localize.have_an_account + ' '}
          <Link className="text-primary m-l-5" to="/signup">
            <b>{localize.sign_up}</b>
          </Link>
        </div>
      </div><br />

      {currentState.failedSignInError.message &&
        <p className="__error_message">{currentState.failedSignInError.message}</p>}
    </Form>
  );

  const passwordInitializeForm = (
    <Form className="form-horizontal" onSubmit={onSubmitResetPassword}>
      {/** Arrow icon for previous */}
      <div className="form-group">
        <div className="col-xs-12">
          <a onClick={(event) => { event.preventDefault(); setView(0); }} href="/">
            <i className="mdi mdi-chevron-left" />
          </a>
        </div>
      </div>

      {/** Title */}
      <div className="form-group ">
        <div className="col-xs-12">
          <h3>{localize.recover_password}</h3>
          <p className="text-muted">{localize.recover_password_}</p>
        </div>
      </div>

      {/** Reset id */}
      <div className="form-group ">
        <div className="col-xs-12">
          <Input className="form-control" type="email" name="forgot_email" placeholder="Email" />
        </div>
      </div>

      {/** Submit button */}
      <div className="form-group text-center m-t-20">
        <div className="col-xs-12">
          <button className="btn btn-primary btn-lg btn-block text-uppercase waves-effect waves-light" type="submit">
            {localize.reset}
          </button>
        </div>
      </div>

      {/** Result a message */}
      {currentState.sentResetMail &&
        <p className="send_a_mail">{localize.sent_reset_mail}</p>}
      {currentState.failedResetError.message &&
        <p className="__error_message">{currentState.failedResetError.message}</p>}
    </Form>
  );

  // Rendering
  return (
    <section id="wrapper" className="login-register login-sidebar">
      <img className="img-background" src={wall}/>
      <div className="login-box card">
        <div className="card-body">
          {currentView=== 0 && signInForm}
          {currentView=== 1 && passwordInitializeForm}
        </div>
      </div>
    </section>
  );
};

const SignInForm = compose(
  withRouter,
  withFirebase,
)(SignIn);

export default SignInForm;
