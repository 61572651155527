import { Field, FormikErrors, FormikTouched } from 'formik';
import React from 'react';
import LocalizedStrings from 'react-localization';

interface MyProps {
  userLanguage: number;
  values: any,
  touched: FormikTouched<any>,
  errors: FormikErrors<any>,
  prevPage: () => void,
  setFieldValue: any,
  setInputValue: any,
}

export default function FourthForm({userLanguage, values, touched, errors, prevPage, setFieldValue, setInputValue}: MyProps): JSX.Element {
  const localize = new LocalizedStrings({
    jp: {
      title: '新しいクーポンの作成まで少し',
      sub_head_title: 'プレビューを参照し、さらにサブタイトルをつけて調整できます(オプション)',
      sub_head_text: 'スペースを含む最大40文字が入力可能です',
      input_placeholder: '初回のみ有効',
      available_for: 'クーポンはまもなく有効となります',
      delivery: 'クーポン配信設定',
      survey: 'アンケート',
      answer_option: '選択オプション：',
      can_choose: '複数選択可能',
      can_choose_multi: 'Yes',
      cannot_choose_multi: 'No',
      question: 'タイトル: ',
      distribution_on: 'クーポンの配信は、$ ~ $ から開始されます。',
      specific_day_type: '来店数に応じてギフトされます',
      every_checked_type: 'チェックインするとその場で利用可能です',
      specific_count_type: '指定した曜日に応じて利用可能です',
      errors: {
        max: '{max_length}文字以内である必要があります。',
      }
    },
    en: {
      title: 'Your coupon is almost set.',
      sub_head_title: 'See the preview and manually adjust the sub-headline as needed. (Optional)',
      sub_head_text: 'Up to 40 characters including spaces',
      input_placeholder: 'First time customers only',
      available_for: 'This coupon will be available for...',
      delivery: 'Delivery Setting',
      survey: 'Survey',
      answer_option: 'Answer Options:',
      can_choose: 'Can shoppers choose multiple options?',
      can_choose_multi: 'Yes',
      cannot_choose_multi: 'No',
      question: 'Question: ',
      distribution_on: 'Starts distribution on at $ ~ $',
      specific_day_type: 'Shoppers who checked in specific times in the past',
      every_checked_type: 'Shoppers who checked in every time',
      specific_count_type: 'Shoppers can use in the specific day',
      errors: {
        max: 'Must be at most {max_length} characters',
      }
    },
  });
  localize.setLanguage(userLanguage === 1 ? 'jp' : 'en');

  const isDisable = !!errors.sub_headline;

  return (
    <>
      <div className="content clearfix">
        <section className="body current" role="tabpanel" aria-labelledby="steps-uid-0-h-3" aria-hidden={false}>
          <div className="row">
            <h3  className="card-title m-t-30 m-b-30">{localize.title}</h3>
            <div className="col-12">
              <div className="form-group">
                <h4 className="card-title m-t-30 m-b-30">{localize.sub_head_title}</h4>
                <small className="text-right">{localize.sub_head_text}</small>
                <Field name="headline" type="text" className="form-control" maxLength={40} onChange={(e) => {
                  setFieldValue('headline', e.target.value);
                  setInputValue({...values, headline: e.target.value});
                }}/>
                {
                  // Show error headline with localize
                  touched.headline && errors.headline &&
                  <p className="text-danger">{localize.formatString(localize.errors[(errors.headline as any).message || ''], {min_length: (errors.headline as any).length , max_length: (errors.headline as any).length})}</p>
                }

                <small className="text-right">{localize.sub_head_text}</small>
                <Field name="discount_description" type="text" className="form-control" maxLength={40} onChange={(e) => {
                  setFieldValue('discount_description', e.target.value);
                  setInputValue({...values, discount_description: e.target.value});
                }}/>
                {
                  // Show error discount_description with localize
                  touched.discount_description && errors.discount_description &&
                  <p className="text-danger">{localize.formatString(localize.errors[(errors.discount_description as any).message || ''], {min_length: (errors.discount_description as any).length , max_length: (errors.discount_description as any).length})}</p>
                }

                <small className="text-right">{localize.sub_head_text}</small>
                <Field name="sub_headline" type="text" className="form-control" placeholder={localize.input_placeholder} />
                {
                  // Show error sub_headline with localize
                  touched.sub_headline && errors.sub_headline &&
                  <p className="text-danger">{localize.formatString(localize.errors[(errors.sub_headline as any).message || ''], {min_length: (errors.sub_headline as any).length , max_length: (errors.sub_headline as any).length})}</p>
                }
              </div>

              <div className="review">
                <h4 className="card-title m-t-30 m-b-30">{localize.available_for}</h4>
                <ul className="list-unstyled review-ul">
                  <li>
                    <i className="fas fa-check" />
                    &nbsp;&nbsp; {' ' + (values.activation_type === 1 ? localize.every_checked_type : values.activation_type === 3 ? localize.specific_count_type : localize.specific_day_type)}
                  </li>
                </ul>
                <h4 className="card-title m-t-30 m-b-30">{localize.delivery}</h4>
                <ul className="list-unstyled review-ul">
                  <li>
                    <i className="fas fa-check" />
                    &nbsp;&nbsp; {
                      localize.distribution_on
                        .replace('$', values.start_at).replace('$', values.end_at)
                    }
                  </li>
                </ul>
                {
                  values.is_survey && <h4 className="card-title m-t-30 m-b-30">{localize.survey}</h4>
                }
                {
                  values.is_survey && <ul className="list-unstyled review-ul">
                    <li>{localize.question + values.survey.question}</li>
                    <li>{localize.answer_option}</li>
                    <ul className="list-unstyled">
                      {values?.survey?.choices?.map((v,i)=>(<li key={i}>&nbsp;&nbsp;{' '+v}</li>))}
                    </ul>
                  </ul>
                }
                {
                  values.is_survey && <ul className="list-unstyled review-ul">
                    <li>{localize.can_choose}</li>
                    <ul className="list-unstyled">
                      <li>
                        &nbsp;&nbsp;{values.survey.include_multiple_answers ? localize.can_choose_multi : localize.cannot_choose_multi}
                      </li>
                    </ul>
                  </ul>
                }
              </div>
            </div>
          </div>
        </section>
      </div>

      {/** Actions button */}
      <div className="actions clearfix">
        <ul role="menu" aria-label="Pagination">
          <li className="disabled" aria-disabled="true">
            <a href="/coupon-distribute" role="menuitem" style={{backgroundColor:'#fff',border:'1px solid #d9d9d9',color:'#999',boxShadow:'none'}} onClick={(e)=>{
              prevPage();
              e.preventDefault();
            }}>Previous</a>
          </li>
          <li aria-hidden="false" aria-disabled="false" style={{display: 'none'}}>
            <a href="/coupon-preview" role="menuitem">Next</a>
          </li>
          <li aria-hidden="true">
            <button type="submit" role="menuitem" disabled={isDisable} style={{
              backgroundColor: '#009efb',
              color: '#fff',
              display: 'block',
              padding: '7px 12px',
              borderRadius: '4px',
              border: '1px solid transparent',
              textDecoration: 'none',
            }}>Submit</button>
          </li>
        </ul>
      </div>
    </>
  );
}
