/* eslint-disable @typescript-eslint/no-var-requires */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './components/App';
import reportWebVitals from './configs/reportWebVitals';
import app from 'firebase';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import { Provider } from 'react-redux';
import { combineReducers, createStore } from 'redux';
import { firebaseReducer, ReactReduxFirebaseProvider, ReactReduxFirebaseProviderProps } from 'react-redux-firebase';
import { firestoreReducer, createFirestoreInstance } from 'redux-firestore';
import { StoreDocument } from './converters/StoreConverter';
import Firebase, { FirebaseContext } from './components/Firebase';
import ReduxToastr, { reducer as toastrReducer } from 'react-redux-toastr';
import OverlayLoading, { toggleViewLoadingReducer } from './components/component/overlay-loading';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns';
import { LocalizationProvider } from '@material-ui/pickers';
import ReactMapGL from 'react-map-gl';
ReactMapGL;
import mapboxgl from 'mapbox-gl/dist/mapbox-gl';

mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};
app.initializeApp(config);
app.firestore();
app.functions();

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  userLanguage: (state = 1, action: any) => {
    const currentLanguage = parseInt(localStorage.getItem('user-setting.language') + '');
    const language = (() => {
      switch (action.type) {
      case 'set_japanese':
        return 1;
      case 'set_english':
        return 2;
      default:
        return isNaN(currentLanguage) ? state : currentLanguage;
      }
    })();

    localStorage.setItem('user-setting.language', language + '');
    return language;
  },
  storeProfile: (state: { isLoaded: boolean, document?: StoreDocument }, action) => {
    switch (action.type) {
    case 'initStoreProfile':
      return { isLoaded: false };
    case 'onLoadStoreProfile':
      return { isLoaded: true, document: action.data };
    default:
      return state || { isLoaded: false };
    }
  },
  toastr: toastrReducer,
  isActiveLoading: toggleViewLoadingReducer,
});

const store = createStore(rootReducer, {});

const rrfProps: ReactReduxFirebaseProviderProps = {
  firebase: app,
  config: {
    userProfile: 'users',
    useFirestoreForProfile: true,
  },
  dispatch: store.dispatch,
  createFirestoreInstance,
};

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <LocalizationProvider dateAdapter={DateFnsAdapter}>
        <FirebaseContext.Provider value={new Firebase()}>
          <App />
          <ReduxToastr
            timeOut={4000}
            newestOnTop={false}
            preventDuplicates
            position="top-right"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar
            closeOnToastrClick
          />
          <OverlayLoading />
        </FirebaseContext.Provider>
      </LocalizationProvider>
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById('main-wrapper'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
