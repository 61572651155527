import * as Yup from 'yup';

const InitState = {
  // Officer information
  officer_name: '',
  email_address: '',
  phone_number: '',
  password: '',
  password_: '',

  // Basic information
  business_name: '',
  branch_name: '',
  tel_number: '',

  // Address information
  address1: '',
  address2: '',
  zip_code: '',
  city: '',
  state: '',
  homepage: '',
  region: 1,
};

const ValidationSchema = Yup.object().shape({
  officer_name: Yup.string()
    .min(1, {length: 1, message: 'min'})
    .max(100, {length: 100, message: 'max'})
    .required({message: 'required'}),
  email_address: Yup.string()
    .min(5, {length: 5, message: 'min'})
    .max(400, {length: 400, message: 'max'})
    .email({message: 'invalid_email'})
    .required({message: 'required'}),
  // phone_number: Yup.string()
  //   .matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, {message: 'invalid_phone_number'})
  //   .required({message: 'required'}),
  password: Yup.string()
    .min(8, {length: 8, message: 'min'})
    .matches(/^[A-Za-z0-9!@#$%^&*(){}:;<>[\]?/~_+-=|`\\.]{8,}$/, {message: 'invalid_password'})
    .required({message: 'required'}),
  password_: Yup.string()
    .test('confirm-password', {message: 'mismatch_password'}, (value, context) => !!value && context.parent.password === value),
  
  business_name: Yup.string()
    .min(1, {length: 1, message: 'min'})
    .max(100, {length: 100, message: 'max'})
    .required({message: 'required'}),
  branch_name: Yup.string()
    .min(1, {length: 1, message: 'min'})
    .max(100, {length: 100, message: 'max'})
    .optional(),
  tel_number: Yup.string()
    .min(5, {length: 5, message: 'min'})
    .max(20, {length: 20, message: 'max'})
    .required({message: 'required'}),

  address1: Yup.string()
    .min(1, {length: 1, message: 'min'})
    .max(100, {length: 100, message: 'max'})
    .required({message: 'required'}),
  address2: Yup.string()
    .min(1, {length: 1, message: 'min'})
    .max(100, {length: 100, message: 'max'})
    .optional(),
  zip_code: Yup.string()
    .min(5, {length: 5, message: 'min'})
    .max(9, {length: 9, message: 'max'})
    .required({message: 'required'}),
  city: Yup.string().required({message: 'empty_city'}),
  state: Yup.string().required({message: 'empty_state'}),
  homepage: Yup.string()
    .min(9, {length: 9, message: 'min'})
    .max(100, {length: 100, message: 'max'})
    .url({message: 'invalid_url'})
    .optional(),
});

export { InitState, ValidationSchema };
