import React, { useEffect } from 'react';
import { useState } from 'react';
import { ChangeEvent } from 'react';
import LocalizedStrings from 'react-localization';
import { useDispatch, useSelector } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';
import { compose } from 'recompose';
import { deleteMediaGallery, uploadMediaGallery } from '../../functions';
import Footer from '../Footer';
import Header from '../Header';
import { withAuthorization } from '../Session';
import Sidebar from '../Sidebar';
import { DocumentData, QueryDocumentSnapshot } from '@firebase/firestore-types';
import './index.scss';
import { ACTIVATE_LOADING, DEACTIVATE_LOADING } from '../component/overlay-loading';
import { toastr } from 'react-redux-toastr';
import YouTube from 'react-youtube';

const useScript = (isLoaded: boolean) => {
  useEffect(() => {
    const scriptElem: HTMLScriptElement[] = [];
    const appendScriptSrc = [
      '/plugins/Magnific-Popup-master/dist/jquery.magnific-popup.min.js',
      '/plugins/Magnific-Popup-master/dist/jquery.magnific-popup-init.js',
      '/plugins/dropify/dist/js/dropify.min.js',
      '/js/customize1.js',
    ];
    for (const path of appendScriptSrc) {
      const script = document.createElement('script');
      script.src = path;
      document.body.appendChild(script);
      scriptElem.push(script);
    }

    return () => {
      for (const elem of scriptElem) {
        document.body.removeChild(elem);
      }
    };
  }, [isLoaded]);
};

const MediaGalleryBase = () => {
  const [userLanguage, storeProfileData] = useSelector((s: any) => [s.userLanguage, s.storeProfile]);
  const [mediaGalleries, setMediaGalleries] = useState<QueryDocumentSnapshot<DocumentData>[]>([]);
  const [isShowPreview, toggleShowPreview] = useState<[boolean, number, string?, string?]>([false, 0]);
  const firestore = useFirestore();
  const dispatch = useDispatch();
  useScript(storeProfileData.isLoaded);
  const localize = new LocalizedStrings({
    jp: {
      title: 'メディアギャラリー',
      max_file_size: '最大アップロード可能サイズ: ',
      drag_drop: 'ファイルをここにドラッグ & ドロップ',
      wrong_appended: '間違ったファイルを追加しましたか？',
      remove: '削除',
      replace: 'ドラッグ & ドロップで別のファイルを選択',
      upload_image: 'イメージのアップロード',
      upload_youtube: 'YouTubeのリンクをアップロード',
      upload: 'アップロード',
      view: 'リンクを確認する',
      completed_upload_image: '画像のアップロードに完了しました',
      failed_upload_image: '画像のアップロードに失敗しました',

      // Status
      _complete_title: 'アップロード完了',
      _complete_message: 'ギャラリーに追加されました！\nページリロードしてください。',
      _complete_deletion: 'ギャラリーから削除しました！\nページリロードしてください。',
      _failed_upload_image: 'アップロード失敗',
      _failed_upload_image_message: '画像のアップロードに失敗しました',
      _failed_delete_gallery: '削除失敗',
      _failed_delete_gallery_message: 'すでに存在していない可能性があります',
      _failed_upload_youtube: 'アップロード失敗',
      _failed_upload_youtube_message: '動画が存在していないか、古い動画です',
    },
    en: {
      title: 'Media Gallery',
      max_file_size: 'Max file size: ',
      drag_drop: 'Drag and drop a file here or click',
      wrong_appended: 'Ooops, something wrong appended.',
      remove: 'Remove',
      replace: 'Drag and drop or click to replace',
      upload_image: 'Upload Image',
      upload_youtube: 'Upload YouTube videos',
      upload: 'Upload',
      view: 'View',
      completed_upload_image: 'Completed image uploading',
      failed_upload_image: 'Failed image uploading',

      // Status
      _complete_title: 'Success!',
      _complete_message: 'Complete add your media-gallery.\nPlease reload.',
      _complete_deletion: 'Complete delete from your media-gallery.\nPlease reload.',
      _failed_upload_image: 'Failure',
      _failed_upload_image_message: 'Failed upload image',
      _failed_delete_gallery: 'Failure',
      _failed_delete_gallery_message: 'Maybe this media is already deleted.\nPlease reload.',
      _failed_upload_youtube: 'Failure',
      _failed_upload_youtube_message: 'Your video is not found or old one',
    },
  });
  localize.setLanguage(userLanguage===1?'jp':'en');

  if (!storeProfileData.isLoaded) {
    return (
      <div>
        <Header />
        <Sidebar />
      </div>
    );
  }

  if (!mediaGalleries.length) {
    firestore
      .collection('stores').doc(storeProfileData.document.id)
      .collection('media-galleries')
      .where('is_disabled', '==', false)
      .get()
      .then((filledData) => {
        if (filledData.empty) return;
        setMediaGalleries(filledData.docs);
      });
  }
  return (
    <div>
      {
        isShowPreview[0] &&
        <div>
          <div className="mfp-bg mfp-img-mobile mfp-ready" />
          <div className="mfp-wrap mfp-close-btn-in mfp-img-mobile mfp-ready" tabIndex={-1} style={{overflow: 'hidden auto'}}>
            <div className="mfp-container mfp-s-ready mfp-image-holder" onClick={(e)=>{
              toggleShowPreview([false, 0]);
            }}>
              <div className="mfp-content">
                <div className="mfp-figure">
                  <button title="Close (Esc)" type="button" className="mfp-close" onClick={(e)=>{
                    toggleShowPreview([false, 0]);
                  }}>x</button>
                  <figure>
                    {
                      isShowPreview[1] === 1 &&
                      <img className="mfp-img" src={isShowPreview[2]} style={{maxHeight: '914px'}} />
                    }
                    {
                      isShowPreview[1] === 2 &&
                      <YouTube
                        className="mfp-img"
                        containerClassName="mfp-img"
                        videoId={new URLSearchParams(new URL(isShowPreview[3] || '').searchParams).get('v') || ''}
                      />
                    }
                    <figcaption>
                      <div className="mfp-bottom-bar">
                        <div className="mfp-title"></div>
                        <div className="mfp-counter"></div>
                      </div>
                    </figcaption>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      <Header />
      <Sidebar />
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="row page-titles">
            <div className="col-md-5 col-8 align-self-center">
              <h3 className="text-themecolor m-b-0 m-t-0">{localize.title}</h3>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active">{localize.title}</li>
              </ol>
            </div>
          </div>
          <div className="row el-element-overlay">
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">{localize.upload}</h4>
                  <label htmlFor="input-file-max-fs">{localize.max_file_size}3MB</label>
                  <input type="file" id="input-file-max-fs" className="dropify" data-max-file-size="3M"  onChange={
                    async (e: ChangeEvent<HTMLInputElement>) => {
                      dispatch({ type: ACTIVATE_LOADING });
                      const fileList = e.target.files;
                      if (!fileList || fileList.length <= 0) return;

                      const file = fileList[0];
                      const ext = file.type;

                      if (!['image/png','image/jpg','image/jpeg'].includes(ext)) {
                        alert(localize.wrong_appended);
                        $('.dropify-clear').click();
                        e.preventDefault();
                        return;
                      }

                      const convertToBase64 = (
                        callback: (
                          result?: string | ArrayBuffer | null | undefined,
                          error?: DOMException | null | undefined,
                        ) => void,
                        file: File,
                      ) => {
                        const reader = new FileReader();
                        reader.onloadend = (e) => {
                          callback(e.target?.result, e.target?.error);
                        };
                        reader.readAsDataURL(file);
                      };
                      convertToBase64(
                        (
                          result: string | ArrayBuffer | null | undefined,
                          error: DOMException | null | undefined,
                        ) => {
                          if (!error && result) {
                            uploadMediaGallery({
                              data: result.toString().replace(/^data:image\/((jpeg)|(png)|(jpg));base64,/, ''),
                              type: 1,
                              title: file.name,
                              extension: ext.replace(/^image\//, ''),
                            }, storeProfileData.document.id)
                              .then((v) => {
                                toastr.success(localize._complete_title, localize._complete_message);
                                $('.dropify-clear').click();
                              })
                              .catch((err) => {
                                toastr.error(localize._failed_upload_image, localize._failed_upload_image_message);
                                $('.dropify-clear').click();
                              })
                              .finally(() => {
                                dispatch({ type: DEACTIVATE_LOADING });});
                          }
                        },
                        file
                      );
                    }
                  } />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">{localize.upload_youtube}</h4>
                  <form className="video-upload">
                    <input type="text" id="lastName" className="form-control form-control-danger" placeholder="Paste the URL" />
                    <small className="form-control-feedback">  </small>
                    <div className="row">
                      <div className="col-6"><div className="form-actions">
                        <button type="button" name="button" className="btn btn-outline-info btn-block btn-zooo m-t-20"
                          onClick={(e) => {
                            if ($('#lastName').length) {
                              toggleShowPreview([true, 2, undefined, $('#lastName').val() as string]);
                            }
                          }}
                        >
                          {localize.view}
                        </button>
                      </div>
                      </div>
                      <div className="col-6">
                        <div className="form-actions">
                          <button type="button" name="button" className="btn btn-info btn-block btn-zooo m-t-20" onClick={async e=>{
                            dispatch({ type: ACTIVATE_LOADING });
                            const videoLink = $('#lastName').val();

                            try {
                              await uploadMediaGallery({
                                type: 2,
                                url: videoLink,
                              }, storeProfileData.document.id);
                              $('#lastName').prop('value', '');
                              setMediaGalleries([]);
                              toastr.success(localize._complete_title, localize._complete_message);
                            } catch (err) {
                              console.error(err);
                              toastr.error(localize._failed_upload_youtube, localize._failed_upload_youtube_message);
                            } finally {
                              dispatch({ type: DEACTIVATE_LOADING });
                            }
                          }}>
                            {localize.upload}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            {/** ... */}
            {
              mediaGalleries && mediaGalleries.length > 0 &&
              mediaGalleries.map((v, i) => {
                if (!v.id) return;
                const media = v.data();
                return (
                  <div className="col-lg-3 col-md-6" key={i}>
                    <div className="card">
                      <div className="el-card-item">
                        <div className="el-card-avatar el-overlay-1"> 
                          <img src={media.thumbnail_filepath || media.filepath} alt="user" />
                          <div className="el-overlay">
                            <ul className="el-info">
                              <li>
                                <a className="btn default btn-outline image-popup-vertical-fit" href='/' onClick={(e)=>{
                                  toggleShowPreview([true, media.type, media.filepath, media.url]);
                                  e.preventDefault();
                                }}>
                                  <i className="icon-magnifier" />
                                </a>
                              </li>
                              <li>
                                <a className="btn default btn-outline" data-toggle="tooltip" title="Move To Trash" onClick={async (event) => {
                                  dispatch({ type: ACTIVATE_LOADING });
                                  try {
                                    await deleteMediaGallery(v.id, storeProfileData.document.id);
                                    event.preventDefault();
                                    toastr.success(localize._complete_title, localize._complete_deletion);
                                  } catch (err) {
                                    console.error(err);
                                    toastr.error(localize._failed_delete_gallery, localize._failed_delete_gallery_message);
                                  } finally {
                                    dispatch({ type: DEACTIVATE_LOADING });
                                  }
                                }}>
                                  <i className="icon-trash" />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="el-card-content">
                          <h3 className="box-title">{media.title}</h3>
                          <small>{media.type === 1 ? `image/${media.extension}` : 'YouTube Movie'}</small>
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            }
            {
              mediaGalleries.length === 0 &&
              <div className="col-lg-3 col-md-6"><div style={{minHeight: '300px'}} /></div>
            }
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

const MediaGallery = compose(
  withAuthorization((authUser: any) => !!authUser),
)(MediaGalleryBase);

export default MediaGallery;
