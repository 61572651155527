import * as Yup from 'yup';

const InitValues = {
  activation_type: 1,
  check_in_times: [] as number[],
  included_day: [] as ('monday'|'tuesday'|'wednesday'|'thursday'|'friday'|'saturday'|'sunday')[],

  discount_type: 1,
  discount_value: 0,
  maximum_amount: 0,
  minimum_amount: 0,
  free_item: '',
  item_value: 0,

  is_survey: false,
  survey: {
    question: '',
    choices: ['', ''],
    include_multiple_answers: false,
  },

  sub_headline: '',
  headline: '',
  discount_description: '',
  disclaimer: '',
  start_at: '',
  end_at: '',

  voucher_id: '000001',
  status_code: 1,
  is_stopped: false,
};

const ValidateScheme = Yup.object().shape({
  // headline (optional) check max length
  headline: Yup.string()
    .nullable()
    .optional()
    .max(40, {length: 40, message: 'max'}),
  // discount_description (optional) check max length
  discount_description: Yup.string()
    .nullable()
    .optional()
    .max(40, {length: 40, message: 'max'}),
  // sub_headline (optional) check max length
  sub_headline: Yup.string()
    .optional()
    .max(40, {length: 40, message: 'max'}),
});

export { InitValues, ValidateScheme };
