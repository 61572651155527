import DateRangePicker from 'rsuite/DateRangePicker';
import moment from 'moment';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import LocalizedStrings from 'react-localization';
import { useSelector } from 'react-redux';
import compose from 'recompose/compose';
import { getDashboardData } from '../../functions';
import Footer from '../Footer';
import Header from '../Header';
import { withAuthorization } from '../Session';
import Sidebar from '../Sidebar';
import c3 from 'c3';
import Chartist from 'chartist';
import 'chartist-plugin-tooltip';
import { startOfDay, endOfDay, addMonths, addYears, addWeeks, isBefore } from 'date-fns';

import './index.scss';
import _ from 'lodash';

const DashboardPage = () => {
  const [userLanguage, storeProfileData] = useSelector((s: any) => [
    s.userLanguage,
    s.storeProfile,
  ]);
  const [value, setValue] = useState<any>([null, null] as any);
  const [aggregateData, setAggregateData] = useState<any>(null);

  const localize = new LocalizedStrings({
    jp: {
      title: 'ダッシュボード',
      title_home: 'Home',
      start: '開始日',
      end: '終了日',
      statistics_check_in_total: 'チェックイン数',
      statistics_coupon_delivery_total: 'クーポン配信数',
      statistics_share_store_total: 'ストアを共有する',
      statistics_coupon_used_total: 'クーポン利用数​',
      show_detail: '詳細を表示',
      completed_survey: '完了した調査',
      visitor: '訪問者',
      check_in_rate: 'ユーザーチェックイン回数比率',
      check_in_one: '初回',
      check_in_two: '2回目',
      check_in_three_more: '3回目以上',
      date_by_gender: '性別による日付',
      date_by_gender_description: 'チェックイン、性別によるクーポンの使用',
      male: '男性',
      female: '女性',
      unknown: '不明',
      popular_day: '人気の日',
      check_in_by_day_of_week: '日別チェックイン数',
      check_in_text: 'チェックイン',
      coupon_redemption: 'クーポン利用数',
      total_check_in: 'チェックイン数',
      redeemed_coupon: '利用数',
      average_coupon_redeem_rate: '平均クーポン償還率',
      repeat_rate: '繰り返し率',
      repeat_rate_one: '初回チェックインユーザー',
      repeat_rate_two: '2回目チェックインユーザー',
      repeat_rate_three_more: '3回目以上チェックインユーザー',
      popular_hour: '人気の時間',
      check_in_by_hour: '時間別チェックイン数',
      most_popular_coupon: '最も人気のあるクーポン',
      no_coupon: 'クーポンはまだ使用されていません。',

      calendar_a_week_text: '1 Week',
      calendar_a_month_text: '1 Month',
      calendar_a_year_text: '1 Year',
    },
    en: {
      title: 'Dashboard',
      title_home: 'Home',
      start: 'Start',
      end: 'End',
      statistics_check_in_total: 'Check-Ins',
      statistics_coupon_delivery_total: 'Coupons Delivered',
      statistics_share_store_total: 'Share The Store',
      statistics_coupon_used_total: 'Coupon Used',
      show_detail: 'View Results',
      completed_survey: 'Completed Surveys',
      visitor: 'Visitors',
      check_in_rate: 'User checkin rate',
      check_in_one: 'Once',
      check_in_two: 'Twice',
      check_in_three_more: '3 times or more',
      date_by_gender: 'Date by gender',
      date_by_gender_description: 'Check-ins, coupon use by gender',
      male: 'Male',
      female: 'Female',
      unknown: 'Unknown',
      popular_day: 'Popular Days',
      check_in_by_day_of_week: 'Number of check-ins by day of the week',
      check_in_text: 'Check-ins',
      coupon_redemption: 'Coupon Redemption',
      total_check_in: 'Total Check-Ins',
      redeemed_coupon: 'Redeemed Coupon',
      average_coupon_redeem_rate: 'Average Coupon Redeem Rate',
      repeat_rate: 'Repeat Rates',
      repeat_rate_one: 'of shoppers came 1 time',
      repeat_rate_two: 'of shoppers came back 2 times',
      repeat_rate_three_more: 'of shoppers came back 3 times or more',
      popular_hour: 'Popular Hours',
      check_in_by_hour: 'Number of check-ins by hour',
      most_popular_coupon: 'Most Popular Coupon',
      no_coupon: 'Your coupons was not used yet.',

      calendar_a_week_text: '1 Week',
      calendar_a_month_text: '1 Month',
      calendar_a_year_text: '1 Year',
    },
  });
  localize.setLanguage(userLanguage === 1 ? 'jp' : 'en');

  useEffect(() => {
    (async () => {
      if (value[0] === null && value[1] === null){
        if (storeProfileData.document?.created_at) {
          const startAt = moment.unix(storeProfileData.document.created_at.seconds).toDate();
          let endtAt = moment.utc().toDate();
          if (moment.unix(storeProfileData.document.created_at.seconds).format(dateFormat) === moment.utc().format(dateFormat)) {
            endtAt = moment.utc().add(1, 'day').toDate();
          }
          setValue([startAt, endtAt]);
        }
      }
      if (storeProfileData.isLoaded && value[0] && value[1]) {
        const ret = await getDashboardData(storeProfileData.document.id, moment(value[0]), moment(value[1]));
        setAggregateData(ret.data);

        c3.generate({
          bindto: '#visitor',
          data: {
            columns: [
              [localize.check_in_one, ret.data.counts.onceCheckInUserCount],
              [localize.check_in_two, ret.data.counts.twiceCheckInUserCount],
              [localize.check_in_three_more, ret.data.counts.moreCheckInUserCount],
            ],

            type: 'donut',
          },
          donut: {
            label: { show: false },
            title: localize.visitor,
            width: 20,
          },
          legend: { hide: true },
          color: {
            pattern: ['#1e88e5', '#eceff1', '#26c6da']
          }
        });
        const LocalizeChart = {
          jp: {
            monday: '月',
            tuesday: '火',
            wednesday: '水',
            thursday: '木',
            friday: '金',
            saturday: '土',
            sunday: '日',
          },
          en: {
            monday: 'Mon',
            tuesday: 'Tue',
            wednesday: 'Wed',
            thursday: 'Thu',
            friday: 'Fri',
            saturday: 'Sat',
            sunday: 'Sun',
          }
        };
        new Chartist.Bar(
          '.download-state',
          {
            labels: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map(item => {
              const lang = userLanguage === 1 ? 'jp' : 'en';
              return LocalizeChart[lang][item];
            }),
            series: [
              ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map((v)=>{
                return ret.data.dateByGenderThisWeek[v].male;
              }),
              ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map((v)=>{
                return ret.data.dateByGenderThisWeek[v].female;
              }),
              ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map((v)=>{
                return ret.data.dateByGenderThisWeek[v].noAnswer;
              }),
            ],
          },
          {
            high: 11,
            low: 0,
            seriesBarDistance: 10,
            plugins: [
              Chartist.plugins.tooltip(),
            ],
            axisX: {
              showGrid: false,
            },
          },
        );

        const popularDays = Object.keys(ret.data.popularDays);
        new Chartist.Line(
          '#popular-days',
          {
            labels: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map(item => {
              const lang = userLanguage === 1 ? 'jp' : 'en';
              return LocalizeChart[lang][item];
            }),
            series: [
              [1, 2, 3, 4, 5, 6, 7].map((item)=>{ // Mon - Sun
                const dayList = _.filter(popularDays, o =>  moment(o.replaceAll('_', '-')).day() === item );
                let sum = 0;
                if (dayList.length > 0) {
                  for (const dayData of dayList) {
                    sum = sum + Number(ret.data.popularDays[dayData].check_in);
                  }
                }
                return sum;
              }),
              [1, 2, 3, 4, 5, 6, 7].map((item)=>{ // Mon - Sun
                const dayList = _.filter(popularDays, o =>  moment(o.replaceAll('_', '-')).day() === item );
                let sumRedeemed = 0;
                if (dayList.length > 0) {
                  for (const dayData of dayList) {
                    sumRedeemed = sumRedeemed + Number(ret.data.popularDays[dayData].redeemed_coupon);
                  }
                }
                return sumRedeemed;
              }),
            ],
          },
          {
            low: 0,
            high: 28,
            showArea: true,
            fullWidth: true,
            plugins: [
              Chartist.plugins.tooltip(),
            ],
            // axisY: {
            //   onlyInteger: true,
            //   scaleMinSpace: 40,
            //   offset: 20,
            //   labelInterpolationFnc: (v) => (v/1)+'k',
            // },
          }
        );

        const popularHours = Object.keys(ret.data.popularHours);
        new Chartist.Line(
          '#popular-hours',
          {
            labels: popularHours,
            series: [
              popularHours.map((v)=>ret.data.popularHours[v].check_in),
              popularHours.map((v)=>ret.data.popularHours[v].redeemed_coupon),
            ],
          },
          {
            low: 0,
            high: 28,
            showArea: true,
            fullWidth: true,
            plugins: [
              Chartist.plugins.tooltip(),
            ],
            axisY: {
              onlyInteger: true,
              scaleMinSpace: 40,
              offset: 20,
              labelInterpolationFnc: (v) => (v/1)+'k',
            },
          }
        );
      }
    })();
  }, [storeProfileData.isLoaded, value]);

  if (!storeProfileData.isLoaded) {
    return (
      <>
        <Header />
        <Sidebar />
      </>
    );
  }

  const dateFormat = userLanguage === 1 ? 'YYYY/MM/DD' : 'MM/DD/YYYY';
  
  // Add ranges custom for calendar
  const ranges: any = [
    {
      label: localize.calendar_a_week_text,
      value: [startOfDay(addWeeks(new Date(), -1)), endOfDay(new Date())]
    },
    {
      label: localize.calendar_a_month_text,
      value: [startOfDay(addMonths(new Date(), -1)), endOfDay(new Date())]
    },
    {
      label: localize.calendar_a_year_text,
      value: [startOfDay(addYears(new Date(), -1)), endOfDay(new Date())]
    }
  ];

  return (
    <div>
      <Header />
      <Sidebar />

      <div className="page-wrapper dashboard">
        <div className="container-fluid">
          <div className="row page-titles">
            {/** Page Title */}
            <div className="col-md-4 col-8 align-self-center">
              <h3 className="text-themecolor">{localize.title}</h3>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/" onClick={(e)=>e.preventDefault()}>{localize.title_home}</a>
                </li>
                <li className="breadcrumb-item active">{localize.title}</li>
              </ol>
            </div>
            <div className="col-md-2"></div>
            {/** Input date range fields */}
            <div className="col-md-4">
              <div className="m-t-10 justify-content-end">
                <div className="m-t-30">
                  <div className="input-group">
                    <DateRangePicker format="yyyy-MM-dd"
                      disabledDate={date => isBefore(date, moment.unix(storeProfileData.document?.created_at?.seconds).toDate() || moment().utc())}
                      size='lg'
                      ranges={ranges}
                      value={value}
                      onChange={(arrayDate)=>{
                        if (!arrayDate || !Array.isArray(arrayDate) || arrayDate.length !== 2) {
                          setValue([null, null]);
                          return;
                        }
                        let [startAt, endAt] = [moment(arrayDate[0]).toDate(), moment(arrayDate[1]).toDate()];
                        if (moment(arrayDate[0]) && moment(arrayDate[1]) && moment(arrayDate[0]) > moment(arrayDate[1])) {
                          [startAt, endAt] = [moment(arrayDate[1]).toDate(), moment(arrayDate[0]).toDate()];
                        } else if (moment(arrayDate[0]) && moment(arrayDate[1]) && moment(arrayDate[0]).format(dateFormat) === moment(arrayDate[1]).format(dateFormat)) {
                          [startAt, endAt] = [moment(arrayDate[0]).toDate(), moment(arrayDate[0]).add(1, 'day').toDate()];
                        }
                        setValue([startAt, endAt]);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>

          {/** Counts cards */}
          <div className="row">
            {/** Check-ins */}
            <div className="col-lg-3 col-md-6">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex flex-row">
                    <div className="round round-lg align-self-center round-info">
                      <i className="ti-location-pin" />
                    </div>

                    <div className="m-l-10 align-self-center">
                      <h3 className="m-b-0 font-light">{aggregateData?.counts?.checkInCount || 0}</h3>
                      <h5 className="text-muted m-b-0">{localize.statistics_check_in_total}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/** Coupon Delivered */}
            <div className="col-lg-3 col-md-6">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex flex-row">
                    <div className="round round-lg align-self-center round-warning">
                      <i className="ti-ticket" />
                    </div>

                    <div className="m-l-10 align-self-center">
                      <h3 className="m-b-0 font-light">{aggregateData?.counts?.deliverCouponCount || 0}</h3>
                      <h5 className="text-muted m-b-0">{localize.statistics_coupon_delivery_total}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/** Share Store Count */}
            <div className="col-lg-3 col-md-6">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex flex-row">
                    <div className="round round-lg align-self-center round-primary">
                      <i className="ti-gift" />
                    </div>

                    <div className="m-l-10 align-self-center">
                      <h3 className="m-b-0 font-light">{aggregateData?.counts?.shareStoreCount || 0}</h3>
                      <h5 className="text-muted m-b-0">{localize.statistics_share_store_total}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/** Coupon Used Count */}
            <div className="col-lg-3 col-md-6">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex flex-row">
                    <div className="round round-lg align-self-center round-danger">
                      <i className="ti-check" />
                    </div>

                    <div className="m-l-10 align-self-center">
                      <h3 className="m-b-0 font-light">{aggregateData?.counts?.couponUsedCount || 0}</h3>
                      <h5 className="text-muted m-b-0">{localize.statistics_coupon_used_total}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            {/* Survey Count */}
            <div className="col-lg-4 col-md-12">
              <div className="card card-inverse card-primary">
                <a href="javascript:void(0)" className="waves-effect waves-dark">
                  <div className="card-body">
                    <div className="d-flex">
                      <div className="m-r-20 align-self-center">
                        <h1 className="text-white"><i className="ti-pie-chart" /></h1>
                      </div>
                      <div>
                        <h3 className="card-title"><span>{aggregateData?.counts?.surveyCount || 0}</span>{' '}{localize.completed_survey}</h3>
                        <h6 className="card-subtitle">{moment(value[0]).format(dateFormat) + ' - ' + moment(value[1]).format(dateFormat)}</h6>
                      </div>
                    </div>
                    {/* <div className="row">
                      <div className="col-11 p-t-10 p-b-20 text-right">
                        <h3 className="font-light text-white">{localize.show_detail}{' '}<i className="ti-arrow-right" /></h3>
                      </div>
                    </div> */}
                  </div>
                </a>
              </div>
            </div>

            {/* Visitors */}
            <div className="col-lg-4 col-md-12">
              <div className="card">
                <div className="card-body">
                  <h3 className="card-title">{localize.visitor}</h3>
                  <h6 className="card-subtitle">{localize.check_in_rate}</h6>
                  <div id="visitor" style={{height: '260px', width: '100%'}} />
                </div>
                <div><hr className="m-t-0 m-b-0" /></div>

                <div className="card-body text-center">
                  <ul className="list-inline m-b-0">
                    <li>
                      <h6 className="text-muted text-info"><i className="fa fa-circle font-10 m-r-10" />{localize.check_in_one}</h6>
                    </li>
                    <li>
                      <h6 className="text-muted text-primary"><i className="fa fa-circle font-10 m-r-10" />{localize.check_in_two}</h6>
                    </li>
                    <li>
                      <h6 className="text-muted text-success"><i className="fa fa-circle font-10 m-r-10" />{localize.check_in_three_more}</h6>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Date by gender */}
            <div className="col-lg-4 col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex no-block align-self-center">
                    <div>
                      <h3 className="card-title">{localize.date_by_gender}</h3>
                      <h6 className="card-subtitle">{localize.date_by_gender_description}</h6>
                    </div>
                  </div>

                  <div className="download-state chartist-chart" style={{height:'270px'}} />
                </div>

                <div><hr className="m-t-0 m-b-0" /></div>

                <div className="card-body text-center">
                  <ul className="list-inline m-b-0">
                    <li><h6 className="text-muted text-info"><i className="fa fa-circle font-10 m-r-10" />{localize.male}</h6></li>
                    <li><h6 className="text-muted text-primary"><i className="fa fa-circle font-10 m-r-10" />{localize.female}</h6></li>
                    <li><h6 className="text-muted text-success"><i className="fa fa-circle font-10 m-r-10" />{localize.unknown}</h6></li>
                  </ul>
                </div>
              </div>
            </div>

          </div>

          <div className="row">
            <div className="col-lg-7 col-md-6">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex flex-wrap">
                    <div>
                      <h3 className="card-title">{localize.popular_day}</h3>
                      <h6 className="card-subtitle">{localize.check_in_by_day_of_week}</h6>
                    </div>

                    <div className="ml-auto align-self-center">
                      <ul className="list-inline m-b-0">
                        <li><h6 className="text-muted text-success"><i className="fa fa-circle font-10 m-r-10" />{localize.check_in_text}</h6></li>
                        <li><h6 className="text-muted text-info"><i className="fa fa-circle font-10 m-r-10" />{localize.coupon_redemption}</h6></li>
                      </ul>
                    </div>
                  </div>

                  <div className="campaign ct-charts" id="popular-days" />

                  <div className="row text-center">
                    <div className="col-lg-4 col-md-4 m-t-20">
                      <h1 className="m-b-0 font-light">
                        {aggregateData?.counts?.checkInCount || 0}
                      </h1>
                      <small>{localize.total_check_in}</small>
                    </div>
                    <div className="col-lg-4 col-md-4 m-t-20">
                      <h1 className="m-b-0 font-light">
                        {aggregateData?.counts?.redeemedCoupon || 0}
                      </h1>
                      <small>{localize.redeemed_coupon}</small>
                    </div>
                    <div className="col-lg-4 col-md-4 m-t-20">
                      <h1 className="m-b-0 font-light">
                        {aggregateData?.counts?.averageCouponRedeemRate || 0}{'%'}
                      </h1>
                      <small>{localize.average_coupon_redeem_rate}</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-5 col-md-6">
              <h2>{localize.repeat_rate}</h2>
              {/* Check-in only time */}
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12">
                      <h3>{Math.round((aggregateData?.counts?.onceCheckInUserCount||0)/(aggregateData?.counts?.totalShopper||0)*100)}%</h3>
                      <h6 className="card-subtitle">{localize.repeat_rate_one}</h6>
                    </div>
                    <div className="col-12">
                      <div className="progress">
                        <div
                          className="progress-bar bg-success"
                          role="progressbar"
                          style={{
                            width: Math.round((aggregateData?.counts?.onceCheckInUserCount||0)/(aggregateData?.counts?.totalShopper||0)*100)+'%',
                            height: '6px',
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Check-in two times */}
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12">
                      <h3>{Math.round((aggregateData?.counts?.twiceCheckInUserCount||0)/(aggregateData?.counts?.totalShopper||0)*100)}%</h3>
                      <h6 className="card-subtitle">{localize.repeat_rate_two}</h6>
                    </div>
                    <div className="col-12">
                      <div className="progress">
                        <div
                          className="progress-bar bg-info"
                          role="progressbar"
                          style={{
                            width: Math.round((aggregateData?.counts?.twiceCheckInUserCount||0)/(aggregateData?.counts?.totalShopper||0)*100)+'%',
                            height: '6px',
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Check-in more times */}
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12">
                      <h3>{Math.round((aggregateData?.counts?.moreCheckInUserCount||0)/(aggregateData?.counts?.totalShopper||0)*100)}%</h3>
                      <h6 className="card-subtitle">{localize.repeat_rate_three_more}</h6>
                    </div>
                    <div className="col-12">
                      <div className="progress">
                        <div
                          className="progress-bar bg-danger"
                          role="progressbar"
                          style={{
                            width: Math.round((aggregateData?.counts?.moreCheckInUserCount||0)/(aggregateData?.counts?.totalShopper||0)*100)+'%',
                            height: '6px',
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          
          <div className="row">
            <div className="col-lg-7 col-md-6">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex flex-wrap">
                    <div>
                      <h3 className="card-title">{localize.popular_hour}</h3>
                      <h6 className="card-subtitle">{localize.check_in_by_hour}</h6>
                    </div>

                    <div className="ml-auto align-self-center">
                      <ul className="list-inline m-b-0">
                        <li><h6 className="text-muted text-success"><i className="fa fa-circle font-10 m-r-10" />{localize.check_in_text}</h6></li>
                        <li><h6 className="text-muted text-info"><i className="fa fa-circle font-10 m-r-10" />{localize.coupon_redemption}</h6></li>
                      </ul>
                    </div>
                  </div>

                  <div className="campaign ct-charts" id="popular-hours" />

                  <div className="row text-center">
                    <div className="col-lg-4 col-md-4 m-t-20">
                      <h1 className="m-b-0 font-light">
                        {aggregateData?.counts?.checkInCount || 0}
                      </h1>
                      <small>{localize.total_check_in}</small>
                    </div>
                    <div className="col-lg-4 col-md-4 m-t-20">
                      <h1 className="m-b-0 font-light">
                        {aggregateData?.counts?.redeemedCoupon || 0}
                      </h1>
                      <small>{localize.redeemed_coupon}</small>
                    </div>
                    <div className="col-lg-4 col-md-4 m-t-20">
                      <h1 className="m-b-0 font-light">
                        {aggregateData?.counts?.averageCouponRedeemRate || 0}{'%'}
                      </h1>
                      <small>{localize.average_coupon_redeem_rate}</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-5 col-md-12">
              <div className="card card-inverse card-primary">
                <a href={aggregateData?.mostPopularCoupon ? '/coupon-list' : '/coupon'} className="waves-effect waves-dark">
                  <div className="card-body">
                    <div className="d-flex">
                      <div className="m-r-20 align-self-center">
                        <h1 className="text-white"><i className="ti-ticket" /></h1>
                      </div>
                      <div><h3 className="card-title">{localize.most_popular_coupon}</h3></div>
                    </div>
                    <div className="row">
                      <div className="col-11 p-t-10 p-b-20">
                        <h3 className="font-light text-white">
                          {
                            aggregateData?.mostPopularCoupon
                              ? aggregateData.mostPopularCoupon.discount_type === 1
                                ? aggregateData.mostPopularCoupon.discount_value + '円 OFF'
                                : aggregateData.mostPopularCoupon.discount_type === 2
                                  ? userLanguage === 1
                                    ? '¥' + aggregateData.mostPopularCoupon.discount_value + ' オフ'
                                    : aggregateData.mostPopularCoupon.discount_value + ' $ OFF'
                                  : aggregateData.mostPopularCoupon.free_item
                              : localize.no_coupon
                          }
                        </h3>
                        <h3 className="font-light text-white">
                          {
                            aggregateData?.mostPopularCoupon
                              ? aggregateData.mostPopularCoupon.coupon_sub_headline
                              : ''
                          }
                        </h3>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>

        </div>
        <Footer />
      </div>
    </div>
  );
};

const Dashboard = compose(
  withAuthorization((authUser: any)=>!!authUser),
)(DashboardPage);
export default Dashboard;
