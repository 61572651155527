import { Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import Footer from '../Footer';
import Header from '../Header';
import { withAuthorization } from '../Session';
import Sidebar from '../Sidebar';
import FirstForm from './FirstForm';
import SecondForm from './SecondForm';
import ThirdForm from './ThirdForm';
import { InitValues as _InitState, ValidateScheme } from './state_and_validator';

import './index.scss';
import LocalizedStrings from 'react-localization';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { ACTIVATE_LOADING, DEACTIVATE_LOADING } from '../component/overlay-loading';
import { createNewCoupon, getCouponDetail, updateCoupon } from '../../functions';
import moment from 'moment';
import { CouponStatusCode } from '../CouponList';

const CouponDetailState = {
  isLoadedData: false,
  data: {},
};

const CouponBase = (props: any) => {
  const [dataCoupon, setData] = useState(CouponDetailState);
  const [userLanguage, storeProfileData] = useSelector((s: any) => {
    return [ s.userLanguage, s.storeProfile ];
  });
  const couponId = props.match.params.couponId;
  useEffect(() => {
    const fetchData = async () => {
      if (storeProfile?.id && couponId) {
        const result = await getCouponDetail(storeProfile?.id, couponId);
        setData({ isLoadedData: true, data: result?.data?.data });
      }
    };

    fetchData();
  }, [storeProfileData.isLoaded, !!couponId]);

  const [currentPage, setPageIndex] = useState<number>(1);
  const dispatch = useDispatch();
  const storeProfile = storeProfileData.document;

  const nextPage = () => setPageIndex(currentPage + 1);
  const prevPage = () => setPageIndex(currentPage - 1);
  const [inputValue, setInputValue] = useState<any>({});

  const localize = new LocalizedStrings({
    jp: {
      title: 'クーポン作成',
      title_detail: 'クーポン作成: 詳細',
      preview: 'クーポン (プレビュー)',
      disclaimer_preview: '利用規約 (プレビュー)',
      voucher_preview: 'クーポン使用時 (プレビュー)',
      show_the_comment: '上記のクーポンコードをレジ係へお見せください。',
      use_coupon: 'クーポンを利用する',
      done: '閉じる',
      percentage_off: ' OFF',
      up_to: 'まで使用可能！',
      down_from: 'から使用可能！',
      item_value: '一個あたり',
      here_is_from_this_coupon: 'クーポンの利用規約',
      here_is_from_business_profile: '店舗の利用規約 (ビジネスプロフィールページより)',

      form_detail_step_detail: '詳細',
      form_detail_step_taget: '対象ユーザー',
      form_detail_step_distribution: '配信タイミング',
      form_detail_step_review: 'レビュー',
    },
    en: {
      title: 'Create A Coupon',
      title_detail: 'Create A Coupon : Coupon Details',
      preview: 'Coupon Preview',
      disclaimer_preview: 'Disclaimer Preview',
      voucher_preview: 'Voucher Preview',
      show_the_comment: 'Show the redeem code above to the cashier',
      use_coupon: 'Use coupon',
      done: 'Done',
      percentage_off: ' OFF',
      up_to: 'Up to',
      down_from: 'Down from',
      item_value: 'Item value is',
      here_is_from_this_coupon: '[Coupon disclaimer]',
      here_is_from_business_profile: '[Service disclaimer defined in the Business Profile page]',

      form_detail_step_detail: 'Coupon Details',
      form_detail_step_taget: 'Target Audience',
      form_detail_step_distribution: 'Distribution Setting',
      form_detail_step_review: 'Review',
    },
  });
  localize.setLanguage(userLanguage === 1 ? 'jp' : 'en');
  const extOfAmount = (storeProfile?.region || userLanguage) === 1 ? '¥' : '$';

  // Waiting for load data is success
  if (couponId && dataCoupon?.isLoadedData === false) {
    return (
      <div>
        <Header />
        <Sidebar />
      </div>
    );
  }

  const data : any = dataCoupon.data;
  if (couponId && !data.survey) {
    data.survey = _InitState.survey;
  }

  const genPreviewHeadline = (values, inputValue) => {
    const headline = Number(inputValue.discount_type) === 1
      ? (inputValue.discount_value || 0) + '%' + localize.percentage_off
      : Number(inputValue.discount_type) === 2
        ? (storeProfile?.region || userLanguage) === 1 
          ? (inputValue.discount_value || 0) + extOfAmount + localize.percentage_off
          : extOfAmount + (inputValue.discount_value || 0) + localize.percentage_off
        : (inputValue.free_item || null);

    inputValue.headline = currentPage === 1 ? headline : inputValue.headline || null;
    return inputValue.headline ?? headline;
  };

  const genPreviewSubhead = (values, inputValue) => {
    const discount_description = Number(inputValue.discount_type) === 1
      ? storeProfile?.region === 1
        ? userLanguage === 1
        // 5¥ xxxxxx => ¥5 xxxxxx
          ? inputValue.maximum_amount != 0 ? extOfAmount + (inputValue.maximum_amount || 0) + ' ' + localize.up_to : ''
        // xxxxxx 5¥ => xxxxxx ¥5
          : inputValue.maximum_amount != 0 ? localize.up_to + ' ' + extOfAmount + (inputValue.maximum_amount || 0) : ''
        : userLanguage === 1
        // $5 xxxxxx
          ? inputValue.maximum_amount != 0 ? extOfAmount + (inputValue.maximum_amount || 0)+ ' ' + localize.up_to : ''
        // xxxxx $5
          : inputValue.maximum_amount != 0 ? localize.up_to + ' ' + extOfAmount + (inputValue.maximum_amount || 0) : ''
      :  Number(inputValue.discount_type) === 2
        ? storeProfile?.region === 1
          ? userLanguage === 1
          // 5¥ xxxxxx => ¥5 xxxxxx
            ? inputValue.minimum_amount != 0 ? extOfAmount + (inputValue.minimum_amount || 0) + ' ' + localize.down_from : ''
          // xxxxx 5¥ => => xxxxxx ¥5
            : inputValue.minimum_amount != 0 ? localize.down_from + ' ' + extOfAmount + (inputValue.minimum_amount || 0) : ''
          : userLanguage === 1
          // $5 xxxxx
            ? inputValue.minimum_amount != 0 ? extOfAmount + (inputValue.minimum_amount || 0) + ' ' + localize.down_from : ''
          // xxxxx $5
            : inputValue.minimum_amount != 0 ? localize.down_from + ' ' + extOfAmount + inputValue.minimum_amount : ''
        : storeProfile?.region === 1
        // xxxxxx 5¥ =>  xxxxxx ¥5
          ?  inputValue.item_value != 0 ? localize.item_value + ' ' + extOfAmount + (inputValue.item_value || 0) : ''
        // xxxxxx 5$
          : inputValue.item_value != 0 ? localize.item_value + ' ' + extOfAmount + (inputValue.item_value || 0) : '';

    inputValue.discount_description = currentPage === 1 ? discount_description : inputValue.discount_description || null;
    return inputValue.discount_description ?? discount_description;
  };

  return (
    <div>
      <Header />
      <Sidebar />
      <div className="page-wrapper">
        <div className="container-fluid">
          {/** Page title */}
          <div className="row page-titles">
            <div className="col-12 align-self-center">
              <h3 className="text-themecolor m-b-0 m-t-0">{localize.title}</h3>

              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/" onClick={(e)=>e.preventDefault}>Home</a>
                </li>
                <li className="breadcrumb-item active">{localize.title_detail}</li>
              </ol>
            </div>
          </div>

          {/** Form */}
          <Formik initialValues={couponId ? dataCoupon?.data as any : _InitState} enableReinitialize={true} validationSchema={ValidateScheme} onSubmit={async (values, _) => {
            try {
              dispatch({ type: ACTIVATE_LOADING });
              const data = Object.assign({}, values) as any;
              data.start_at = moment(values.start_at).utc().format('YYYY/MM/DD HH:mm');
              data.end_at = moment(values.end_at).utc().format('YYYY/MM/DD HH:mm');
              data.status_code = CouponStatusCode.active;
              if (couponId) {
                await updateCoupon(storeProfile.id, couponId, data);
              } else {
                await createNewCoupon(storeProfile.id, data);
              }
              
              props.history.push('/coupon-list');
            } catch (err) {
              console.error(err);
            } finally {
              dispatch({ type: DEACTIVATE_LOADING });
            }
          }}>

            {({ values, touched, errors, setFieldError, setFieldValue }) => (
              <Form id="coupon-form">
                <div className="row">
                  {/** Preview */}
                  <div className="col-md-5">
                    <div className="card">
                      <div className="card-body">
                        <h3>{localize.preview}</h3>
                        <div className="card coupon-preview">
                          <div className="card-body coupon-preview">
                            <div className="row">
                              <div className="col-12">
                                <h4 className="coupon-preview-headline">
                                  {
                                    genPreviewHeadline(values, inputValue)
                                  }
                                </h4>
                                <p className="coupon-preview-subhead">
                                  {
                                    genPreviewSubhead(values, inputValue)
                                  }
                                </p>
                                <div className="coupon-preview-date-time">
                                  <p>{inputValue?.start_at || null } - {inputValue?.end_at || null}</p>
                                </div>
                                <div className="coupon-preview-button">
                                  <button type="button" name="button" className="btn btn-outline-secondary">{localize.use_coupon}</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <br/>
                        <h3>{localize.disclaimer_preview}</h3>
                        <div className="card">
                          <div className="card-body disclaimer-preview">
                            <p>{localize.here_is_from_this_coupon}</p>
                            <p>{values.disclaimer}</p>
                            <p>{localize.here_is_from_business_profile}</p>
                            <p>{storeProfile?.service_disclaimer}</p>
                          </div>
                        </div>

                        <h3>{localize.voucher_preview}</h3>
                        <div className="card">
                          <div className="card-body text-center voucher-preview">
                            <h3>{values.voucher_id}</h3>
                            <p style={{textAlign:'center'}}>{localize.show_the_comment}</p>
                            <button type="button" name="button" className="btn btn-outline-secondary">{localize.done}</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/** Form */}
                  <div className="col-md-7">
                    <div className="row">
                      <div className="col-12">
                        <div className="card wizard-content">
                          <div className="card-body">
                            <div className="validation-wizard wizard-circle wizard clearfix">
                              {/** Visible wizard step */}
                              <div className="steps clearfix">
                                <ul role="tablist">
                                  <li role="tab" className={currentPage>1 ? 'done' : currentPage===1 ? 'current' : 'disabled last'} aria-disabled={currentPage<1} aria-selected={currentPage<1}>
                                    <a id="steps-uid-0-t-0" href="/" aria-controls="steps-uid-0-p-0" onClick={(e)=>{
                                      if (currentPage > 1) setPageIndex(1);
                                      e.preventDefault();
                                    }}>
                                      <span className="current-info audible">current step: </span>
                                      <span className="step">1</span> {localize.form_detail_step_detail}
                                    </a>
                                  </li>
                                  <li role="tab" className={currentPage>3 ? 'done' : currentPage===3 ? 'current' : 'disabled last'} aria-disabled={currentPage<3} aria-selected={currentPage<3}>
                                    <a id="steps-uid-0-t-2" href="/" aria-controls="steps-uid-0-p-2" onClick={(e)=>{
                                      if (currentPage > 3) setPageIndex(3);
                                      e.preventDefault();
                                    }}>
                                      <span className="step">2</span> {localize.form_detail_step_distribution}
                                    </a>
                                  </li>
                                  <li role="tab" className={currentPage>4 ? 'done' : currentPage===4 ? 'current' : 'disabled last'} aria-disabled={currentPage<4} aria-selected={currentPage<4}>
                                    <a id="steps-uid-0-t-3"  href="/" aria-controls="steps-uid-0-p-3" onClick={(e)=>{
                                      e.preventDefault();
                                    }}>
                                      <span className="step">3</span> {localize.form_detail_step_review}
                                    </a>
                                  </li>
                                </ul>
                              </div>

                              {
                                currentPage === 1
                                  ? <FirstForm {...{userLanguage, values, touched, errors, setFieldError, nextPage, unit: extOfAmount, setFieldValue, setInputValue, inputValue }} />
                                  : currentPage === 2
                                    ? <SecondForm {...{userLanguage, values, touched, errors, setFieldError, nextPage, prevPage, setInputValue }} />
                                    : currentPage === 3
                                      ? <ThirdForm {...{userLanguage, values, touched, errors, nextPage, prevPage, setFieldValue, setInputValue }} />
                                      : <Redirect to="/coupon" />
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>

        <Footer />
      </div>
    </div>
  );
};

const Coupon = compose(
  withRouter,
  withAuthorization((authUser: any) => !!authUser),
)(CouponBase);
export default Coupon;
