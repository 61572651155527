import { Field, Form } from 'formik';
import React, { useEffect, useState } from 'react';
import LocalizedStrings from 'react-localization';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { getCouponDetail } from '../../functions';
import { genHeadline, genTitleHeadline, Region } from '../CouponList';
import Footer from '../Footer';
import Header from '../Header';
import { withAuthorization } from '../Session';
import Sidebar from '../Sidebar';
import moment from 'moment';

const CouponDetailState = {
  isLoadedData: false,
  data: {},
};

const CouponDetailBase = (props: any ): JSX.Element => {
  const couponId = props.match.params.couponId;
  const [data, setData] = useState(CouponDetailState);
  const [userLanguage, storeProfileData] = useSelector((s: any) => {
    return [ s.userLanguage, s.storeProfile ];
  });
  const storeProfile = storeProfileData.document;

  useEffect(() => {
    const fetchData = async () => {
      if (storeProfile?.id) {
        const result = await getCouponDetail(storeProfile?.id, couponId);
        setData({ isLoadedData: true, data: result?.data?.data });
      }
    };

    fetchData();
  }, [storeProfileData.isLoaded, couponId]);
  const values: any = data.data || null;
  const currentPage = 4;

  const localize = new LocalizedStrings({
    jp: {
      title: 'クーポン作成',
      preview: 'クーポン (プレビュー)',
      title_menu: 'クーポン作成​：詳細',
      disclaimer_preview: '利用規約 (プレビュー)',
      voucher_preview: 'クーポン使用時 (プレビュー)',
      show_the_comment: '上記のクーポンコードをレジ係へお見せください。',
      done: '閉じる',
      percentage_off: '% OFF',
      up_to: 'まで使用可能！',
      down_from: 'から使用可能！',
      item_value: '一個あたり',
      here_is_from_this_coupon: 'クーポンの利用規約',
      here_is_from_business_profile: '店舗の利用規約 (ビジネスプロフィールページより)',
      // Form
      form_detail_title: '新しいクーポンの作成まで少し',
      form_detail_step_detail: '詳細',
      form_detail_step_taget: '対象ユーザー',
      form_detail_step_distribution: '配信タイミング',
      form_detail_step_review: 'レビュー',
      form_detail_sub_head_title: 'プレビューを参照し、さらにサブタイトルをつけて調整できます(オプション)',
      form_detail_sub_head_text: 'スペースを含む最大40文字が入力可能です',
      form_detail_input_placeholder: '初回のみ有効',
      form_detail_available_for: 'クーポンはまもなく有効となります',
      form_detail_delivery: 'クーポン配信設定',
      form_detail_survey: 'アンケート',
      form_detail_answer_option: '選択オプション：',
      form_detail_can_choose: '複数選択可能',
      form_detail_can_choose_multi: 'はい',
      form_detail_cannot_choose_multi: 'なし',
      form_detail_question: 'タイトル: ',
      form_detail_distribution_on: 'クーポンの配信は、{start_at}-{end_at}から開始されます。',
      form_detail_specific_day_type: '来店数に応じてギフトされます',
      form_detail_every_checked_type: 'チェックインするとその場で利用可能です',
      form_detail_specific_count_type: '指定した曜日に応じて利用可能です',
    },
    en: {
      title: 'Create A Coupon',
      preview: 'Coupon Preview',
      title_menu: 'Create A Coupon : Coupon Details',
      disclaimer_preview: 'Disclaimer Preview',
      voucher_preview: 'Voucher Preview',
      show_the_comment: 'Show the redeem code above to the cashier',
      done: 'Done',
      percentage_off: '% OFF',
      up_to: 'Up to',
      down_from: 'Down from',
      item_value: 'Item value is',
      here_is_from_this_coupon: '[Coupon disclaimer]',
      here_is_from_business_profile: '[Service disclaimer defined in the Business Profile page]',
      // Form
      form_detail_title: 'Your coupon is almost set.',
      form_detail_step_detail: 'Coupon Details',
      form_detail_step_taget: 'Target Audience',
      form_detail_step_distribution: 'Distribution Setting',
      form_detail_step_review: 'Review',
      form_detail_sub_head_title: 'See the preview and manually adjust the sub-headline as needed. (Optional)',
      form_detail_sub_head_text: 'Up to 40 characters including spaces',
      form_detail_input_placeholder: 'First time customers only',
      form_detail_available_for: 'This coupon will be available for...',
      form_detail_delivery: 'Delivery Setting',
      form_detail_survey: 'Survey',
      form_detail_answer_option: 'Answer Options:',
      form_detail_can_choose: 'Can shoppers choose multiple options?',
      form_detail_can_choose_multi: 'Yes',
      form_detail_cannot_choose_multi: 'No',
      form_detail_question: 'Question: ',
      form_detail_distribution_on: 'Starts distribution on at {start_at} - {end_at}',
      form_detail_specific_day_type: 'Shoppers who checked in specific times in the past',
      form_detail_every_checked_type: 'Shoppers who checked in every time',
      form_detail_specific_count_type: 'Shoppers can use in the specific day',
    },
  });
  localize.setLanguage(userLanguage === 1 ? 'jp' : 'en');
  const dateFormat = userLanguage === Region.jp ? 'YYYY/MM/DD' : 'MM/DD/YYYY';

  // Waiting for load data is success
  if (data?.isLoadedData === false) {
    return (
      <div>
        <Header />
      </div>
    );
  }

  return (
    <div>
      <Header />
      <Sidebar />
      <div className="page-wrapper">
        <div className="container-fluid">
          {/** Page title */}
          <div className="row page-titles">
            <div className="col-12 align-self-center">
              <h3 className="text-themecolor m-b-0 m-t-0">{localize.title}</h3>

              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/" onClick={(e)=>e.preventDefault}>Home</a>
                </li>
                <li className="breadcrumb-item active">{localize.title_menu}</li>
              </ol>
            </div>
          </div>
          {/* Form */}
          <div id="coupon-form">
            <div className="row">
              {/** Preview */}
              <div className="col-md-5">
                <div className="card">
                  <div className="card-body">
                    <h3>{localize.preview}</h3>
                    <div className="card coupon-preview">
                      <div className="card-body coupon-preview">
                        <div className="row">
                          <div className="col-12">
                            <h4 className="coupon-preview-headline">
                              {genTitleHeadline(values, storeProfile, userLanguage)}
                            </h4>
                            <p className="coupon-preview-subhead">
                              {genHeadline(values, storeProfile, userLanguage)}
                            </p>
                            <div className="coupon-preview-date-time">
                              <p>{values?.start_at || null } - {values?.end_at || null}</p>
                            </div>
                          </div>
                          
                          {/* <div className="col-4">
                            <img src={SwipeSavingImage} alt="Swipe the coupon left or right" className="coupon-preview-swipe-img" />
                          </div> */}
                        </div>
                      </div>
                    </div>

                    <h3>{localize.disclaimer_preview}</h3>
                    <div className="card">
                      <div className="card-body disclaimer-preview">
                        <p>{localize.here_is_from_this_coupon}</p>
                        <p>{values.disclaimer}</p>
                        <p>{localize.here_is_from_business_profile}</p>
                        <p>{storeProfile?.service_disclaimer}</p>
                      </div>
                    </div>

                    <h3>{localize.voucher_preview}</h3>
                    <div className="card">
                      <div className="card-body text-center voucher-preview">
                        <h3>{values.voucher_id}</h3>
                        <p style={{textAlign:'center'}}>{localize.show_the_comment}</p>
                        <button type="button" name="button" className="btn btn-outline-secondary">{localize.done}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/** Form */}
              <div className="col-md-7">
                <div className="row">
                  <div className="col-12">
                    <div className="card wizard-content">
                      <div className="card-body">
                        <div className="validation-wizard wizard-circle wizard clearfix">
                          {/** Visible wizard step */}
                          <div className="steps clearfix">
                            <ul role="tablist">
                              <li role="tab" className="done" aria-disabled={currentPage<1} aria-selected={currentPage<1}>
                                <a id="steps-uid-0-t-0" href="/" aria-controls="steps-uid-0-p-0" onClick={(e)=>{
                                  e.preventDefault();
                                }}>
                                  {/* <span className="current-info audible">current step: </span> */}
                                  <span className="step">1</span> {localize.form_detail_step_detail}
                                </a>
                              </li>
                              <li role="tab" className="done" aria-disabled={currentPage<3} aria-selected={currentPage<3}>
                                <a id="steps-uid-0-t-2" href="/" aria-controls="steps-uid-0-p-2" onClick={(e)=>{
                                  e.preventDefault();
                                }}>
                                  <span className="step">2</span> {localize.form_detail_step_distribution}
                                </a>
                              </li>
                              <li role="tab" className="current" aria-disabled={currentPage<4} aria-selected={currentPage<4}>
                                <a id="steps-uid-0-t-3"  href="/" aria-controls="steps-uid-0-p-3" onClick={(e)=>{
                                  e.preventDefault();
                                }}>
                                  <span className="step">3</span> {localize.form_detail_step_review}
                                </a>
                              </li>
                            </ul>
                          </div>

                          <div className="content clearfix">
                            <section className="body current" role="tabpanel" aria-labelledby="steps-uid-0-h-3" aria-hidden={false}>
                              <div className="row">
                                <h3>{localize.form_detail_title}</h3>
                                <div className="col-12">
                                  <div className="form-group">
                                    <h4 className="card-title m-t-30 m-b-30">{localize.form_detail_sub_head_title}</h4>
                                    {/* <small className="text-right">{localize.form_detail_sub_head_text}</small> */}
                                    <input name="headline" type="text" className="form-control m-b-20" value={genTitleHeadline(values, storeProfile, userLanguage)} disabled/>
                                    <input name="discount_description" type="text" className="form-control m-b-20" value={genHeadline(values, storeProfile, userLanguage)} disabled/>
                                    <input name="sub_headline" type="text" className="form-control" placeholder={localize.form_detail_input_placeholder} value={values.sub_headline} disabled/>
                                  </div>
                                  <div className="review">
                                    <h4 className="card-title m-t-30 m-b-30">{localize.form_detail_available_for}</h4>
                                    <ul className="list-unstyled review-ul">
                                      <li>
                                        <i className="fas fa-check" />
                    &nbsp;&nbsp; {' ' + (values.activation_type === 1 ? localize.form_detail_every_checked_type : values.activation_type === 2 ? localize.form_detail_specific_count_type : localize.form_detail_specific_day_type)}
                                      </li>
                                    </ul>
                                    <h4 className="card-title m-t-30 m-b-30">{localize.form_detail_delivery}</h4>
                                    <ul className="list-unstyled review-ul">
                                      <li>
                                        <i className="fas fa-check" />
                    &nbsp;&nbsp; { localize.formatString(localize.form_detail_distribution_on, {start_at : moment(values.start_at).format(dateFormat + ' HH:mm'), end_at: moment(values.end_at).format(dateFormat + ' HH:mm')})}
                                      </li>
                                    </ul>
                                    {
                                      values.is_survey && <h4 className="card-title m-t-30 m-b-30">{localize.form_detail_survey}</h4>
                                    }
                                    {
                                      values.is_survey && <ul className="list-unstyled review-ul">
                                        <li>{localize.form_detail_question + values.survey.question}</li>
                                        <li>{localize.form_detail_answer_option}</li>
                                        <ul className="list-unstyled">
                                          {values?.survey?.choices?.map((v,i)=>(<li key={i}>&nbsp;&nbsp;{' '+v}</li>))}
                                        </ul>
                                      </ul>
                                    }
                                    {
                                      values.is_survey && <ul className="list-unstyled review-ul">
                                        <li>{localize.form_detail_can_choose}</li>
                                        <ul className="list-unstyled">
                                          <li>
                        &nbsp;&nbsp;{' ' + values.survey.include_multiple_answers ? localize.form_detail_can_choose_multi : localize.form_detail_cannot_choose_multi}
                                          </li>
                                        </ul>
                                      </ul>
                                    }
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End form */}
        </div>

        <Footer />
      </div>
    </div>
  );
};

const CouponDetail = compose(
  withRouter,
  withAuthorization((authUser: any) => !!authUser),
)(CouponDetailBase);

export default CouponDetail;