import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import Footer from '../Footer';
import Header from '../Header';

import { covidAmenityKeys, fbAmenityKeys, generalAmenityKeys, InitState as _InitState, removeHTML, ValidationScheme } from './state_and_validator';
import './index.scss';
import 'firebase/firestore';
import LocalizedStrings from 'react-localization';
import Sidebar from '../Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { withAuthorization } from '../Session';
import { ExtendedFirestoreInstance, useFirestore } from 'react-redux-firebase';
import { getStateAndCityFromZipCode, reindexMediaGallery, updateStore } from '../../functions';
import { toastr } from 'react-redux-toastr';
import { ACTIVATE_LOADING, DEACTIVATE_LOADING } from '../component/overlay-loading';
import { TimePicker } from '@material-ui/pickers';
import moment from 'moment';
import { TextField } from '@material-ui/core';
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import $ from 'jquery';
// Default SortableJS
import Sortable from 'sortablejs';
import ReactMapGL, { MapController, Marker, NavigationControl } from 'react-map-gl';
import Pin from '../component/map-pin';
import MapStyle from './map_style';
import changeIcon from '../../assets/images/icon/change.png';
import QRCode from 'qrcode.react';

const useScript = (id?: string) => {
  useEffect(() => {
    const scriptElem: HTMLScriptElement[] = [];
    const appendScriptSrc = [
      '/plugins/moment/moment.js',
      '/plugins/html5-editor/wysihtml5-0.3.0.js',
      '/plugins/html5-editor/bootstrap-wysihtml5.js',
      '/plugins/bootstrap-material-datetimepicker/js/bootstrap-material-datetimepicker.js',
      '/plugins/clockpicker/dist/jquery-clockpicker.min.js',
      '/js/customize.js',
    ];
    for (const path of appendScriptSrc) {
      const script = document.createElement('script');
      script.src = path;
      document.body.appendChild(script);
      scriptElem.push(script);
    }

    return () => {
      for (const elem of scriptElem) {
        document.body.removeChild(elem);
      }
    };
  });
};

export enum CheckInTypeShowUrl {
  QRUrl = 1,
  NFCUrl = 2,
  BeaconID = 3,
}


const getCategories = (
  region: number,
  setCategories: React.Dispatch<React.SetStateAction<{[key: string]: string[]}>>,
  firestore: ExtendedFirestoreInstance
) => {
  useEffect(() => {
    firestore.collection('category')
      .where('region', '==', region)
      .get()
      .then((snapshot) => {
        const categories = {} as any;
        snapshot.docs.map(v => {
          const data = v.data();
          const keys = Object.keys(data);
          categories[v.id] = keys.filter((v) => !['region', 'sort'].includes(v));
        });
        setCategories(categories);
      });
  }, [true]);
};

const getMediaGalleries = (storeId: string, firestore: ExtendedFirestoreInstance) => {
  return firestore
    .collection('stores').doc(storeId)
    .collection('media-galleries')
    .where('is_disabled', '==', false)
    .orderBy('index')
    .get();
};

const BusinessProfileBase = () => {
  const [userLanguage, storeProfileData] = useSelector((s: any) => {
    return [ s.userLanguage, s.storeProfile ];
  });
  const [mediaGalleries, setMediaGalleries] = useState<any>([]);

  useScript(storeProfileData?.document?.id);
  useEffect(() => {
    if (storeProfileData.isLoaded) {
      onEditorStateChange(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(storeProfileData.document.about_business).contentBlocks, convertFromHTML(storeProfileData.document.about_business).entityMap)));
      
      getMediaGalleries(storeProfileData.document.id, firestore)
        .then((filledData) => {
          if (filledData.empty) return;
          setMediaGalleries(filledData.docs);
        });
    }
  }, [storeProfileData.isLoaded]);

  const [categoriesData, setCategories] = useState({} as {[key: string]: string[]});
  const [editorState, onEditorStateChange] = useState(EditorState.createEmpty());
  const [viewport, setViewport] = useState({
    latitude: 0,
    longitude: 0,
    zoom: 14,
  });
  const [marker, setMarker] = useState({
    latitude: 0,
    longitude: 0,
  });
  
  const dispatch = useDispatch();
  const firestore = useFirestore();
  getCategories(userLanguage, setCategories, firestore);
  const categories = Object.keys(categoriesData ?? []);

  if (!storeProfileData.isLoaded) {
    return (
      <div>
        <Header />
      </div>
    );
  } else if (storeProfileData.isLoaded && marker.latitude === 0) {
    const location = storeProfileData.document.location.geopoint as firebase.default.firestore.GeoPoint;
    setMarker({
      latitude: location.latitude,
      longitude: location.longitude,
    });

    setViewport({
      latitude: location.latitude,
      longitude: location.longitude,
      zoom: 14,
    });
  }

  const storeProfile = storeProfileData.document;
  const country = storeProfile.region === 1 ? 'Japan' : 'USA';
  storeProfile.country = storeProfile.country ?? country;
  if (mediaGalleries && mediaGalleries.length > 0) {
    const el = document.getElementById('simpleList');
    const sortable = Sortable.create(el);
  }

  const localize = new LocalizedStrings({
    jp: {
      title: '店舗情報',
      title_home: 'Home',
      // Preview
      asset_preview: 'プレビュー',
      asset_preview_subtitle: 'スライド順にアプリで表示されます',
      edit_slide: 'スライド編集',
      phone: '電話番号 ',
      url: 'URL ',
      hours_of_operation: '営業時間',

      // Basic
      basic_info: '基本情報',
      business_name: '店舗名',
      branch_name: '支店名',
      tel_number: '電話番号',
      category: 'カテゴリー',
      sub_category: 'サブカテゴリー',
      email_address: 'E-mail アドレス',
      homepage: 'ホームページ',

      // Address
      address: '住所',
      address1: '以降の住所',
      address2: '部屋番号',
      zip_code: '郵便番号 (ハイフンなし)',
      city: '市区町村',
      state: '都道府県',
      country: '国',

      about_business: 'あなたのビジネスについて',
      service_disclaimer: 'サービス利用における免責事項',

      // Operation Hours
      operation_hour: '営業時間',
      closed_on_federal_holidays: '祝日は休業日',
      yes: 'はい',
      monday: '月曜日',
      tuesday: '火曜日',
      wednesday: '水曜日',
      thursday: '木曜日',
      friday: '金曜日',
      saturday: '土曜日',
      sunday: '日曜日',
      business_hour: '営業時間 ',
      closed_day: '定休日',
      open_24h_hours: '24時間営業',
      end_at: '閉店時間',
      start_at: '開店時間',

      // Service Amenities
      service_amenities: 'サービスアメニティー',
      coivd19_amenity: 'コロナ対策',
      general_amenity: '基本アメニティー',
      fb_amenity: '飲食',

      // Covid19
      required_masks: 'マスク着用の義務',
      staff_wears_masks: 'スタッフのマスク着用',
      staff_wears_gloves: 'スタフのグローブ着用',
      social_distancing: 'ソーシャルディスタンス',
      limited_capacity: '受け入れ人数の制限',
      checks_temperature: '入店時の体温測定',
      hand_sanitizer: '入店時の消毒',
      staff_sanitizes_between_customers: 'スタッフによる消毒',
      curbside_pick_up: '乗車中の商品受け渡し（カーブサイド）',

      // General
      accepts_credit: 'クレジット決済',
      accepts_apple_pay: 'ApplePay決済',
      accepts_google_pay: 'GooglePay決済',
      pet_friendly: 'ペット入店',
      free_wifi: 'フリー Wi-FI',
      good_for_dates: 'デートにぴったり',
      good_for_kids: '子連れ歓迎',
      good_for_groups: 'グループ歓迎',
      street_parking: '路面駐車',
      garage_parking: 'ガレージ駐車',
      parking_available: '付近にパーキング',
      valet_parking: '係員付き駐車場',
      bike_parking: 'バイク駐車',
      smoke_free: '喫煙可能',
      smoking_area_available: '喫煙可能エリアあり',
      powder_room_available: '化粧室あり',
      wheelchair_accessible: '車椅子入店',
      tv: 'TV',
      gender_neutral_restroom_available: '性別区別のないトイレ',
      bilingual_staff: 'バイリンガルスタッフ',
      public_transportation_nearby: '公共交通機関が近い',
      quiet_ambiance: '静かな雰囲気',

      // F&B
      price_range: '価格帯',
      dress_code: 'ドレスコード',
      casual: 'カジュアル',
      business_casual: 'ビジネスカジュアル',
      upscale: '高級',
      option_vegetarian: 'ベジタリアンオプション',
      option_vegan: 'ビーガンオプション',
      happy_hour: 'ハッピーアワー',
      outdoor_seating: 'アウトドア席',
      delivery: 'デリバリー',
      pick_up: '商品のお引き渡し',
      takeout: 'テイクアウト',
      drive_thru: 'ドライブスルー',
      sit_down_dining: 'ダイニング席',
      waitstaff_service: '給仕サービス',
      full_bar: '酒類の提供',
      sommelier: 'ソムリエ',
      private_dining: '個席',
      good_for_breakfast: '朝食に最適',
      good_for_lunch: 'ランチに最適',
      good_for_dinner: 'ディナーに最適',
      good_for_late_night: '深夜での飲食',
      accepts_reservations: '予約受付',

      // Feedbacks
      character50: ' スペースを含む最大50文字が入力可能です',
      character100: ' スペースを含む最大100文字が入力可能です',
      character400: ' スペースを含む最大400文字が入力可能です',
      character800: ' スペースを含む最大800文字が入力可能です',

      errors: {
        min: '{min_length}文字以上で入力してください。',
        max: '{max_length}字以内で入力してください。',
        invalid_email: '無効なメール。',
        required: '必須項目。',
        invalid_phone_number: 'この電話番号は無効です。',
        invalid_password: '無効なパスワード。',
        mismatch_password: 'パスワードが一致しません。',
        empty_city: '市を入力してください。',
        empty_state: '州を選択してください。',
        invalid_url: '接続URLのドメインが見つかりません。',
        invalid_zip_code: 'この郵便番号は無効です。',
        time_from_greater_time_to: '閉店時間が開店時間よりも早くなっています。',
      },

      placeholder: {
        business_name: '例　アニマル動物園',
        branch_name: '例　お台場店',
      },

      // Status
      _complete_title: 'アップロード完了',
      _complete_message: '変更を保存しました！',
      _validate_error_title: '入力値が不正です',
      _validate_error_message: 'エラーが表示されている各項目を修正してください。',
      _undefined_error_title: 'サーバーエラー',
      _undefined_error_message: '申し訳ありませんが、時間を開けて再度お試しください。',

      edit_order_images: '画像の表示順を編集',
      edit_order_images_register_image_description: '画像をまだアップロードしていない場合は下のリンクをクリックしてください。',
      edit_order_images_register_image: '画像のアップロード',
      edit_order_images_save: '保存',
      edit_order_images_cancel: 'キャンセル​',
      button_copy_text: 'コピー',
    },
    en: {
      title: 'Business Profile',
      title_home: 'Home',
      // Preview
      asset_preview: 'Asset Preview',
      asset_preview_subtitle: 'These images are being shown on the ZOOO app.',
      edit_slide: 'Edit Slide',
      phone: 'Phone ',
      url: 'URL ',
      hours_of_operation: 'Hours Of Operation ',

      // Basic Info
      basic_info: 'Basic Info',
      business_name: 'Business Name ',
      branch_name: 'Branch Name (If any)',
      tel_number: 'Business Phone Number *',
      category: 'Business Category *',
      sub_category: 'Business Sub Category *',
      email_address: 'Email Address *',
      homepage: 'Business URL',

      // Address
      address: 'Address',
      address1: 'Street Address *',
      address2: 'Unit / Apt / Suite Number',
      zip_code: 'Zip Code *',
      city: 'City',
      state: 'State',
      country: 'Country',

      about_business: 'About Business',
      service_disclaimer: 'Service Disclaimer',

      // Operation Hours
      operation_hour: 'Operation Hours',
      closed_on_federal_holidays: 'Closed on Federal holidays',
      yes: 'Yes',
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
      sunday: 'Sunday',
      business_hour: 'Business hour ',
      closed_day: 'Closed',
      open_24h_hours: 'Open 24 hours',
      end_at: 'Closes at',
      start_at: 'Opens at',

      // Service Amenities
      service_amenities: 'Service Amenities',
      coivd19_amenity: 'COVID Updated Service',
      general_amenity: 'General',
      fb_amenity: 'F&B Specific',

      // Covid19
      required_masks: 'Masks are required',
      staff_wears_masks: 'Staff wears masks',
      staff_wears_gloves: 'Staff wears gloves',
      social_distancing: 'Social distancing enforced',
      limited_capacity: 'Operating at limited capacity',
      checks_temperature: 'Temperature checks performed',
      hand_sanitizer: 'Hand sanitizer provided',
      staff_sanitizes_between_customers: 'Staff sanitizes between customers',
      curbside_pick_up: 'Curbside Pick-Up is available',

      // General
      accepts_credit: 'Accepts Credit Cards',
      accepts_apple_pay: 'Accepts Apple Pay',
      accepts_google_pay: 'Accepts Google pay',
      pet_friendly: 'Pet-Friendly',
      free_wifi: 'Free Wi-Fi',
      good_for_dates: 'Good for dates!',
      good_for_kids: 'Good for kids!',
      good_for_groups: 'Good for groups!',
      street_parking: 'Street Parking',
      garage_parking: 'Garage Parking',
      parking_available: 'Parking Available',
      valet_parking: 'Valet Parking',
      bike_parking: 'Bike Parking',
      smoke_free: 'Smoke-Free',
      smoking_area_available: 'Smoking Area Available',
      powder_room_available: 'Powder Room Available',
      wheelchair_accessible: 'Wheelchair Accessible',
      tv: 'TV',
      gender_neutral_restroom_available: 'Gender-Neutral Restrooms available',
      bilingual_staff: 'Bilingual / Multilingual Staff',
      public_transportation_nearby: 'Public Transportation nearby',
      quiet_ambiance: 'Quiet Ambiance',

      // F&B
      price_range: 'Price Range',
      dress_code: 'Dress Code',
      casual: 'Casual',
      business_casual: 'Business Casual',
      upscale: 'Upscale',
      option_vegetarian: 'Vegetarian Options Available',
      option_vegan: 'Vegan Options Available',
      happy_hour: 'Happy Hour',
      outdoor_seating: 'Outdoor/Patio Seating Available',
      delivery: 'Delivery',
      pick_up: 'Pick-Up',
      takeout: 'Takeout',
      drive_thru: 'Drive-Thru',
      sit_down_dining: 'Sit-Down Dining',
      waitstaff_service: 'Waitstaff Service',
      full_bar: 'Full Bar',
      sommelier: 'Sommelier',
      private_dining: 'Private Dining',
      good_for_breakfast: 'Good for breakfast!',
      good_for_lunch: 'Good for lunch!',
      good_for_dinner: 'Good for dinner!',
      good_for_late_night: 'Good for late-night!',
      accepts_reservations: 'Accepts Reservations',

      // Feedbacks
      character50: ' Up to 50 characters including spaces',
      character100: ' Up to 100 characters including spaces',
      character400: ' Up to 400 characters including spaces',
      character800: ' Up to 800 characters including spaces',

      errors: {
        min: 'Please enter at least {min_length} characters',
        max: 'Up to {max_length} characters including spaces',
        invalid_email: 'Invalid email.',
        required: 'Required field',
        invalid_phone_number: 'Invalid phone number',
        invalid_password: 'Invalid password',
        mismatch_password: 'Password not equal',
        empty_city: 'Please enter the city your business is registered in',
        empty_state: 'Please enter the state your business is registered in',
        invalid_url: 'Missing domain in continue url',
        invalid_zip_code: 'The zip code you entered is invalid',
        time_from_greater_time_to: 'The end time is earlier than the start time.',
      },

      placeholder: {
        business_name: 'ZOOO Stake Link',
        branch_name: 'Spring Valley',
      },

      // Status
      _complete_title: 'Success!',
      _complete_message: 'Your changes have been saved.',
      _validate_error_title: 'Invalid value',
      _validate_error_message: 'Please check and fix field has problems',
      _undefined_error_title: 'Server unable',
      _undefined_error_message: 'Sorry, you\'re getting an error. Please later.',

      edit_order_images: 'Edit Image Order',
      edit_order_images_register_image_description: 'If you have not registered the image, please register the image.',
      edit_order_images_register_image: 'Register the images',
      edit_order_images_save: 'Save',
      edit_order_images_cancel: 'Cancel',
      button_copy_text: 'COPY',
    },
  });
  localize.setLanguage(userLanguage === 1 ? 'jp' : 'en');

  const renderPrevAndNext = () => {
    if (mediaGalleries && mediaGalleries.length > 0) {
      return (
        <>
          <a className="carousel-control-prev" href="#carouselExampleIndicators2" role="button" data-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="sr-only">Previous</span>
          </a>
          <a className="carousel-control-next" href="#carouselExampleIndicators2" role="button" data-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="sr-only">Next</span>
          </a>
        </>
      );
    }

    return (null);
  };

  const handleClick = ({lngLat: [longitude, latitude]}) => 
  {
    setMarker({
      latitude: latitude,
      longitude: longitude,
    });

    try {
      const location = storeProfile.location.geopoint as firebase.default.firestore.GeoPoint;
      if (longitude != location.longitude && latitude != location.latitude) {
        const updateMarker = {
          marker: {
            latitude: latitude,
            longitude: longitude,
          }
        };
        updateStore(updateMarker, storeProfile.id);
      }
      
      toastr.success(localize._complete_title, localize._complete_message);
    } catch (err) {
      console.error(err);
      toastr.error(localize._undefined_error_title, localize._undefined_error_message);
    }

  };

  return (
    <Formik
      initialValues={storeProfile}
      enableReinitialize={true}
      validationSchema={ValidationScheme}
      onSubmit={async (values, _) => {
        try {
          const location = storeProfileData.document.location.geopoint as firebase.default.firestore.GeoPoint;
          if (marker && marker.longitude != location.longitude && marker.latitude != location.latitude) {
            values.marker = marker;
          }
          const data = Object.assign({}, values);
          dispatch({ type: ACTIVATE_LOADING });
          const ret = await updateStore(data, storeProfile.id);
          setMarker({
            latitude: ret.data.storeModel.location.geopoint._latitude,
            longitude: ret.data.storeModel.location.geopoint._longitude,
          });
          setViewport({
            latitude: ret.data.storeModel.location.geopoint._latitude,
            longitude: ret.data.storeModel.location.geopoint._longitude,
            zoom: 14,
          });
          toastr.success(localize._complete_title, localize._complete_message);
        } catch (err) {
          console.error(err);
          toastr.error(localize._undefined_error_title, localize._undefined_error_message);
        } finally {
          dispatch({ type: DEACTIVATE_LOADING });
        }
      }}>
      {({ values, touched, errors, setFieldValue, handleBlur, setFieldError, handleReset }) => {
        values.fb_amenity.dress_code = parseInt(values.fb_amenity.dress_code);
        values.fb_amenity.price_range = parseInt(values.fb_amenity.price_range);
        const addViper = (e: any, day: string, index: number) => {
          if (values.operation_hour[day].start_at.length >= 3) return;
          values.operation_hour[day].start_at.push('00:00');
          values.operation_hour[day].end_at.push('00:00');
        };
        const spliceViper = (e: any, day: string, index: number) => {
          if (values.operation_hour[day].start_at.length <= 1) return;
          values.operation_hour[day].start_at.splice(index, 1);
          values.operation_hour[day].end_at.splice(index, 1);
        };
        const onBlur = async (event) => {
          handleBlur(event);
          if (values.zip_code?.length >= 5) {
            try {
              const info = await getStateAndCityFromZipCode(values.zip_code, userLanguage===1 ? 'ja' : 'en');
              setFieldValue('city', info.city);
              setFieldValue('state', info.state);
              setFieldValue('country', info.region === 1 ? 'Japan' : 'USA');
            } catch (err) {
              console.error(err);
              setFieldError('zip_code', {message:'invalid_zip_code'} as any);
            }
          } else {
            setFieldValue('city', '');
            setFieldValue('state', '');
            setFieldValue('country', '');
          }
        };

        const checkZipCode = async (event) => {
          handleBlur(event);
          if (values.zip_code?.length >= 5) {
            try {
              const info = await getStateAndCityFromZipCode(values.zip_code, userLanguage===1 ? 'ja' : 'en');
            } catch (err) {
              console.error(err);
              setFieldError('zip_code', {message:'invalid_zip_code'} as any);
            }
          }
        };

        const resetForm = () => {
          handleReset();
          onEditorStateChange(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(storeProfileData.document.about_business).contentBlocks, convertFromHTML(storeProfileData.document.about_business).entityMap)));
        };

        const copyToClipboard = (type) => {
          let value = '';
          switch(type) {
          case CheckInTypeShowUrl.NFCUrl: 
            value = storeProfile.nfc_dynamic_link;
            break;
            
          case CheckInTypeShowUrl.BeaconID: 
            value = storeProfile.major_id;
            break;

          case CheckInTypeShowUrl.QRUrl: 
          default: 
            value = storeProfile.qr_code_dynamic_link;
            break;
          }

          navigator.clipboard.writeText(value);
        };

        return (
          <Form>
            <div>
              <Header />
              <Sidebar />

              <div className="page-wrapper">
                <div className="container-fluid">

                  {/** Page Title */}
                  <div className="row page-titles">
                    <div className="col-md-5 col-8 align-self-center">
                      <h3 className="text-themecolor m-b-0 m-t-0">{localize.title}</h3>
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <a href="/" onClick={(e)=>e.preventDefault()}>{localize.title_home}</a>
                        </li>
                        <li className="breadcrumb-item active">{localize.title}</li>
                      </ol>
                    </div>
                  </div>

                  {/** Page Content */}
                  <div className="row">
                    {/** Preview */}
                    <div className="col-lg-4 col-xlg-3 col-md-5">
                      <div className="card">
                        <div className="card-body">
                          <div className="col-sm-12">
                            <h4 className="card-title">{localize.asset_preview}</h4>
                            <h6 className="card-subtitle">{localize.asset_preview_subtitle}</h6>

                            {/** Preview Media gallery */}
                            <div id="carouselExampleIndicators2" className="carousel slide" data-ride="carousel">
                              <ol className="carousel-indicators">
                                {
                                  (mediaGalleries && mediaGalleries.length > 0) ? (
                                    mediaGalleries.map((value, index) => {
                                      return (<li data-target="#carouselExampleIndicators2" data-slide-to={index} className={index === 0 ? 'active' : ''} key={index}></li>);
                                    })
                                  ) : (null)
                                }
                              </ol>
                              <div className="carousel-inner" role="listbox">
                                {
                                  (mediaGalleries && mediaGalleries.length > 0) ? (
                                    mediaGalleries.map((value, index) => {
                                      if (!value.id) return;
                                      const media = value.data();
                                      return (
                                        <div className={'carousel-item ' + (index === 0 ? 'active' : '')} key={index}>
                                          <img className="img-responsive" src={media.thumbnail_filepath || media.filepath} style={{width: '300px', height:'200px'}} alt="user"/>
                                        </div>
                                      );
                                    })
                                  ) : (
                                    <a href="/media-gallery">Media Gallery</a>
                                  )
                                }
                              </div>
                              {renderPrevAndNext()}
                            </div>
                            <div className="modal fade edit-gallery-modal" tabIndex={-1} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" style={{display: 'none'}}>
                              <div className="modal-dialog modal-xl">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h4 className="modal-title" id="myLargeModalLabel">{localize.edit_order_images}</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                                  </div>
                                  <div className="modal-body">
                                    <div className="row el-element-overlay">
                                      <div className="col-md-12">
                                        <div className="card" style={{border: 'none', boxShadow:'none', marginLeft:'20px'}}>
                                          {/* <div className="card-body"> */}
                                          {/* <h4 className="card-title">Upload Images</h4> */}
                                          <label htmlFor="input-file-max-fs">{localize.edit_order_images_register_image_description}</label>
                                          <a href="/media-gallery" style={{textDecoration: 'underline', fontWeight: 'bold'}}>{localize.edit_order_images_register_image}</a>
                                          {/* </div> */}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row el-element-overlay list-group" id="simpleList">
                                      {(mediaGalleries && mediaGalleries.length > 0) ? (
                                        mediaGalleries.map((value, index) => {
                                          if (!value.id) return;
                                          const media = value.data();
                                          return (
                                            <div className="col-lg-3 col-md-6 media-image" key={value.id} id={value.id}>
                                              <div className="card">
                                                <div className="el-card-item">
                                                  <div className="el-card-avatar el-overlay-1"> <img src={media.thumbnail_filepath} alt={media.title} />
                                                    <div className="el-overlay">
                                                      {/* <ul className="el-info">
                                                        <li><a className="btn default btn-outline" href="" data-toggle="tooltip" title="Move To Trash"><i className="icon-trash"></i></a></li>
                                                      </ul> */}
                                                    </div>
                                                  </div>
                                                  <div className="el-card-content">
                                                    <i className="icon-menu"></i>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                        )
                                      ) : (null)}
                                    </div>
                                  </div>
                                  <div className="modal-footer">
                                    <button type="button" className="btn btn-success waves-effect text-left" data-dismiss="modal" onClick={async (_) => {
                                      try {
                                        dispatch({ type: ACTIVATE_LOADING });
                                        const arrayIdAfterSort : any = [];
                                        $('#simpleList .media-image').each(function(i, obj) {
                                          const mediaReindexData = {
                                            media_gallery_id: obj.id,
                                            index: i + 1,
                                          };
                                          arrayIdAfterSort.push(mediaReindexData);
                                        });
                                        await reindexMediaGallery(arrayIdAfterSort, storeProfileData.document.id);
                                        await getMediaGalleries(storeProfileData.document.id, firestore)
                                          .then((filledData) => {
                                            if (filledData.empty) return;
                                            setMediaGalleries(filledData.docs);
                                          });
                                        toastr.success(localize._complete_title, localize._complete_message);
                                      } catch (err) {
                                        console.error(err);
                                        toastr.error(localize._undefined_error_title, localize._undefined_error_message);
                                      } finally {
                                        dispatch({ type: DEACTIVATE_LOADING });
                                      }
                                    }}>{localize.edit_order_images_save}</button>
                                    <button type="button" className="btn btn-danger waves-effect text-left" data-dismiss="modal">{localize.edit_order_images_cancel}</button>
                                  </div>
                                </div>
                                {/* <!-- /.modal-content --> */}
                              </div>
                              {/* <!-- /.modal-dialog --> */}
                            </div>

                            {/** Button of edit slide */}
                            <div className="text-center">
                              <button type="button" name="button" className="btn btn-success btn-rounded m-t-20 text-center" data-toggle="modal" data-target=".edit-gallery-modal"> {localize.edit_slide}</button>
                            </div>
                          </div>
                        </div>

                        <div />

                        <div className="card-body">
                          <small className="text-muted">{localize.about_business}</small>
                          <p className="text-themecolor" dangerouslySetInnerHTML={{__html: values.about_business}} />

                          <small className="text-muted p-t-30 db">{localize.email_address + ' '}</small>
                          <h6>{values.email_address}</h6>

                          <small className="text-muted p-t-30 db">{localize.phone}</small>
                          <h6>{values.tel_number}</h6>

                          <small className="text-muted p-t-30 db">{localize.url}</small>
                          <h6>{values.homepage}</h6>

                          <small className="text-muted p-t-30 db">{localize.address}</small>
                          <h6>{values.address1 + ' ' + values.address2}</h6>

                          <div className="map-box">
                            <ReactMapGL
                              {...viewport}
                              // zoom={16}
                              mapStyle={MapStyle}
                              width="100%"
                              height="300px"
                              attributionControl={false}
                              mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
                              onViewportChange={(viewport) => setViewport(viewport)}
                              onClick={handleClick}
                            >
                              <NavigationControl showCompass={false}></NavigationControl>
                              <Marker latitude={marker.latitude} longitude={marker.longitude} offsetLeft={-10} offsetTop={-20}>
                                <Pin size={20} />
                                <div>{storeProfile.business_name}</div>
                              </Marker>
                            </ReactMapGL>
                          </div>
                          <br />
                          <small className="text-muted">{localize.service_disclaimer}</small>
                          <p className="text-themecolor" dangerouslySetInnerHTML={{__html: values.service_disclaimer}} />

                          <small>{localize.hours_of_operation}</small>
                          <div className="table-responsive">
                            <table className="table">
                              <tbody className="text-themecolor">
                                {
                                  ['monday','tuesday','wednesday','thursday','friday','saturday','sunday'].map((v, i) => {
                                    return (
                                      <tr key={i}>
                                        <td>
                                          <h6>{localize[v]}</h6>
                                        </td>
                                        <td>
                                          {
                                            values.operation_hour[v].closed_day
                                              ? <h6>{localize.closed_day}</h6>
                                              : values.operation_hour[v].opened_24h_day
                                                ? <h6><span>{localize.open_24h_hours}</span></h6>
                                                : values.operation_hour[v].start_at.map((_v, _i) => {
                                                  return (
                                                    <h6 key={`${i}.${_i}`}>
                                                      <span>{_v}</span>
                                                      {' - '}
                                                      <span>{values.operation_hour[v].end_at[_i]}</span>
                                                    </h6>
                                                  );
                                                })
                                          }
                                        </td>
                                      </tr>
                                    );
                                  })
                                }
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-body">
                          <div className="m-t-20">
                            <div>
                              <QRCode style={{width: '200px', height: '200px'}} className="qr-code" value={storeProfile.qr_code_dynamic_link} />
                            </div>
                            <div className='row m-t-30'>
                              <div className="col-sm-12 d-flex">
                                <p className="show-url-p">QR URL</p>
                                <input className="form-control m-r-10 m-l-10" value={storeProfile.qr_code_dynamic_link}/>
                                <button type='button' className="btn btn-success text-center button-copy" onClick={() => copyToClipboard(CheckInTypeShowUrl.QRUrl)}>{localize.button_copy_text}</button>
                              </div>
                            </div>


                            <div className='row m-t-10'>
                              <div className="col-sm-12 d-flex">
                                <p className="show-url-p">NFC URL</p>
                                <input className="form-control m-r-10 m-l-10" value={storeProfile.nfc_dynamic_link}/>
                                <button type='button' className="btn btn-success text-center button-copy" onClick={() => copyToClipboard(CheckInTypeShowUrl.NFCUrl)}>{localize.button_copy_text}</button>
                              </div>
                            </div>

                            <div className='row m-t-10 m-b-10'>
                              <div className="col-sm-12 d-flex">
                                <p className="show-url-p">Beacon ID</p>
                                <input className="form-control m-r-10 m-l-10" value={storeProfile.major_id}/>
                                <button type='button' className="btn btn-success text-center button-copy" onClick={() => copyToClipboard(CheckInTypeShowUrl.BeaconID)}>{localize.button_copy_text}</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/** Input fields */}
                    <div className="col-lg-8 col-xlg-9 col-md-7">
                      {/** Business Detail */}
                      <div className="card">
                        <div className="card-body">
                          {/** Basic Info */}
                          <div className="form-body">
                            <h3 className="card-title">{localize.basic_info}</h3>
                            <hr />
                            <div className="row p-t-20">
                              <div className="col-md-7">
                                <div className="form-group">
                                  <label className="control-label" htmlFor="business_name">
                                    {localize.business_name}
                                    <span className="themecolor">*</span>
                                  </label>
                                  <Field name="business_name" type="text" id="business_name" className="form-control" placeholder={localize.placeholder.business_name} />
                                  {
                                    touched.business_name && errors.business_name &&
                                    <p className="text-danger">{localize.formatString(localize.errors[errors.business_name['message'] as string], {min_length: errors.business_name['length'] as number , max_length: errors.business_name['length'] as number})}</p>
                                  }
                                </div>
                              </div>
                              <div className="col-md-5">
                                <div className="form-group">
                                  <label className="control-label" htmlFor="branch_name">{localize.branch_name}</label>
                                  <Field name="branch_name" type="text" id="branch_name" className="form-control form-control-danger" placeholder={localize.placeholder.branch_name} maxLength={50} />
                                  <small className="form-control-feedback">{localize.character50}</small>
                                  {
                                    touched.branch_name && errors.branch_name &&
                                    <p className="text-danger">{localize.formatString(localize.errors[errors.branch_name['message'] as string], {min_length: errors.branch_name['length'] as number , max_length: errors.branch_name['length'] as number})}</p>
                                  }
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="control-label">{localize.tel_number}</label>
                                  <Field name="tel_number" type="tel" className="form-control" placeholder="7021231234" disabled />
                                  {
                                    touched.tel_number && errors.tel_number &&
                                    <p className="text-danger">{localize.formatString(localize.errors[errors.tel_number['message'] as string], {min_length: errors.tel_number['length'] as number , max_length: errors.tel_number['length'] as number})}</p>
                                  }
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="control-label">{localize.category}</label>
                                  <Field name="category" as="select" className="form-control custom-select">
                                    {
                                      !!categories && [(<option value="" key={0} />), ...categories.map((v) => {
                                        return (<option value={v} key={v}>{v}</option>);
                                      })]
                                    }
                                  </Field>
                                  {
                                    touched.category && errors.category &&
                                    <p className="text-danger">{localize.formatString(localize.errors[errors.category['message'] as string], {min_length: errors.category['length'] as number , max_length: errors.category['length'] as number})}</p>
                                  }
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-5">
                                <label className="control-label">{localize.sub_category}</label>
                                <Field name="sub_category" as="select" className="form-control custom-select">
                                  <option defaultChecked={true} value="" />
                                  {
                                    !!values.category && categoriesData[values.category] && categoriesData[values.category].map((v) => {
                                      return (<option value={v} key={v}>{v}</option>);
                                    })
                                  }
                                </Field>
                                {
                                  touched.sub_category && errors.sub_category &&
                                  <p className="text-danger">{localize.formatString(localize.errors[errors.sub_category['message'] as string], {min_length: errors.sub_category['length'] as number , max_length: errors.sub_category['length'] as number})}</p>
                                }
                              </div>
                              <div className="col-md-7">
                                <div className="form-group">
                                  <label className="control-label">{localize.email_address}</label>
                                  <Field name="email_address" type="email" className="form-control" placeholder="example@gmail.com" />
                                  {
                                    touched.email_address && errors.email_address &&
                                    <p className="text-danger">{localize.formatString(localize.errors[errors.email_address['message'] as string], {min_length: errors.email_address['length'] as number , max_length: errors.email_address['length'] as number})}</p>
                                  }
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <div className="form-group">
                                  <label className="control-label">{localize.homepage}</label>
                                  <Field name="homepage" type="url" className="form-control" placeholder="https://example.com" />
                                  {
                                    touched.homepage && errors.homepage &&
                                    <p className="text-danger">{localize.formatString(localize.errors[errors.homepage['message'] as string], {min_length: errors.homepage['length'] as number , max_length: errors.homepage['length'] as number})}</p>
                                  }
                                </div>
                              </div>
                            </div>

                            <h3 className="box-title m-t-40">{localize.address}</h3>
                            <hr />

                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label>{localize.address1}</label>
                                  <Field name="address1" type="text" className="form-control" maxLength={100} />
                                  <small className="form-control-feedback">{' ' + localize.character100}</small>
                                  {
                                    touched.address1 && errors.address1 &&
                                    <p className="text-danger">{localize.formatString(localize.errors[errors.address1['message'] as string], {min_length: errors.address1['length'] as number , max_length: errors.address1['length'] as number})}</p>
                                  }
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label>{localize.address2}</label>
                                  <Field name="address2" type="text" className="form-control" maxLength={100} />
                                  <small className="form-control-feedback">{' ' + localize.character100}</small>
                                  {
                                    touched.address2 && errors.address2 &&
                                    <p className="text-danger">{localize.formatString(localize.errors[errors.address2['message'] as string], {min_length: errors.address2['length'] as number , max_length: errors.address2['length'] as number})}</p>
                                  }
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>{localize.city}</label>
                                  <Field name="city" type="text" className="form-control"/>
                                  {
                                    touched.city && errors.city &&
                                    <p className="text-danger">{localize.formatString(localize.errors[errors.city['message'] as string], {min_length: errors.city['length'] as number , max_length: errors.city['length'] as number})}</p>
                                  }
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>{localize.state}</label>
                                  <Field name="state" type="text" className="form-control"/>
                                  {
                                    touched.state && errors.state &&
                                    <p className="text-danger">{localize.formatString(localize.errors[errors.state['message'] as string], {min_length: errors.state['length'] as number , max_length: errors.state['length'] as number})}</p>
                                  }
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-5">
                                <div className="form-group">
                                  <label>{localize.zip_code}</label>
                                  <Field name="zip_code" type="text" className="form-control" maxLength={7} onBlur={checkZipCode}/>
                                  {
                                    touched.zip_code && errors.zip_code &&
                                    <p className="text-danger">{localize.formatString(localize.errors[errors.zip_code['message'] as string], {min_length: errors.zip_code['length'] as number , max_length: errors.zip_code['length'] as number})}</p>
                                  }
                                 
                                </div>
                              </div>
                              <div className="col-md-1">
                                <div className="form-group">
                                  <label>&nbsp;&nbsp;&nbsp;&nbsp;</label>
                                  <button type='button' style={{border: '0', background: 'transparent'}} onClick={onBlur}>
                                    <img src={changeIcon} width="36" height="36" alt="submit" />
                                  </button>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>{localize.country}</label>
                                  <Field name='country' type="text" className="form-control"/>
                                  {
                                    touched.country && errors.country &&
                                    <p className="text-danger">{localize.formatString(localize.errors[errors.country['message'] as string], {min_length: errors.country['length'] as number , max_length: errors.country['length'] as number})}</p>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <label>{localize.about_business}</label>
                            <Editor
                              editorState={editorState}
                              wrapperClassName="demo-wrapper"
                              editorClassName="demo-editor"
                              onEditorStateChange={(_) => {
                                setFieldValue('about_business', draftToHtml(convertToRaw(_.getCurrentContent())));
                                touched.about_business = true;
                                onEditorStateChange(_);
                              }}
                              toolbarStyle={{
                                borderRadius: '.25rem',
                                border: '1px solid #ced4da',
                                marginBottom: '-1px',
                              }}
                              editorStyle={{
                                height: '222px',
                                borderRadius: '.25rem',
                                border: '1px solid #ced4da',
                                padding: '.375rem .75rem',
                                width: '100%',
                                fontSize: '1rem',
                                lineHeight: 1.5,
                                backgroundClip: 'padding-box',
                                color: '#495057',
                              }}
                            />
                            <small className="form-control-feedback">{' '+localize.character800}</small>
                            <p className="form-control-feedback" style={{float: 'right'}}>{((removeHTML(values.about_business).length || 0 ) + '/' + 800)}</p>
                            {
                              errors.about_business &&
                              <p className="text-danger">{localize.formatString(localize.errors[errors.about_business['message'] as string], {min_length: errors.about_business['length'] as number , max_length: errors.about_business['length'] as number})}</p>
                            }
                            <div className="form-helper" />
                          </div>
                          <div className="form-group">
                            <label>{localize.service_disclaimer}</label>
                            <Field name="service_disclaimer" className="form-control" as="textarea" rows={5} />
                            <small className="form-control-feedback">{' '+localize.character400}</small>
                            <p className="form-control-feedback" style={{float: 'right'}}>{((values.service_disclaimer.length || 0 ) + '/' + 400)}</p>
                            {
                              touched.service_disclaimer && errors.service_disclaimer &&
                              <p className="text-danger">{localize.formatString(localize.errors[errors.service_disclaimer['message'] as string], {min_length: errors.service_disclaimer['length'] as number , max_length: errors.service_disclaimer['length'] as number})}</p>
                            }
                          </div>

                          {/** Operation Hours */}
                          <div className="form-group">
                            <h3 className="box-title m-t-40">{localize.operation_hour}</h3>
                            <hr />

                            <div className="row">
                              <div className="col-12">
                                <div className="demo-checkbox">
                                  <div className="row">
                                    <div className="col-md-5">
                                      <h4>{' '+localize.closed_on_federal_holidays}</h4>
                                    </div>
                                    <div className="col-md-6">
                                      <Field name="operation_hour.closed_on_federal_holidays" type="checkbox" id="closed-federal-holidays" className="filled-in chk-col-pink" checked={values.operation_hour.closed_on_federal_holidays} />
                                      <label htmlFor="closed-federal-holidays">{localize.yes}</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {
                              ['monday','tuesday','wednesday','thursday','friday','saturday','sunday']
                                .map((v, i) => {
                                  const operationHour = values.operation_hour[v];

                                  return (
                                    <div className="row" key={i}>
                                      {/** What's day */}
                                      <div className="col-lg-3 col-md-12">
                                        <label className="m-t-20">{localize[v]}</label>
                                      </div>

                                      <div className="col-lg-6">
                                        {/* Business hour row */}
                                        {
                                          operationHour.start_at.map((_v, _i) => {
                                            return [
                                              <div className="" key={`${i}.${_i}.1`}>
                                                <small>{localize.business_hour+(_i+1)}</small>
                                                
                                                <div className="row">
                                                  <div className="col-5">
                                                    <div className="input-group m-t-10">
                                                      <TimePicker
                                                        ampm={false}
                                                        onChange={(_) => {
                                                          setFieldValue(`operation_hour.${v}.start_at[${_i}]`, moment(_ || '00:00').format('HH:mm'));
                                                        }}
                                                        value={moment(values.operation_hour[v].start_at[_i], 'HH:mm')}
                                                        renderInput={(props) => {
                                                          return (
                                                            <TextField {...props} helperText={localize.start_at} name={`operation_hour.${v}.start_at[${_i}]`} className="form-control" />
                                                          );
                                                        }}
                                                        disabled={values.operation_hour[v].opened_24h_day || values.operation_hour[v].closed_day}
                                                      />
                                                    </div>
                                                  </div>

                                                  <div className="col-5">
                                                    <div className="input-group m-t-10">
                                                      <TimePicker
                                                        ampm={false}
                                                        onChange={(_) => {
                                                          setFieldValue(`operation_hour.${v}.end_at[${_i}]`, moment(_ || '00:00').format('HH:mm'));
                                                        }}
                                                        value={moment(values.operation_hour[v].end_at[_i], 'HH:mm')}
                                                        renderInput={(props) => {
                                                          return (
                                                            <TextField {...props} helperText={localize.end_at} name={`operation_hour.${v}.end_at[${_i}]`} className="form-control" />
                                                          );
                                                        }}
                                                        disabled={values.operation_hour[v].opened_24h_day || values.operation_hour[v].closed_day}
                                                      />
                                                    </div>
                                                  </div>

                                                  <div className="col-2">
                                                    <div className="" style={{ marginTop: operationHour.start_at.length > 1 ? '-9px' : '10px', }}>
                                                      <button className="btn btn-success" data-toggle="tooltip" title="Add Business Hours" type="button" onClick={(e) => {
                                                        addViper(e, v, _i);
                                                        setFieldValue('category', values.category);
                                                      }} disabled={values.operation_hour[v].opened_24h_day || values.operation_hour[v].closed_day} >
                                                        <i aria-hidden={true} className="fa fa-plus" />
                                                      </button>
                                                      <br />
                                                      <button className="btn btn-inverse" hidden={values.operation_hour[v].start_at.length===1} type="button" onClick={(e) => {
                                                        spliceViper(e, v, _i);
                                                        setFieldValue('category', values.category);
                                                      }} disabled={values.operation_hour[v].opened_24h_day || values.operation_hour[v].closed_day} >
                                                        <i aria-hidden={true} className="fa fa-minus" />
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>,
                                            ];
                                          })
                                        }
                                        <div>
                                          { errors.operation_hour && errors.operation_hour[`${v}`] && errors.operation_hour[`${v}`].valid_time && 
                                            <p className="m-t-20 text-danger"> { 
                                              localize.formatString(localize.errors[errors.operation_hour[`${v}`].valid_time.message as string], {min_length: errors.operation_hour[`${v}`].valid_time['length'] as number , max_length: errors.operation_hour[`${v}`].valid_time['length'] as number})}
                                            </p>
                                          }
                                        </div>
                                      </div>

                                      <div className="col-lg-3">
                                        <div className="demo-radio-button m-t-10">
                                          <Field name={`operation_hour.${v}.closed_day`} type="checkbox" id={`${v}Closed`} className="filled-in chk-col-pink" checked={values.operation_hour[v].closed_day} onClick={(e)=>{
                                            if (e.target.value === 'false') {
                                              while (values.operation_hour[v].start_at.length > 1) {
                                                values.operation_hour[v].start_at.splice(1, 1);
                                              }
                                              values.operation_hour[v].start_at[0] = '00:00';
                                              while (values.operation_hour[v].end_at.length > 1) {
                                                values.operation_hour[v].end_at.splice(1, 1);
                                              }
                                              values.operation_hour[v].end_at[0] = '00:00';
                                              setFieldValue(`operation_hour.${v}.opened_24h_day`, false);
                                            }
                                          }} />
                                          <label htmlFor={`${v}Closed`}>{localize.closed_day}</label>
                                          <Field name={`operation_hour.${v}.opened_24h_day`} type="checkbox" id={`${v}Opened`} className="filled-in chk-col-pink" checked={values.operation_hour[v].opened_24h_day} onClick={(e)=>{
                                            if (e.target.value === 'false') {
                                              while (values.operation_hour[v].start_at.length > 1) {
                                                values.operation_hour[v].start_at.splice(1, 1);
                                              }
                                              values.operation_hour[v].start_at[0] = '00:00';
                                              while (values.operation_hour[v].end_at.length > 1) {
                                                values.operation_hour[v].end_at.splice(1, 1);
                                              }
                                              values.operation_hour[v].end_at[0] = '00:00';
                                              setFieldValue(`operation_hour.${v}.closed_day`, false);
                                            }
                                          }} />
                                          <label htmlFor={`${v}Opened`}>{localize.open_24h_hours}</label>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                            }

                          </div>

                          {/** Submit buttons */}
                          <div className="form-actions">
                            <div className="row">
                              <div className="col-md-6" />
                              <div className="col-md-3 m-t-10">
                                <button type="submit" className="btn btn-success btn-block btn-zooo" 
                                  disabled={ JSON.stringify(errors) !== JSON.stringify({}) }>
                                  <i className="fa fa-check" />
                                  {' '}
                                    Update
                                </button>
                              </div>
                              <div className="col-md-3 m-t-10">
                                <button type="button" className="btn btn-inverse btn-block btn-zooo" onClick={() => {
                                  resetForm();
                                }}>
                                    Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/** Amenity Info */}
                      <div className="card">
                        <div className="card-body">
                          <h3 className="box-title m-t-20">{localize.service_amenities}</h3>
                          <hr />
                          <h4>{localize.coivd19_amenity}</h4>
                          <br />
                          <div className="demo-checkbox covid-updated-services serviceAmenity">
                            {/** ... */}
                            {
                              !!storeProfile && !!storeProfile.covid19_amenity && covidAmenityKeys.map((v, i) => {
                                return [
                                  <Field id={v} name={'covid19_amenity.' + v} type="checkbox" className="filled-in chk-col-cyan" checked={values.covid19_amenity[v]} key={v} />,
                                  <label htmlFor={v} key={i}>{localize[v]}</label>,
                                ];
                              })
                            }
                          </div>
                          <br />

                          {/** General Amenity */}
                          <h4>{localize.general_amenity}</h4>
                          <br />
                          <div className="demo-checkbox serviceAmenity">
                            {/** ... */}
                            {
                              !!storeProfile && !!storeProfile.general_amenity && generalAmenityKeys.map((v, i) => {
                                return [
                                  <Field id={v} name={'general_amenity.' + v} type="checkbox" className="filled-in chk-col-pink" checked={values.general_amenity[v]} key={v} />,
                                  <label htmlFor={v} key={i}>{localize[v]}</label>,
                                ];
                              })
                            }
                          </div>
                          <br />

                          <h4>{localize.fb_amenity}</h4>
                          <br />
                          <h5>{localize.price_range}</h5>
                          <div className="demo-radio-button m-t-20">
                            <div className="row">
                              <div className="col-3">
                                <Field value="1" id="range1" name="fb_amenity.price_range" type="radio" className="radio-col-amber" checked={values.fb_amenity.price_range === 1} />
                                <label htmlFor="range1">{' '}$</label>
                              </div>

                              <div className="col-3">
                                <Field value="2" id="range2" name="fb_amenity.price_range" type="radio" className="radio-col-amber" checked={values.fb_amenity.price_range === 2} />
                                <label htmlFor="range2">{' '}$$</label>
                              </div>

                              <div className="col-3">
                                <Field value="3" id="range3" name="fb_amenity.price_range" type="radio" className="radio-col-amber" checked={values.fb_amenity.price_range === 3} />
                                <label htmlFor="range3">{' '}$$$</label>
                              </div>

                              <div className="col-3">
                                <Field value="4" id="range4" name="fb_amenity.price_range" type="radio" className="radio-col-amber" checked={values.fb_amenity.price_range === 4} />
                                <label htmlFor="range4">{' '}$$$$</label>
                              </div>
                            </div>
                            {
                              touched.fb_amenity && touched.fb_amenity['price_range'] &&
                              errors.fb_amenity && errors.fb_amenity['price_range'] &&
                              <div className="row">
                                <div className="col-12">
                                  <p className="text-danger">{errors.fb_amenity['price_range']}</p>
                                </div>
                              </div>
                            }
                          </div>

                          <h5>{localize.dress_code}</h5>
                          <div className="demo-radio-button m-t-20 m-b-30">
                            <div className="row">
                              <div className="col-4">
                                <Field value="1" id="dress1" name="fb_amenity.dress_code" type="radio" className="radio-col-amber" checked={values.fb_amenity.dress_code === 1} />
                                <label htmlFor="dress1">{' '+localize.casual}</label>
                              </div>

                              <div className="col-4">
                                <Field value="2" id="dress2" name="fb_amenity.dress_code" type="radio" className="radio-col-amber" checked={values.fb_amenity.dress_code === 2} />
                                <label htmlFor="dress2">{' '+localize.business_casual}</label>
                              </div>

                              <div className="col-4">
                                <Field value="3" id="dress3" name="fb_amenity.dress_code" type="radio" className="radio-col-amber" checked={values.fb_amenity.dress_code === 3} />
                                <label htmlFor="dress3">{' '+localize.upscale}</label>
                              </div>
                            </div>
                            {
                              touched.fb_amenity && touched.fb_amenity['price_range'] &&
                              errors.fb_amenity && errors.fb_amenity['price_range'] &&
                              <div className="row">
                                <div className="col-12">
                                  <p className="text-danger">{errors.fb_amenity['price_range']}</p>
                                </div>
                              </div>
                            }
                          </div>

                          {/** Service Amenities */}
                          <div className="demo-checkbox serviceAmenity">
                            {/** ... */}
                            {
                              !!storeProfile && !!storeProfile.fb_amenity && fbAmenityKeys.map((v, i) => {
                                if(['dress_code', 'price_range'].includes(v)) return;
                                return [
                                  <Field id={v} name={'fb_amenity.' + v} type="checkbox" className="filled-in chk-col-amber" checked={values.fb_amenity[v]} key={v} />,
                                  <label htmlFor={v} key={i}>{localize[v]}</label>,
                                ];
                              })
                            }
                          </div>

                          {/** Submit buttons field */}
                          <div className="form-actions profile-save-btn-sticky">
                            <div data-spy="affix" data-offset-top="0" className="profile-save-btn-sticky">
                              <div className="row">
                                <div className="col-md-6" />
                                <div className="col-md-3 m-t-10">
                                  <button type="submit" className="btn btn-success btn-block btn-zooo"
                                    disabled={ JSON.stringify(errors) !== JSON.stringify({}) }>
                                    <i className="fa fa-check" />
                                    {' '}
                                      Update
                                  </button>
                                </div>
                                <div className="col-md-3 m-t-10">
                                  <button type="button" className="btn btn-inverse btn-block btn-zooo" onClick={() => {
                                    resetForm();
                                  }}>
                                      Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Footer />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

const BusinessProfile = compose(
  withRouter,
  withAuthorization((authUser: any) => !!authUser),
)(BusinessProfileBase);

export default BusinessProfile;
