import axios, { AxiosRequestConfig } from 'axios';
import app from 'firebase/app';

// Config 
axios.interceptors.request.use(async (config: AxiosRequestConfig) => {
  // If signed in then set id token to header.
  const currentUser = app.auth().currentUser;
  if (currentUser) {
    config.headers = config.headers ?? {};
    config.headers.authorization = 'Bearer ' + (await currentUser.getIdToken());
    config.headers.timezone = timezone();
  }

  // Other values
  config.baseURL = process.env.REACT_APP_FIREBASE_BASE_URL;

  // Returns config
  return config;
});

function timezone(): string {
  // Get and convert current timezone into format '+7'
  let timezone: string = (new Date().getTimezoneOffset() / 60) * -1 + '';
  +timezone > 0 && (timezone = `+${timezone}`);
  return timezone;
}

export default axios;
