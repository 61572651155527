import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import Firebase, { withFirebase } from '../Firebase';

import FirstWizardForm from './FirstWizardForm';
import SecondWizardForm from './SecondWizardForm';
import ThirdWizardForm from './ThirdWizardForm';
import FourthWizard from './FourthWizard';
import { InitState, ValidationSchema } from './state_and_validation';
import { createStore } from '../../functions';
import './index.scss';
import { useDispatch } from 'react-redux';
import { ACTIVATE_LOADING, DEACTIVATE_LOADING } from '../component/overlay-loading';

const SignUp = (props: any) => {
  document.title = 'Business Manager | ZOOO Register';

  const dispatch = useDispatch();
  const errorFirebase =  {
    error : false,
    message: ''
  };
  return (
    <Formik
      onSubmit={async (values, _) => {
        dispatch({ type: ACTIVATE_LOADING });
        const firebase = props.firebase as Firebase;
        try {
          await firebase.auth.createUserWithEmailAndPassword(values.email_address, values.password);
          const data = Object.assign({}, values) as any;
          delete data.password;
          delete data.password_;
          data.region = parseInt(data.region);
          await createStore(data);
          props.history.push('/signup/confirmation');
        } catch (err) {
          console.error(err);
          errorFirebase.error = true;
          errorFirebase.message = err.message;
        } finally {
          dispatch({ type: DEACTIVATE_LOADING });
        }
      }}
      initialValues={InitState}
      validationSchema={ValidationSchema}>

      {({ errors, touched, values, setFieldValue, setFieldError, handleBlur, }) => (
        <Form>
          {
            window.location.pathname === '/signup'
              ? FirstWizardForm({ ...props, errors, touched, values })
              : window.location.pathname === '/signup/basic'
                ? SecondWizardForm({ ...props, errors, touched, values })
                : window.location.pathname === '/signup/address'
                  ? ThirdWizardForm({ ...props, errors, touched, values, setFieldValue, setFieldError, handleBlur, errorFirebase })
                  : FourthWizard({ ...props, values })
          }
        </Form>
      )}
    </Formik>
  );
};

const SignUpForm = compose(
  withRouter,
  withFirebase,
)(SignUp);
export default SignUpForm;

