import React from 'react';
import { Redirect } from 'react-router';

import Firebase, { withFirebase } from '../Firebase';

const SignOut = (props: any) => {
  const firebase = props.firebase as Firebase;
  firebase.doSignOut();

  return <Redirect to="/" />;
};

export default withFirebase(SignOut);
