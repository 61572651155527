/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Firebase from './firebase';

const FirebaseContext = React.createContext(Firebase.prototype);

export const withFirebase = (Component: any) => {
  const withFirebase = (props: any): JSX.Element => (
    <FirebaseContext.Consumer>
      {(firebase) => <Component {...props} firebase={firebase} />}
    </FirebaseContext.Consumer>
  );
  return withFirebase;
};

export default FirebaseContext;
