import React from 'react';
import LocalizedStrings from 'react-localization';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';

import successImage from '../../assets/images/gallery/rory-on-paper-plane.png';
import logoIcon from '../../assets/images/logo-icon.png';
import logo from '../../assets/images/logo-light-icon.png';

const Header = () => {
  return (
    <header className="topbar">
      <nav className="navbar top-navbar navbar-expand-md navbar-light">
        <div className="navbar-header">
          <a className="navbar-brand" href="/">
            <b>
              <img src={logoIcon} alt="homepage" className="dark-logo" />
              <img src={logo} alt="homepage" className="light-logo" />
            </b>
            <span className="product-title">Business Manager</span>
          </a>
        </div>
      </nav>
    </header>
  );
};

const FourthWizard = (props: any): JSX.Element => {
  const userLanguage = useSelector((s: any) => s.userLanguage);
  const localize = new LocalizedStrings({
    en: {
      title: 'Thank you!',
      email: 'Your email address:',
      message: 'Your application has been submitted. A team member will reach out to you in order to confirm the details of our partnership.',
      go_to:'Go To Top',
    },
    jp: {
      title: '申請を受付けました。',
      email: 'あなたのメールアドレス:',
      message: '申し込みが送信されました。運営メンバーが詳細を確認し、登録されたメールアドレスへ連絡をお送りします。',
      go_to:'トップへ',
    },
  });
  localize.setLanguage(userLanguage === 1 ? 'jp' : 'en');

  if (!props.values.officer_name) {
    return <Redirect to="/signup" />;
  }

  document.body.classList.add('fix-header');

  return (
    <div id="main-wrapper">
      <Header />

      <div className="page-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-6 text-center">
              <img src={successImage} alt="Application has been submitted." className="success-feature-img" />
              <div className="row" />
            </div>
            <div className="col-md-6">
              <div className="m-l-30">
                <h3 className="text-themecolor m-b-40 m-t-10 page-title-zooo">{localize.title}</h3>
                <h4>{localize.email}</h4>
                <h4 className="text-danger m-t-10 m-b-20">{props.values.email_address}</h4>
                <h5>{localize.message}</h5>
                <div className="m-t-40 m-b-40">
                  <a href="/">
                    <button type="button" name="button" className="btn btn-info btn-zooo btn-zooo btn-zooo-submit">
                      {localize.go_to}
                    </button>
                  </a>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <footer className="footer">
        © 2021 ZOOO Local Rewards
      </footer>
    </div>
  );
};

export default FourthWizard;
