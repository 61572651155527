import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import LocalizedStrings from 'react-localization';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import {
  ACTIVATE_LOADING,
  DEACTIVATE_LOADING,
} from '../component/overlay-loading';
import Footer from '../Footer';
import Header from '../Header';
import { withAuthorization } from '../Session';
import Sidebar from '../Sidebar';
import {
  InitValues as _InitState,
  ValidateScheme,
  TITLE_MAXLENGTH,
  BODY_MAXLENGTH,
} from './state_and_validator';
import { useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { getNotificationContent, updateNotificationContent } from '../../functions';

interface NotificationContentModel {
  long: ContentModel,
  short: ContentModel,
}

interface ContentModel {
  customise: any;
  title: string;
  body: string;
}

const NotificationContentState = {
  isLoadedData: false,
  data: {
    long: {
      customise: '0',
      title: '',
      body: '',
    },
    short: {
      customise: '0',
      title: '',
      body: '',
    },
  },
};

const NotificationContentsBase = (props: any): JSX.Element => {
  const [userLanguage, storeProfileData] = useSelector((s: any) => {
    return [s.userLanguage, s.storeProfile];
  });
  const [dataResponse, setData] = useState(NotificationContentState);
  const storeProfile = storeProfileData.document;
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      if (storeProfile?.id) {
        const result = await getNotificationContent(storeProfile?.id);
        setData({ isLoadedData: true, data: result?.data?.data });
      }
    };

    fetchData();
  }, [storeProfileData.isLoaded]);

  const localize = new LocalizedStrings({
    jp: {
      title: '通知',
      title_home: 'Home',
      title_menu: '通知',
      title_long_distance: '遠距離通知メッセージ',
      title_short_distance: '近距離通知メッセージ',
      option_text: 'オプション',
      default_text: 'デフォルト',
      customize_text: 'カスタマイズ',
      title_text: 'タイトル',
      body_text: '本文',
      up_characters_text: '{max_length}字以内で入力してください。',

      title_content_short_distance_default: '{business_name}の近くにいます！',
      body_content_short_distance_default:
        'ZOOOアプリを開いて、お店に行ってみましょう。ZOOOのお得なお店を探索してサウンドポイントを獲得しましょう。',
      title_content_long_distance_default: '{business_name}を発見！',
      body_content_long_distance_default:
        'ちょっと足を伸ばして、新しいお店を開拓してみましょう！チェックインしてポイントゲット！クーポンもいただき！',
      errors: {
        required: '必須項目。',
        max: '{max_length}文字以内である必要があります。',
      },

      _complete_title: 'アップロード完了',
      _complete_message: '変更を保存しました！',
      _undefined_error_title: 'サーバーエラー',
      _undefined_error_message:
        '申し訳ありませんが、時間を開けて再度お試しください。',
    },
    en: {
      title: 'Notification',
      title_home: 'Home',
      title_menu: 'Notification',
      title_long_distance: 'Long Distance Notification',
      title_short_distance: 'Short Distance Notification',
      option_text: 'Option',
      default_text: 'Default',
      customize_text: 'Customize',
      title_text: 'Title',
      body_text: 'Body',
      up_characters_text: 'Up to {max_length} characters including spaces',

      title_content_short_distance_default: 'You\'re near {business_name}.',
      body_content_short_distance_default:
        'Open up the ZOOO app to learn more about them. You just earned Sound Points for exploring your community!',
      title_content_long_distance_default: 'Found {business_name}!',
      body_content_long_distance_default:
        'Let\'s stretch your legs a bit and explore some new stores! "Check in" and get points! And get coupons!',
      errors: {
        required: 'Required field',
        max: 'Must be at most {max_length} characters',
      },

      _complete_title: 'Success!',
      _complete_message: 'Your changes have been saved.',
      _undefined_error_title: 'Server unable',
      _undefined_error_message: 'Sorry, you\'re getting an error. Please later.',
    },
  });
  localize.setLanguage(userLanguage === 1 ? 'jp' : 'en');

  // Waiting for load data is success
  if (dataResponse?.isLoadedData === false) {
    return (
      <div>
        <Header />
      </div>
    );
  }

  // Init value
  _InitState.long.title = localize.formatString(
    localize.title_content_long_distance_default,
    { business_name: storeProfile.business_name }
  ) as string;
  _InitState.long.body = localize.formatString(
    localize.body_content_long_distance_default,
    { business_name: storeProfile.business_name }
  ) as string;
  _InitState.short.title = localize.formatString(
    localize.title_content_short_distance_default,
    { business_name: storeProfile.business_name }
  ) as string;
  _InitState.short.body = localize.formatString(
    localize.body_content_short_distance_default,
    { business_name: storeProfile.business_name }
  ) as string;
  let disableInputLongContent = true;
  let disableInputShortContent = true;

  return (
    <div>
      <Header />
      <Sidebar />
      <div className="page-wrapper">
        <div className="container-fluid">
          {/** Page title */}
          <div className="row page-titles">
            <div className="col-12 align-self-center">
              <h3 className="text-themecolor m-b-0 m-t-0">{localize.title}</h3>

              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/" onClick={(e) => e.preventDefault}>
                    {localize.title_home}
                  </a>
                </li>
                <li className="breadcrumb-item active">
                  {localize.title_menu}
                </li>
              </ol>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  {/** Form */}
                  <Formik
                    initialValues={dataResponse.data?.long && dataResponse.data?.short ? dataResponse.data as NotificationContentModel : _InitState}
                    validationSchema={ValidateScheme}
                    onSubmit={async (values, _) => {
                      try {
                        dispatch({ type: ACTIVATE_LOADING });
                        const dataRequest: NotificationContentModel = Object.assign({}, values);
                        // Convert data
                        dataRequest.long.customise = dataRequest.long.customise == '1' ? true : false;
                        dataRequest.short.customise = dataRequest.short.customise == '1' ? true : false;

                        // Call API update
                        await updateNotificationContent(storeProfile.id, dataRequest);
                        toastr.success(
                          localize._complete_title,
                          localize._complete_message
                        );
                      } catch (err) {
                        toastr.error(
                          localize._undefined_error_title,
                          localize._undefined_error_message
                        );
                      } finally {
                        dispatch({ type: DEACTIVATE_LOADING });
                      }
                    }}
                  >
                    {({ values, touched, errors, setFieldValue, handleReset }) => (
                      (values.long.customise = Number(values?.long?.customise) + ''),
                      (values.short.customise = Number(values?.short?.customise) + ''),
                      (disableInputLongContent = values.long.customise == '0' ? true : false),
                      (disableInputShortContent = values.short.customise == '0' ? true : false),
                      <Form id="coupon-form">
                        <div className="row">
                          <div className="col-md-6">
                            <h2>{localize.title_long_distance}</h2>
                            <hr></hr>
                            <div>
                              <label>{localize.option_text}</label>
                              <ul
                                className="list-unstyled row"
                                style={{ margin: 'auto', width: '80%' }}
                              >
                                <li className="col-md-4">
                                  <div>
                                    <Field
                                      id="long_class_1"
                                      name="long.customise"
                                      type="radio"
                                      value="0"
                                      onClick={(e) => {
                                        setFieldValue(
                                          'long.title',
                                          localize.formatString(
                                            localize.title_content_long_distance_default,
                                            {
                                              business_name: storeProfile.business_name,
                                            }
                                          )
                                        );
                                        setFieldValue(
                                          'long.body',
                                          localize.formatString(
                                            localize.body_content_long_distance_default,
                                            {
                                              business_name: storeProfile.business_name,
                                            }
                                          )
                                        );
                                        disableInputLongContent = true;
                                      }}
                                    />
                                    <label htmlFor="long_class_1">
                                      {localize.default_text}
                                    </label>
                                  </div>
                                </li>

                                <li className="col-md-4">
                                  <div>
                                    <Field
                                      id="long_class_2"
                                      name="long.customise"
                                      type="radio"
                                      value="1"
                                      onClick={(e) => {
                                        setFieldValue('long.title', '');
                                        setFieldValue('long.body', '');
                                      }}
                                    />
                                    <label htmlFor="long_class_2">
                                      {localize.customize_text}
                                    </label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div>
                              <label>{localize.title_text}</label>
                              <Field
                                name="long.title"
                                type="text"
                                className="form-control"
                                maxLength={TITLE_MAXLENGTH}
                                disabled={disableInputLongContent}
                              />
                              <small className="form-control-feedback">
                                {localize.formatString(localize.up_characters_text, {
                                  max_length: TITLE_MAXLENGTH,
                                })}
                              </small>
                              <p
                                className="form-control-feedback"
                                style={{ float: 'right' }}
                              >
                                {(values.long.title.length || 0) +
                          '/' +
                          TITLE_MAXLENGTH}
                              </p>
                              {touched.long?.title && errors.long?.title && (
                                <p className="text-danger">
                                  {localize.formatString(
                                    localize.errors[
                              errors.long.title['message'] as string
                                    ],
                                    {
                                      min_length: errors.long.title['length'] as number,
                                      max_length: errors.long.title['length'] as number,
                                    }
                                  )}
                                </p>
                              )}
                            </div>
                            <div>
                              <label>{localize.body_text}</label>
                              <Field
                                name="long.body"
                                className="form-control"
                                as="textarea"
                                rows={5}
                                maxLength={BODY_MAXLENGTH}
                                disabled={disableInputLongContent}
                              />
                              <small className="form-control-feedback">
                                {localize.formatString(localize.up_characters_text, {
                                  max_length: BODY_MAXLENGTH,
                                })}
                              </small>
                              <p
                                className="form-control-feedback"
                                style={{ float: 'right' }}
                              >
                                {(values.long.body.length || 0) + '/' + BODY_MAXLENGTH}
                              </p>
                              {touched.long?.body && errors.long?.body && (
                                <p className="text-danger">
                                  {localize.formatString(
                                    localize.errors[
                              errors.long.body['message'] as string
                                    ],
                                    {
                                      min_length: errors.long.body['length'] as number,
                                      max_length: errors.long.body['length'] as number,
                                    }
                                  )}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <h2>{localize.title_short_distance}</h2>
                            <hr></hr>
                            <div>
                              <label>{localize.option_text}</label>
                              <ul
                                className="list-unstyled row"
                                style={{ margin: 'auto', width: '80%' }}
                              >
                                <li className="col-md-4">
                                  <div>
                                    <Field
                                      id="short_class_1"
                                      name="short.customise"
                                      type="radio"
                                      value="0"
                                      onClick={(e) => {
                                        setFieldValue(
                                          'short.title',
                                          localize.formatString(
                                            localize.title_content_short_distance_default,
                                            {
                                              business_name: storeProfile.business_name,
                                            }
                                          )
                                        );
                                        setFieldValue(
                                          'short.body',
                                          localize.formatString(
                                            localize.body_content_short_distance_default,
                                            {
                                              business_name: storeProfile.business_name,
                                            }
                                          )
                                        );
                                        disableInputShortContent = true;
                                      }}
                                    />
                                    <label htmlFor="short_class_1">
                                      {localize.default_text}
                                    </label>
                                  </div>
                                </li>

                                <li className="col-md-4">
                                  <div>
                                    <Field
                                      id="short_class_2"
                                      name="short.customise"
                                      type="radio"
                                      value="1"
                                      onClick={(e) => {
                                        setFieldValue('short.title', '');
                                        setFieldValue('short.body', '');
                                      }}
                                    />
                                    <label htmlFor="short_class_2">
                                      {localize.customize_text}
                                    </label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div>
                              <label>{localize.title_text}</label>
                              <Field
                                name="short.title"
                                type="text"
                                className="form-control"
                                maxLength={TITLE_MAXLENGTH}
                                disabled={disableInputShortContent}
                              />
                              <small className="form-control-feedback">
                                {localize.formatString(localize.up_characters_text, {
                                  max_length: TITLE_MAXLENGTH,
                                })}
                              </small>
                              <p
                                className="form-control-feedback"
                                style={{ float: 'right' }}
                              >
                                {(values.short.title.length || 0) +
                          '/' +
                          TITLE_MAXLENGTH}
                              </p>
                              {touched.short?.title && errors.short?.title && (
                                <p className="text-danger">
                                  {localize.formatString(
                                    localize.errors[
                              errors.short.title['message'] as string
                                    ],
                                    {
                                      min_length: errors.short.title[
                                        'length'
                                      ] as number,
                                      max_length: errors.short.title[
                                        'length'
                                      ] as number,
                                    }
                                  )}
                                </p>
                              )}
                            </div>
                            <div>
                              <label>{localize.body_text}</label>
                              <Field
                                name="short.body"
                                className="form-control"
                                as="textarea"
                                rows={5}
                                maxLength={BODY_MAXLENGTH}
                                disabled={disableInputShortContent}
                              />
                              <small className="form-control-feedback">
                                {localize.formatString(localize.up_characters_text, {
                                  max_length: BODY_MAXLENGTH,
                                })}
                              </small>
                              <p
                                className="form-control-feedback"
                                style={{ float: 'right' }}
                              >
                                {(values.short.body.length || 0) + '/' + BODY_MAXLENGTH}
                              </p>
                              {touched.short?.body && errors.short?.body && (
                                <p className="text-danger">
                                  {localize.formatString(
                                    localize.errors[
                              errors.short.body['message'] as string
                                    ],
                                    {
                                      min_length: errors.short.body['length'] as number,
                                      max_length: errors.short.body['length'] as number,
                                    }
                                  )}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>

                        {/** Submit buttons */}
                        <div className="form-actions">
                          <div className="row">
                            <div className="col-md-8" />
                            <div className="col-md-2 m-t-10">
                              <button
                                type="submit"
                                className="btn btn-success btn-block btn-zooo"
                                disabled={JSON.stringify(errors) !== JSON.stringify({})}
                              >
                                <i className="fa fa-check" /> Update
                              </button>
                            </div>
                            <div className="col-md-2 m-t-10">
                              <button
                                type="button"
                                className="btn btn-inverse btn-block btn-zooo"
                                onClick={handleReset}
                              >
                                {' '}
                        Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                        {/** End Submit buttons */}
                      </Form>
                    )}
                  </Formik>
                  {/** End Form */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

const NotificationContents = compose(
  withRouter,
  withAuthorization((authUser: any) => !!authUser)
)(NotificationContentsBase);
export default NotificationContents;
