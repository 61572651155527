import * as Yup from 'yup';

const TITLE_MAXLENGTH = 50;
const BODY_MAXLENGTH = 200;

const InitValues = {
  long: {
    customise: '0',
    title: '',
    body: '',
  },
  short: {
    customise: '0',
    title: '',
    body: '',
  },
};

const ValidateScheme = Yup.object().shape({
  long: Yup.object().shape({
    title: Yup.string()
      .optional()
      .max(TITLE_MAXLENGTH, { length: TITLE_MAXLENGTH, message: 'max' }),
    body: Yup.string()
      .optional()
      .max(BODY_MAXLENGTH, { length: BODY_MAXLENGTH, message: 'max' }),
  }),
  short: Yup.object().shape({
    title: Yup.string()
      .optional()
      .max(TITLE_MAXLENGTH, { length: TITLE_MAXLENGTH, message: 'max' }),
    body: Yup.string()
      .optional()
      .max(BODY_MAXLENGTH, { length: BODY_MAXLENGTH, message: 'max' }),
  }),
});

export { InitValues, ValidateScheme, TITLE_MAXLENGTH, BODY_MAXLENGTH };
