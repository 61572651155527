import React, { useState } from 'react';
import { Field } from 'formik';
import LocalizedStrings from 'react-localization';
import { Link } from 'react-router-dom';

import backgroundImage from '../../assets/images/background/local-shop-interior2.jpg';
import logoText from '../../assets/images/logo-text.png';
import { useDispatch, useSelector } from 'react-redux';
import { ACTIVATE_LOADING, DEACTIVATE_LOADING } from '../component/overlay-loading';

const FirstWizardForm = (props: any): JSX.Element => {
  const [userLanguage] = useSelector((s: any) => [ s.userLanguage ]);
  // const [isUserError, setUserError] = useState(false);
  const dispatch = useDispatch();
  const localize = new LocalizedStrings({
    en: {
      welcome: 'Let\'s team up and ',
      welcome_: ' bring your business to new heights',
      title: 'Contact Information',
      title_contact: 'Contact',
      title_business: 'Business',
      title_address: 'Address',
      placeholder_your_name: 'Your Name',
      placeholder_email_address: 'Email Address',
      placeholder_phone_number: 'Contact Phone Number',
      placeholder_password: 'Password',
      placeholder_confirm_password: 'Confirm Password',
      next: 'Next',
      already: 'Already have an account? ',
      signin: 'Sign In',
      what: 'What\'s',
      question: '?',
      errors: {
        min: 'Please enter at least {min_length} characters',
        max: 'Up to {max_length} characters including spaces',
        invalid_email: 'Invalid email.',
        required: 'Required field',
        invalid_phone_number: 'Invalid phone number',
        invalid_password: 'Invalid password',
        mismatch_password: 'Password not equal',
      }
    },
    jp: {
      welcome: 'ビジネスを新たな高みへと導くため',
      welcome_: 'チームをセットアップしましょう',
      title: '連絡先',
      title_contact: '連絡先',
      title_business: '基本情報',
      title_address: '所在地',
      placeholder_your_name: '尊名',
      placeholder_email_address: 'メールアドレス',
      placeholder_phone_number: '電話番号',
      placeholder_password: 'アカウントのパスワード',
      placeholder_confirm_password: 'アカウントのパスワード(確認用)',
      next: '次へ',
      already: 'すでにアカウントをお持ちですか？',
      signin: 'ログインはこちら',
      what: 'サービスについては',
      question: '',
      errors: {
        min: '{min_length}文字以上で入力してください。',
        max: '{max_length}字以内で入力してください。',
        invalid_email: '無効なメール。',
        required: '必須項目。',
        invalid_phone_number: 'この電話番号は無効です。',
        invalid_password: '無効なパスワード。',
        mismatch_password: 'パスワードが一致しません。',
      }
    },
  });
  localize.setLanguage(userLanguage === 1 ? 'jp' : 'en');

  const { errors, touched } = props;
  const errors_ = {
    officer_name: errors.officer_name,
    email_address: errors.email_address,
    phone_number: errors.phone_number,
    password: errors.password,
    password_: errors.password_,
  };
  const touched_ = {
    officer_name: touched.officer_name,
    email_address: touched.email_address,
    phone_number: touched.phone_number,
    password: touched.password,
    password_: touched.password_,
  };

  return (
    <section id="wrapper">
      <img className="img-background" src={backgroundImage}/>
      <div className="login-register" >
        <div className="register-box card" style={{borderRadius:'4px'}}>
          <div className="card-body row">
            {/** Form title */}
            <div className="col-md-6">
              <a href="/" className="text-center db" onClick={event => event.preventDefault()}>
                <br />
                <img src={logoText} alt="Home" className="register-login-img" />
              </a>
              <h2 className="text-center register-login-title">ZOOO Local Rewards</h2>
              <p className="text-center business-tagline">{localize.welcome}<br />{localize.welcome_}</p>
              <p className="text-center">
                {localize.what}<a href="https://zooorewards.com/marketing-solutions/" className="m-l-5" target="_blank" rel="noreferrer"><b>ZOOO Local Rewards</b></a>{localize.question}
              </p>
              <div className="text-center">
                <span style={{display:'inline-block'}}>{localize.already}</span>{' '}<Link to="/signin" className="m-l-5"><b style={{display:'inline-block'}}>{localize.signin}</b></Link>
              </div>
            </div>

            {/** Form */}
            <div className="col-md-6">
              {/** Wizard Step */}
              <div className="row m-t-20 m-b-20">
                <div className="col-4">
                  <p className="text-danger font-weight-bold">1. {localize.title_contact}</p>
                </div>

                <div className="col-4">
                  <p>2. {localize.title_business}</p>
                </div>

                <div className="col-4">
                  <p>3. {localize.title_address}</p>
                </div>
              </div>

              {/** Input form name */}
              <hr className="divider-dots" />
              <h3 className="m-t-20 m-b-20">{localize.title}</h3>

              {/** Fields */}
              <div className="form-horizontal form-material">
                <div className="form-group">
                  <Field type="text" name="officer_name" placeholder={localize.placeholder_your_name} className="form-control" />
                  {
                    errors.officer_name && touched.officer_name &&
                    <p className="text-danger">{localize.formatString(localize.errors[errors.officer_name.message], {min_length: errors.officer_name.length , max_length: errors.officer_name.length})}</p>
                  }
                </div>

                <div className="form-group">
                  <Field type="text" name="email_address" placeholder={localize.placeholder_email_address} className="form-control" />
                  {
                    errors.email_address && touched.email_address &&
                    <p className="text-danger">{localize.formatString(localize.errors[errors.email_address.message], {min_length: errors.email_address.length, max_length: errors.email_address.length})}</p>
                  }
                </div>

                {/* <div className="form-group">
                  <Field type="text" name="phone_number" placeholder={localize.placeholder_phone_number} className="form-control" />
                  {
                    errors.phone_number && touched.phone_number &&
                    <p className="text-danger">{localize.formatString(localize.errors[errors.phone_number.message], {min_length: errors.phone_number.length, max_length: errors.phone_number.length})}</p>
                  }
                </div> */}

                <div className="form-group">
                  <Field type="password" name="password" placeholder={localize.placeholder_password} className="form-control" />
                  {
                    errors.password && touched.password &&
                    <p className="text-danger">{localize.formatString(localize.errors[errors.password.message], {min_length: errors.password.length, max_length: errors.password.length})}</p>
                  }
                </div>

                <div className="form-group">
                  <Field type="password" name="password_" placeholder={localize.placeholder_confirm_password} className="form-control" />
                  {
                    errors.password_ && touched.password_ &&
                    <p className="text-danger">{localize.formatString(localize.errors[errors.password_.message], {min_length: errors.password_.length, max_length: errors.password_.length})}</p>
                  }
                </div>

                {/** Next button */}
                <div className="form-group text-center">
                  <button
                    disabled={
                      JSON.stringify(touched_) === JSON.stringify({}) || JSON.stringify(errors_) !== JSON.stringify({})
                    }
                    onClick={async () => {
                      // setUserError(false);
                      dispatch({ type: ACTIVATE_LOADING });
                      try {
                        //
                        props.history.push('/signup/basic');
                      } catch (err) {
                        try {
                          //
                        } catch (err) {
                          // setUserError(true);
                        }
                      } finally {
                        dispatch({ type: DEACTIVATE_LOADING });
                      }
                    }}
                    className="btn btn-info btn-lg btn-block text-uppercase waves-effect waves-light btn-zooo"
                  >
                    {localize.next}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FirstWizardForm;
