import React from 'react';
import ReactLoading from 'react-loading';
import { connect } from 'react-redux';

import './overlay-loading.scss';

export const ACTIVATE_LOADING = 'activate_loading';
export const startLoading =  (): { type: string } => ({ type: ACTIVATE_LOADING });
export const DEACTIVATE_LOADING = 'deactivate_loading';
export const endLoading = (): { type: string } => ({ type: DEACTIVATE_LOADING });
export const toggleViewLoadingReducer = (state = { value: false }, action: { type: string }): { value: boolean } => {
  switch (action.type) {
  case ACTIVATE_LOADING:
    return { value: true };
  case DEACTIVATE_LOADING:
    return { value: false };
  default:
    return state;
  }
};

const OverlayLoading = (props: any): JSX.Element => {
  if (props.value) {
    $('body').addClass('_not_scroll');
  } else {
    $('body').removeClass('_not_scroll');
  }

  return (
    <div className={props.value ? '_activate-overlay-application-loading' : '_deactivate-overlay-application-loading'}>
      <div className="_loading-animation">
        <ReactLoading type="spokes" color="#FFF" />
      </div>
    </div>
  );
};

const mapStateToProps = (state: {isActiveLoading: {value: boolean}}) => ({value: state.isActiveLoading.value});
const mapDispatchToProps = (dispatch: any) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OverlayLoading);
