import React from 'react';
import { Field } from 'formik';
import LocalizedStrings from 'react-localization';
import { Link, Redirect } from 'react-router-dom';

import backgroundImage from '../../assets/images/background/local-shop-interior2.jpg';
import logoText from '../../assets/images/logo-text.png';
import { useSelector } from 'react-redux';

const SecondWizardForm = (props: any): JSX.Element => {
  const userLanguage = useSelector((s: any) => s.userLanguage);
  const localize = new LocalizedStrings({
    en: {
      welcome: 'Let\'s team up and ',
      welcome_: ' bring your business to new heights',
      previous: 'Previous step',
      title: 'Business Information',
      title_contact: 'Contact',
      title_business: 'Business',
      title_address: 'Address',
      placeholder_business_name: 'Business Name',
      placeholder_branch_name: 'Branch Name (Optional)',
      placeholder_business_phone_number: 'Business Phone Number',
      tooltip_business_phone_number: 'Please enter a business phone number. If you do not have a business phone at the physical location, enter your mobile phone number.',
      next: 'Next',
      errors: {
        min: 'Please enter at least {min_length} characters',
        max: 'Up to {max_length} characters including spaces',
        required: 'Required field',
      }
    },
    jp: {
      welcome: 'ビジネスを新たな高みへと導くため',
      welcome_: 'チームをセットアップしましょう',
      previous: '前の画面に戻る',
      title: '基本情報',
      title_contact: '連絡先',
      title_business: '基本情報',
      title_address: '所在地',
      placeholder_business_name: '店名',
      placeholder_branch_name: '支店名',
      placeholder_business_phone_number: '電話番号',
      tooltip_business_phone_number: 'お店の電話番号を入力してください。お店に電話を設置していない場合は、責任者の携帯電話番号などを入力してください。',
      next: '次へ',
      errors: {
        min: '{min_length}文字以上で入力してください。',
        max: '{max_length}字以内で入力してください。',
        required: '必須項目。',
      }
    },
  });
  localize.setLanguage(userLanguage === 1 ? 'jp': 'en');

  if (!props.values.officer_name) {
    return <Redirect to="/signup" />;
  }

  const { errors, touched } = props;
  const errors_ = {
    business_name: errors.business_name,
    branch_name: errors.branch_name,
    tel_number: errors.tel_number,
  };
  const touched_ = {
    business_name: touched.business_name,
    branch_name: touched.branch_name,
    tel_number: touched.tel_number,
  };

  return (
    <section id="wrapper">
      <img className="img-background" src={backgroundImage}/>
      <div className="login-register" >
        <div className="register-box card" style={{borderRadius:'4px'}}>
          <div className="card-body row">
            {/** Form title */}
            <div className="col-md-6">
              <a href="/" className="text-center db" onClick={event => event.preventDefault()}>
                <br />
                <img src={logoText} alt="Home" className="register-login-img" />
              </a>
              <h2 className="text-center register-login-title">ZOOO Local Rewards</h2>
              <p className="text-center business-tagline">
                {localize.welcome}<br />{localize.welcome_}
              </p>
              <div className="text-center">
                <i className="mdi mdi-arrow-left text-danger" />
                {' '}
                <Link to="/signup">{localize.previous}</Link>
              </div>
            </div>

            {/** Form */}
            <div className="col-md-6">
              {/** Wizard Step */}
              <div className="row m-t-20 m-b-20">
                <div className="col-4">
                  <p>1. {localize.title_contact}</p>
                </div>

                <div className="col-4">
                  <p className="text-danger font-weight-bold">2. {localize.title_business}</p>
                </div>

                <div className="col-4">
                  <p>3. {localize.title_address}</p>
                </div>
              </div>

              {/** Input Form Name */}
              <hr className="divider-dots" />
              <h3 className="m-t-20 m-b-10">{localize.title}</h3>

              {/** Fields */}
              <div className="form-horizontal form-material">
                <div className="form-group">
                  <Field type="text" name="business_name" placeholder={localize.placeholder_business_name} className="form-control" />
                  {
                    touched.business_name && errors.business_name &&
                    <p className="text-danger">{localize.formatString(localize.errors[errors.business_name.message], {min_length: errors.business_name.length , max_length: errors.business_name.length})}</p>
                  }
                </div>
                <div className="form-group">
                  <Field type="text" name="branch_name" placeholder={localize.placeholder_branch_name} className="form-control" />
                  {
                    touched.branch_name && errors.branch_name &&
                    <p className="text-danger">{localize.formatString(localize.errors[errors.branch_name.message], {min_length: errors.branch_name.length , max_length: errors.branch_name.length})}</p>
                  }
                </div>
                <div className="form-group">
                  <div className="text-right">
                    <i className="fas fa-question-circle in-form-tooltip" data-toggle="tooltip"
                      title={localize.tooltip_business_phone_number} />
                  </div>
                  <Field type="tel" name="tel_number" placeholder={localize.placeholder_business_phone_number} className="form-control" />
                  {
                    touched.tel_number && errors.tel_number &&
                    <p className="text-danger">{localize.formatString(localize.errors[errors.tel_number.message], {min_length: errors.tel_number.length , max_length: errors.tel_number.length})}</p>
                  }
                </div>

                {/** Next page */}
                <div className="form-group text-center">
                  <button
                    disabled={
                      JSON.stringify(touched_) === JSON.stringify({}) || JSON.stringify(errors_) !== JSON.stringify({})
                    }
                    onClick={() => props.history.push('/signup/address')}
                    className="btn btn-info btn-lg btn-block text-uppercase waves-effect waves-light btn-zooo"
                  >
                    {localize.next}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SecondWizardForm;
