import React from 'react';
import LocalizedStrings from 'react-localization';
import { useDispatch, useSelector } from 'react-redux';

import user from '../../assets/images/users/generic-user2.jpg';
import logo from '../../assets/images/logo-icon.png';
import lightLogo from '../../assets/images/logo-light-icon.png';

import './index.scss';
import { useFirebase, useFirestore } from 'react-redux-firebase';

const HeaderBase = () => {
  const [userProfile, storeProfile, userLanguage] = useSelector((s: any) => {
    return [{...s.firebase.auth, ...s.firebase.profile}, s.storeProfile, s.userLanguage];
  });
  const firebase = useFirebase();
  const firestore = useFirestore();
  const dispatch = useDispatch();

  if (!storeProfile.isLoaded) {
    if (firebase.auth().currentUser && userProfile?.isLoaded) {
      if (!userProfile?.isEmpty) {
        const manageStore = userProfile.manage_store;
        if (Array.isArray(manageStore) && manageStore.length > 0) {
          firestore
            .collection('stores')
            .doc(manageStore[0].store_id)
            .get()
            .then((snapshot) => {
              const document = snapshot.data();
              if (snapshot.exists && document) {
                document.id = snapshot.id;
                dispatch({
                  type: 'onLoadStoreProfile',
                  data: document,
                });
              }
            })
            .catch(console.error);
        }
      }
    }
  }

  const localize = new LocalizedStrings({
    en: {
      manage_team: ' Manage Team',
      audit_log: ' Audit Log',
      account_billing: ' Account & Billing',
      logout: ' Logout',
      loading: 'Loading data',
      view_profile: 'View Profile',
    },
    jp: {
      manage_team: ' スタッフを管理',
      audit_log: ' 操作履歴',
      account_billing: ' 請求関連',
      logout: ' ログアウト',
      loading: 'ローディング...',
      view_profile: 'プロフィールを見る',
    },
  });
  localize.setLanguage(userLanguage === 1 ? 'jp' : 'en');

  return (
    <div>
      <header className="topbar" style={{position:'fixed',width:'100%',top:'0'}}>
        <nav className="navbar top-navbar navbar-expand-md navbar-light">
          <div className="navbar-header">
            <a className="navbar-brand" href="/">
              <b>
                <img src={logo} alt="homepage" className="dark-logo" />
                <img src={lightLogo} alt="homepage" className="light-logo" />
              </b>
              <span className="product-title">Business Manager</span>
            </a>
          </div>
          <div className="navbar-collapse">
            <ul className="navbar-nav mr-auto mt-md-0">
              <li className="nav-item">
                <a className="nav-link nav-toggler hidden-md-up text-muted waves-effect waves-dark" onClick={(event) => event.preventDefault()} href="/">
                  <i className="mdi mdi-menu"></i>
                </a>
              </li>
            </ul>
            <ul className="navbar-nav my-lg-0">
              <li>
                <div className="card-body">
                  <i className="fas fa-qrcode text-white"></i>
                </div>
              </li>
              <li className="nav-item dropdown">
                <a
                  href="/"
                  onClick={(event) => event.preventDefault()}
                  className="nav-link dropdown-toggle text-muted waves-effect waves-dark"
                  data-toggle="dropdown"
                >
                  <span className="hide-menu store-name-nav-bar">{storeProfile?.document?.business_name}</span>
                </a>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle text-muted waves-effect waves-dark"
                  href="/"
                  onClick={(event) => event.preventDefault()}
                  data-toggle="dropdown"
                >
                  <img src={user} alt="user" className="profile-pic" />
                </a>
                <div className="dropdown-menu dropdown-menu-right scale-up">
                  <ul className="dropdown-user">
                    <li>
                      <div className="dw-user-box">
                        <div className="u-img"><img src={user} alt="user" /></div>
                        <div className="u-text">
                          <h4 style={{paddingTop: '16px'}}>
                            {
                              userProfile.officer_name &&
                              userProfile.officer_name.length > 15
                                ? userProfile.officer_name.substring(0, 14) + '...'
                                : userProfile.officer_name
                            }
                          </h4>
                          <p className="text-muted">
                            {
                              userProfile.email &&
                              userProfile.email.length > 14
                                ? userProfile.email.substring(0, 15) + '...'
                                : userProfile.email
                            }
                          </p>
                          {/* <a href="profile.html" className="btn btn-rounded btn-danger btn-sm">{localize.view_profile}</a> */}
                        </div>
                      </div>
                    </li>
                    <li role="separator" className="divider"></li>
                    {/* <li><a href="manage-team.html"><i className="ti-user" />{localize.manage_team}</a></li>
                    <li><a href="audit-log.html"><i className="ti-email" />{localize.audit_log}</a></li>
                    <li role="separator" className="divider"></li>
                    <li><a href="account-billing.html"><i className="ti-settings" />{localize.account_billing}</a></li>
                    <li role="separator" className="divider"></li> */}
                    <li><a href="/logout"><i className="fa fa-power-off" />{localize.logout}</a></li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </header>
      <div style={{
        display: 'block',
        position: 'relative',
        width: '100%',
        height: '70px',
        verticalAlign: 'baseline',
        float: 'none'
      }} />
    </div>
  );
};

export default React.memo(HeaderBase);
