import axios from './axios';
import Axios, { AxiosResponse } from 'axios';
import moment from 'moment';

const createStore = (data: any): Promise<AxiosResponse<any>> => axios.post('/store/create', data);
const updateStore = (data: any, storeId: string): Promise<AxiosResponse<any>> => axios.put(`/store/update/${storeId}`, data);
const uploadMediaGallery = (data: any, storeId: string): Promise<AxiosResponse<any>> => axios.post(`/store/media-gallery/${storeId}`, data);
const reindexMediaGallery = (data: any, storeId: string): Promise<AxiosResponse<any>> => axios.put(`/store/media-gallery/${storeId}`, data);
const deleteMediaGallery = (mediaId: string, storeId: string): Promise<AxiosResponse<any>> => axios.delete(`/store/media-gallery/${storeId}/${mediaId}`);
const getDashboardData = (storeId: string, startAt: moment.Moment, endAt: moment.Moment) => axios.get(`/store/dashboard/${storeId}/${startAt.format('YYYY-MM-DD')}/${endAt.format('YYYY-MM-DD')}`);
const createNewCoupon = (storeId: string, data: any) => axios.post(`/store/coupons/${storeId}`, data);
const getCustomers = (storeId) : Promise<AxiosResponse<any>> => axios.get(`/store/users/${storeId}`);
const getCustomerDetail = (storeId, customerId, startAt?, endAt?) : Promise<AxiosResponse<any>> => axios.get(`/store/users/${storeId}/${customerId}` + ((startAt && endAt) ? `?start_at=${startAt}&end_at=${endAt}` : ''));
const getCoupons = (storeId) : Promise<AxiosResponse<any>> => axios.get(`/store/coupons/${storeId}`);
const getCouponDetail = (storeId, couponId) : Promise<AxiosResponse<any>> => axios.get(`/store/coupons/${storeId}/${couponId}`);
const stopCoupon = (storeId, couponId) : Promise<AxiosResponse<any>> => axios.put(`/store/coupons/${storeId}/${couponId}/toggle-status`);
const copyCoupon = (storeId, couponId) : Promise<AxiosResponse<any>> => axios.post(`/store/coupons/${storeId}/${couponId}/copy`);
const updateCoupon = (storeId, couponId, data) : Promise<AxiosResponse<any>> => axios.put(`/store/coupons/${storeId}/${couponId}`, data);
const deleteCoupon = (storeId, couponId) : Promise<AxiosResponse<any>> => axios.delete(`/store/coupons/${storeId}/${couponId}`);
const getCheckInHistory = (storeId) : Promise<AxiosResponse<any>> => axios.get(`/store/check-in-histories/${storeId}`);
const getSurveyHistory = (storeId) : Promise<AxiosResponse<any>> => axios.get(`/store/surveys/${storeId}`);
const getSurveyResult = (storeId, surveyId) : Promise<AxiosResponse<any>> => axios.get(`/store/surveys/${storeId}/${surveyId}`);
const getCouponResult = (storeId, couponId) : Promise<AxiosResponse<any>> => axios.get(`/store/coupons/${storeId}/${couponId}/result`);
const updateNotificationContent = (storeId, data) : Promise<AxiosResponse<any>> => axios.post(`/store/notification-content/${storeId}`, data);
const getNotificationContent = (storeId) : Promise<AxiosResponse<any>> => axios.get(`/store/notification-content/${storeId}`);
const getStateAndCityFromZipCode = async (
  zipCode: string,
  language: 'ja' | 'en',
): Promise<{ city: string, state: string, region: number }> => {
  const client1 = Axios.create();
  const ret = await client1.get(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${zipCode}&language=${language}&key=${process.env.REACT_APP_LOCATION_API_KEY}`
  );
  if (ret.data.status !== 'OK') {
    throw new Error('Invalid zip-code');
  }
  return {
    city: ret.data.results[0].address_components[language==='ja'?2:1].long_name,
    state: ret.data.results[0].address_components[language==='ja'?3:3].long_name,
    region: ret.data.results[0].address_components[5]?.short_name === 'US'
    || ret.data.results[0].address_components[4]?.short_name === 'US'
    || ret.data.results[0].address_components[3]?.short_name === 'US'
    || ret.data.results[0].address_components[2]?.short_name === 'US' ? 2 : 1,
  };
};

export { createStore, getStateAndCityFromZipCode, updateStore, uploadMediaGallery, deleteMediaGallery, getDashboardData, createNewCoupon, getCustomers, getCoupons, getCustomerDetail, getCheckInHistory, getSurveyHistory,
  getSurveyResult, getCouponDetail, stopCoupon, deleteCoupon, copyCoupon, updateCoupon, getCouponResult, reindexMediaGallery, updateNotificationContent, getNotificationContent };
