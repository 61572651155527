import React from 'react';
import './index.scss';

export default function Footer(): JSX.Element {
  return (
    <footer className="footer" style={{left:0}}>
      © 2021 ZOOO Local Rewards
    </footer>
  );
}
