import React, { useEffect } from 'react';
import $ from 'jquery';

import './index.scss';
import LocalizedStrings from 'react-localization';
import { useSelector } from 'react-redux';

export default function Sidebar(): JSX.Element {
  const [ userLanguage] = useSelector((s: any) => {
    return [ s.userLanguage];
  });

  useEffect(() => {
    const activeLink = async () => {
      const path = new URL(window.location.href).pathname;
      $('.active_target_links').removeClass('active');
      switch (path) {
      case '':
      case '/':
        $('#dashboard_li').addClass('active');
        break;
      case '/business-profile':
      case '/business-profile/':
      case '/media-gallery':
      case '/media-gallery/':
      case '/notification':
        $('#business_info_li').addClass('active');
        break;
      case '/coupon':
      case '/coupon/':
      case '/coupon-list':
      case '/coupon-list/':
        $('#coupons_li').addClass('active');
        break;
      case '/customers':
      case '/customers/':
      case '/survey-history':
      case '/survey-history/':
      case '/checkin-history':
      case '/cehckin-history/':
        $('#shoppers_li').addClass('active');
        break;
      default:
        break;
      }
    };

    activeLink();
  }, [true]);

  const localize = new LocalizedStrings({
    en: {
      dashboard: 'Dashboard',
      business_info: 'Business Info',
      business_profile: 'Business Profile',
      media_gallery: 'Media Gallery',
      notification: 'Notification',
      coupon: 'Coupons',
      all_coupon: 'All Coupons',
      create_a_coupon: 'Create A Coupon',
      shoper: 'Shoppers',
      acquired_shoper: 'Acquired Shoppers',
      gift_coupon_history: 'Gifted Coupon History',
      survey_result: 'Survey Results',
      checkin_history: 'Check-in History',
      help: 'Help',
      faq: 'FAQ',
      contact_us: 'Contact Us',
    },
    jp: {
      dashboard: 'ダッシュボード',
      business_info: '店舗情報',
      business_profile: '店舗情報',
      media_gallery: 'メディアギャラリー',
      notification: '通知',
      coupon: 'クーポン',
      all_coupon: 'クーポンリスト',
      create_a_coupon: 'クーポン作成​',
      shoper: '顧客',
      acquired_shoper: '顧客リスト',
      gift_coupon_history: 'クーポンプレゼント履歴',
      survey_result: 'アンケート結果',
      checkin_history: 'チェックイン履歴',
      help: 'ヘルプ',
      faq: 'FAQ',
      contact_us: '問い合わせ',
    },
  });
  localize.setLanguage(userLanguage === 1 ? 'jp' : 'en');

  return (
    <aside className="left-sidebar">
      <div className="scroll-sidebar">
        <nav className="sidebar-nav">
          <ul id="sidebarnav" className="mr-auto mt-md-0">
            <li className="activate_target_links" id="dashboard_li">
              <a className="" href="/" aria-expanded="false">
                <i className="mdi mdi-gauge"></i>
                <span className="hide-menu">{localize.dashboard}</span>
              </a>
            </li>
            <li className="activate_target_links" id="business_info_li">
              <a className="" href="/" onClick={(event) => event.preventDefault()} aria-expanded="false">
                <i className="mdi mdi-store"></i>
                <span className="hide-menu">{localize.business_info}</span>
              </a>
              <ul className="collapse">
                <li><a href="/business-profile">{localize.business_profile}</a></li>
                <li><a href="/media-gallery">{localize.media_gallery}</a></li>
                <li><a href="/notification">{localize.notification}</a></li>
              </ul>
            </li>
            <li className="activate_target_links" id="coupons_li">
              <a className="" href="/" onClick={(event) => event.preventDefault()} aria-expanded="false">
                <i className="mdi mdi-bullseye"></i>
                <span className="hide-menu">{localize.coupon}</span>
              </a>
              <ul className="collapse">
                <li><a href="/coupon-list">{localize.all_coupon}</a></li>
                <li><a href="/coupon">{localize.create_a_coupon}</a></li>
              </ul>
            </li>
            {/* <li className="activate_target_links" id="shoppers_li">
              <a className="" href="/" onClick={(event) => event.preventDefault()} aria-expanded="false">
                <i className="mdi mdi-account-alert"></i>
                <span className="hide-menu">{localize.shoper}</span>
              </a>
              <ul className="collapse">
                <li><a href="/customers">{localize.acquired_shoper}</a></li>
                <li><a href="/checkin-history">{localize.checkin_history}</a></li>
              </ul>
            </li>
            <li className="activate_target_links" id="shoppers_li">
              <a className="" href="/" onClick={(event) => event.preventDefault()} aria-expanded="false">
                <i className="mdi mdi-account-alert"></i>
                <span className="hide-menu">{localize.shoper}</span>
              </a>
              <ul className="collapse">
                <li><a href="/survey-history">{localize.survey_result}</a></li>
              </ul>
            </li> */}
            <li className="activate_target_links" id="help_li">
              <a className="" href="/" onClick={(event) => event.preventDefault()} aria-expanded="false">
                <i className="mdi mdi-comment-question-outline"></i>
                <span className="hide-menu">{localize.help}</span>
              </a>
              <ul className="collapse">
                <li><a href="https://zoooinc.com/business-owner-faq/" rel="noreferrer" target="_blank">{localize.faq}</a></li>
                <li><a href="https://zoooinc.com/stores-contact-us/" rel="noreferrer" target="_blank">{localize.contact_us}</a></li>
              </ul>
            </li>
            <div className="pull-right text-right">
              <li className="nav-item create-coupon-li">
                <a href="/coupon">
                  <button type="button" className="btn btn-info waves-effect waves-dark text-white create-a-coupon-button">
                    {localize.create_a_coupon}
                  </button>
                </a>
              </li>
            </div>
          </ul>
        </nav>
      </div>
    </aside>
  );
}
