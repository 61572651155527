import React from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import SignUpForm from '../SignUp';
import SignInPage from '../SignIn';
import Dashboard from '../Dashboard';
import BusinessProfile from '../BusinessProfile';
import SignOut from '../SignOut';
import MediaGallery from '../MediaGallery';
import CouponList from '../CouponList';
import Coupon from '../Coupon';
import CouponDetail from '../CouponDetail';
import CouponResult from '../CouponResult';
import NotFoundPage from '../NotFound';
import NotificationContents from '../NotificationContents';

const App = (): JSX.Element => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Dashboard} />
        <Route exact path="/signup" component={SignUpForm} />
        <Route exact path="/signup/basic" component={SignUpForm} />
        <Route exact path="/signup/address" component={SignUpForm} />
        <Route exact path="/signup/confirmation" component={SignUpForm} />
        <Route exact path="/signin" component={SignInPage} />
        <Route exact path="/logout" component={SignOut} />
        <Route exact path="/business-profile" component={BusinessProfile} />
        <Route exact path="/media-gallery" component={MediaGallery} />
        <Route exact path="/coupon-list" component={CouponList} />
        <Route exact path="/coupon" component={Coupon} />
        <Route exact path="/coupons/:couponId/edit" component={Coupon} />
        <Route exact path="/coupons/:couponId" component={CouponDetail} />
        <Route exact path="/coupons/:couponId/result" component={CouponResult} />
        <Route exact path="/notification" component={NotificationContents} />
        {/* Hide menu customer */}
        {/* <Route exact path="/customers" component={Customers} />
        <Route exact path="/customers/:customerId" component={CustomerDettail} /> */}
        {/*End hide menu customer */}
        {/* <Route exact path="/coupon-history" component={CouponHistory} /> */}
        {/* Hide menu customer */}
        {/*
        <Route exact path="/survey-history" component={SurveyHistory} />
        <Route exact path="/survey-history/:surveyId" component={SurveyResult} />
        <Route exact path="/checkin-history" component={CheckinHistory} /> */}
        {/*End hide menu customer */}
        <Route exact path="/404" component={NotFoundPage} />
        <Redirect to="/404" />
      </Switch>
    </Router>
  );
};

export default App;
