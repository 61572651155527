import React from 'react';
import Rory from '../../assets/images/gallery/Rory-in-trouble.png';

export default function NotFoundPage(): JSX.Element {
  return (
    <div className="error-box">
      <div className="error-body text-center">
        <img
          src={Rory}
          alt="Rory's troubled face."
          className="error-rory"
        />
        <h1 className="text-info">404</h1>
        <h3 className="text-uppercase m-b-30">Page Not Found!</h3>
        <a
          href="/"
          className="btn btn-danger btn-rounded waves-effect waves-light m-b-40"
        >
          Back to home
        </a>
      </div>
      <footer className="footer text-center">© 2021 ZOOO Local Rewards</footer>
    </div>
  );
}
