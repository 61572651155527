import React, { useEffect, useState } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import Sidebar from '../Sidebar';
import { compose } from 'recompose';
import { withAuthorization } from '../Session';
import { useSelector, useDispatch } from 'react-redux';
import { copyCoupon, deleteCoupon, getCoupons, stopCoupon } from '../../functions';
import LocalizedStrings from 'react-localization';
import moment from 'moment';
import { ACTIVATE_LOADING, DEACTIVATE_LOADING } from '../component/overlay-loading';
import { toastr } from 'react-redux-toastr';
import { Redirect } from 'react-router';

export enum CouponStatusCode {
  draft = 1,
  scheduled = 2,
  active = 3,
  ended = 4,
}

export enum DiscountType {
  discountPercentage = 1,
  discountAmount = 2,
  freeItem = 3,
}

export enum ActivationType {
  afterCheckedIn = 1,
  canUseSpecificTimes = 2,
  onSpecificDays = 3,
}

export enum Region {
  jp = 1,
  us = 2,
}

export interface Coupon {
  id?: string;
  discount_value?: number;
  status_code?: CouponStatusCode;
  headline?: string;
  discount_description?: string;
  sub_headline?: string;
  created_at?: Date; // Date
  updated_at?: Date; // Date
  is_stopped?: boolean;
  activation_type?: ActivationType;
  disclaimer?: string;
  start_at?: Date; // Date
  end_at?: Date; // Date
  is_disabled?: string;
  discount_type?: DiscountType;
  extension?: string;
  total_delivery?: number;
  maximum_amount?: number;
  minimum_amount?: number;
  item_value?: number;
  free_item?: string;
  is_survey?: boolean;
}

const CouponListState = {
  isLoadedData: false,
  items: [],
};

export const genHeadline = (coupon: Coupon, storeProfile, userLanguage) => {
  const localize = new LocalizedStrings({
    jp: {
      // headline
      up_to: 'まで使用可能！',
      down_from: 'から使用可能！',
      item_value: '一個あたり',
    },
    en: {
      // headline
      up_to: 'Up to',
      down_from: 'Down from',
      item_value: 'Item value is',
    }
  });
  localize.setLanguage(userLanguage === Region.jp ? 'jp' : 'en');

  let result = '';
  const extOfAmount = (storeProfile?.region || userLanguage) === Region.jp ? '¥' : '$';
  switch (coupon.discount_type) {
  case DiscountType.discountPercentage:
    if (storeProfile?.region === Region.jp) {
      if (userLanguage === Region.jp) {
        // 5¥ xxxxxx => xxxxxx ¥5
        result = coupon.maximum_amount ? extOfAmount + coupon.maximum_amount  + ' ' + localize.up_to : '';
      } else {
        // xxxxxx 5¥ => xxxxxx ¥5
        result = coupon.maximum_amount ? localize.up_to + ' ' + extOfAmount + coupon.maximum_amount : '';
      }
    } else {
      if (userLanguage === Region.jp) {
        // $5 xxxxxx
        result = coupon.maximum_amount ? extOfAmount + coupon.maximum_amount + ' ' + localize.up_to : '';
      } else {
        // xxxxx $5
        result = coupon.maximum_amount ? localize.up_to + ' ' + extOfAmount + coupon.maximum_amount : '';
      }
    }
    break;
  case DiscountType.discountAmount:
    if (storeProfile?.region === Region.jp) {
      if (userLanguage === Region.jp) {
        // 5¥ xxxxxx => xxxxxx ¥5
        result = coupon.minimum_amount ? extOfAmount + coupon.minimum_amount + ' ' + localize.down_from : '';
      } else {
        // xxxxxx 5¥ => xxxxxx ¥5
        result = coupon.minimum_amount ? localize.down_from + ' ' + extOfAmount + coupon.minimum_amount : '';
      }
    } else {
      if (userLanguage === Region.jp) {
        // $5 xxxxxx
        result = coupon.minimum_amount ? extOfAmount + coupon.minimum_amount + ' ' + localize.down_from : '';
      } else {
        // xxxxx $5
        result = coupon.minimum_amount ? localize.down_from + ' ' + extOfAmount + coupon.minimum_amount : '';
      }
    }
    break;
  default:
    if (storeProfile?.region === Region.jp) {
      // ¥5
      result = coupon.item_value ? localize.item_value + ' ' + extOfAmount + coupon.item_value : '';
    } else {
      // $5
      result = coupon.item_value ? localize.item_value + ' ' + extOfAmount + coupon.item_value : '';
    }
    break;
  }
  return coupon.headline && coupon.discount_description ? coupon.discount_description : result;
};

export const genTitleHeadline = (coupon: Coupon, storeProfile, userLanguage) => {
  const localize = new LocalizedStrings({
    jp: {
      percentage_off: ' OFF',
    },
    en: {
      percentage_off: ' OFF',
    }
  });
  localize.setLanguage(userLanguage === Region.jp ? 'jp' : 'en');

  let result: any = '';
  const extOfAmount = (storeProfile?.region || userLanguage) === Region.jp ? '¥' : '$';

  switch ( Number(coupon.discount_type)) {
  case DiscountType.discountPercentage:
    result = (coupon.discount_value || 0) + '%' + localize.percentage_off;
    break;

  case DiscountType.discountAmount:
    result = (storeProfile?.region || userLanguage) === 1 
      ? (coupon.discount_value || 0) + extOfAmount + localize.percentage_off
      : extOfAmount + (coupon.discount_value || 0) + localize.percentage_off;
    break;
  default: 
    result = coupon.free_item;
    break;
  }
 
  return coupon.headline && coupon.discount_description ? coupon.headline : result;
};

const CouponListBase = (props: any): JSX.Element => {
  const [userLanguage, storeProfileData] = useSelector((s: any) => {
    return [s.userLanguage, s.storeProfile];
  });
  const [data, setData] = useState(CouponListState);
  const dispatch = useDispatch();

  const storeProfile = storeProfileData?.document;
  useEffect(() => {
    const fetchData = async () => {
      if (storeProfile?.id) {
        const result = await getCoupons(storeProfile?.id);
        setData({ isLoadedData: true, items: result?.data?.data });
      }
    };

    fetchData();
  }, [storeProfileData.isLoaded]);

  const coupons: any = data.items || null;

  // Init localize
  const localize = new LocalizedStrings({
    jp: {
      // Title
      title: 'クーポンリスト',
      title_home: 'Home',
      button_create_coupon: 'クーポンを作る',
      // Column header
      header_status: 'ステータス',
      header_headline: 'タイトル',
      header_subheadline: 'サブタイトル',
      header_start_date: '開始日',
      header_end_date: '終了日',
      header_total_delivery: '配信数',
      header_survey: 'アンケート',
      header_action: '操作',
      // Action
      action_stop: '配信停止',
      action_enable: '再配信',
      action_detail: '詳細',
      action_edit_publish: '編集',
      action_duplicate: 'コピー',
      action_performance: 'グラフ',
      action_discard_draft: '削除',
      // Status
      status_draft: '下書き',
      status_scheduled: '配信予定',
      status_active: '有効',
      status_ended: '終了',
      status_stopped: '配信停止',
      // headline
      up_to: 'まで使用可能！',
      down_from: 'から使用可能！',
      item_value: '一個あたり',
      // Survey
      has_survey: 'はい',
      not_has_survey: 'なし',
      // record empty
      record_empty: 'データが見つかりません。',

      // Toastr message
      _complete_title: '完了',
      _complete_message_stop: 'クーポンを無効にしました。',
      _complete_message_enable: 'クーポンを有効にしました。',
      _complete_message_copy: 'クーポンをコピーしました。',
      _complete_message_discard: 'クーポンを削除しました。',
      _failed_title: '失敗',
      _failed_message_stop: 'クーポンの配信を停止できません。',
      _failed_message_copy: 'クーポンをコピーできませんでした。',
      _failed_message_discard: 'クーポンを削除できません。'
    },
    en: {
      // title
      title: 'All Coupons',
      title_home: 'Home',
      button_create_coupon: 'Create A Coupon',
      // column header
      header_status: 'Status',
      header_headline: 'Headline',
      header_subheadline: 'Sub-Headline',
      header_start_date: 'Start Date',
      header_end_date: 'End Date',
      header_total_delivery: 'Number of Delivery',
      header_survey: 'Survey',
      header_action: 'Action',
      // action
      action_stop: 'Stop',
      action_enable: 'Enable',
      action_detail: 'Coupon Details',
      action_edit_publish: 'Edit & Publish',
      action_duplicate: 'Duplicate',
      action_performance: 'See Performance',
      action_discard_draft: 'Discard Draft',
      // Status
      status_draft: 'Draft',
      status_scheduled: 'Scheduled',
      status_active: 'Active',
      status_ended: 'Ended',
      status_stopped: 'Stopped',
      // headline
      up_to: 'Up to',
      down_from: 'Down from',
      item_value: 'Item value is',
      // Survey
      has_survey: 'Yes',
      not_has_survey: 'None',
      // Record empty
      record_empty: 'There is no data to display.',

      // Toastr message
      _complete_title: 'Success!',
      _complete_message_stop: 'You disabled the coupon.',
      _complete_message_enable: 'The coupon is now active.',
      _complete_message_copy: 'The coupon has been duplicated.',
      _complete_message_discard: 'The draft has been deleted.',
      _failed_title: 'Failure',
      _failed_message_stop: 'Can not stop coupon.',
      _failed_message_copy: 'Can not copy coupon.',
      _failed_message_discard: 'Can not discard coupon.'
    },
  });
  localize.setLanguage(userLanguage === Region.jp ? 'jp' : 'en');
  const dateFormat = userLanguage === Region.jp ? 'YYYY/MM/DD' : 'MM/DD/YYYY';

  // Waiting for load data is success
  if (data?.isLoadedData === false) {
    return (
      <div>
        <Header />
      </div>
    );
  }

  const genStatus = (coupon) => {
    const status = coupon.status_code;
    let result: React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;
    switch (status) {
    case CouponStatusCode.draft:
      result = <span className="label label-danger">{localize.status_draft}</span>;
      break;
    case CouponStatusCode.scheduled:
      result = <span className="label label-success">{localize.status_scheduled}</span>;
      break;
    case CouponStatusCode.active:
      result = <span className="label label-info">{localize.status_active}</span>;
      break;
    case CouponStatusCode.ended:
      result = <span className="label label-inverse">{localize.status_ended}</span>;
      break;
    default:
      result = <span></span>;
    }

    const now : Date = moment().toDate();
    const endDate: Date | null = coupon.end_at ? moment(coupon.end_at).toDate() : null;
    if (coupon.is_stopped || (endDate && endDate < now)) {
      result = <span className="label label-danger">{localize.status_stopped}</span>;
    }

    return result;
  };

  const genSurvey = (isSurvey) => {
    let result = '';
    if (isSurvey === true) {
      result = localize.has_survey;
    } else {
      result = localize.not_has_survey;
    }
    return result;
  };

  const genStatusButtonStop = (coupon: Coupon): boolean => {
    let status = false;

    if (coupon.is_stopped === true) {
      return false;
    }
    const now : Date = moment().toDate();
    const startDate: Date | null = coupon.start_at ? moment(coupon.start_at).toDate() : null;
    const endDate: Date | null = coupon.end_at ? moment(coupon.end_at).toDate() : null;

    switch (Number(coupon.status_code)) {
    case CouponStatusCode.draft:
      status = false;
      break;
    case CouponStatusCode.scheduled:
      if (startDate && now < startDate) {
        status = true;
      }
      else if (endDate && endDate < now) {
        status = false;
      } else {
        status = true;
      }
      break;

    case CouponStatusCode.active:
      status = true;
      break;

    case CouponStatusCode.ended:
      status = false;
      break;

    default:
      break;
    }

    return status;
  };

  const genStatusButtonEnable = (coupon: Coupon): boolean => {
    let status = false;
    const now : Date = moment().toDate();
    const endDate: Date | null = coupon.end_at ? moment(coupon.end_at).toDate() : null;
    if (coupon.is_stopped === true && endDate && now < endDate && coupon.status_code === CouponStatusCode.active) {
      status = true;
    }

    return status;
  };

  const genStatusButtonEdit = (coupon: Coupon): boolean => {
    let status = false;
    const now : Date = moment().toDate();
    const startDate: Date | null = coupon.start_at ? moment(coupon.start_at).toDate() : null;
    const endDate: Date | null = coupon.end_at ? moment(coupon.end_at).toDate() : null;

    switch (Number(coupon.status_code)) {
    case CouponStatusCode.draft:
      status = true;
      break;
    case CouponStatusCode.scheduled:
      if (startDate && now < startDate) {
        status = true;
      }
      else if (endDate && endDate < now) {
        status = false;
      } else {
        status = false;
      }
      break;

    case CouponStatusCode.active:
      status = false;
      break;

    case CouponStatusCode.ended:
      status = false;
      break;

    default:
      break;
    }

    if (coupon.total_delivery && coupon.total_delivery > 0) {
      status = false;
    }

    return status;
  };

  const genStatusButtonDiscardDraft = (coupon: Coupon): boolean => {
    // delivering ticket(s) or delivered ticket(s) cannot be deleted
    let status = false;
    const now : Date = moment().toDate();
    const startDate: Date | null = coupon.start_at ? moment(coupon.start_at).toDate() : null;
    const endDate: Date | null = coupon.end_at ? moment(coupon.end_at).toDate() : null;

    switch (Number(coupon.status_code)) {
    case CouponStatusCode.draft:
      status = true;
      break;
    case CouponStatusCode.scheduled:
      if (startDate && now < startDate) {
        status = true;
      }
      else if (endDate && endDate < now) {
        status = false;
      } else {
        status = false;
      }
      break;

    case CouponStatusCode.active:
      status = false;
      break;

    case CouponStatusCode.ended:
      status = false;
      break;

    default:
      break;
    }

    if (coupon.total_delivery && coupon.total_delivery > 0) {
      status = false;
    } else if (coupon.is_stopped === true) {
      return true;
    }

    return status;
  };

  const genButtonStoppedOrEnable = (coupon: Coupon) => {
    if (genStatusButtonEnable(coupon)) {
      return (
        <button
          type="button"
          name="button"
          className="btn btn-xs btn-rounded btn-success btn-block"
          onClick={async (event) => {
            dispatch({ type: ACTIVATE_LOADING });
            try {
              await stopCoupon(storeProfile?.id, coupon.id);
              event.preventDefault();
              toastr.success(localize._complete_title, localize._complete_message_enable);
              const result = await getCoupons(storeProfile?.id);
              setData({ isLoadedData: true, items: result?.data?.data });
            } catch (err) {
              console.error(err);
              toastr.error(localize._failed_title, localize._failed_message_stop);
            } finally {
              dispatch({ type: DEACTIVATE_LOADING });
            }
          }}>
          {localize.action_enable}
        </button>
      );
    } else {
      return (
        <button
          type="button"
          name="button"
          className="btn btn-xs btn-rounded btn-outline-danger btn-block"
          disabled={!genStatusButtonStop(coupon)}
          onClick={async (event) => {
            dispatch({ type: ACTIVATE_LOADING });
            try {
              await stopCoupon(storeProfile?.id, coupon.id);
              event.preventDefault();
              toastr.success(localize._complete_title, localize._complete_message_stop);
              const result = await getCoupons(storeProfile?.id);
              setData({ isLoadedData: true, items: result?.data?.data });
            } catch (err) {
              console.error(err);
              toastr.error(localize._failed_title, localize._failed_message_stop);
            } finally {
              dispatch({ type: DEACTIVATE_LOADING });
            }
          }}
        >
          {localize.action_stop}
        </button>
      );
    }
  };  

  return (
    <div>
      <Header />
      <Sidebar />
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="row page-titles">
            <div className="col-md-5 col-8 align-self-center">
              <h3 className="text-themecolor m-b-0 m-t-0">{localize.title}</h3>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">{localize.title_home}</a>
                </li>
                <li className="breadcrumb-item active">{localize.title}</li>
              </ol>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <a href="/coupon">
                    <button type="button" className="btn btn-info btn-zooo m-t-10 float-right">
                      {localize.button_create_coupon}
                    </button>
                  </a>
                  <div className="table-responsive">
                    <table
                      id="demo-foo-addrow"
                      className="table table-bordered m-t-30 table-hover contact-list table-responsive coupon-action-btn"
                      data-paging="true"
                      data-paging-size="7"
                      data-filtering="true"
                      data-sorting="true"
                    >
                      <thead>
                        <tr className="footable-filtering">
                          <th>{localize.header_status}</th>
                          <th>{localize.header_headline}</th>
                          <th>{localize.header_subheadline}</th>
                          <th>{localize.header_start_date}</th>
                          <th>{localize.header_end_date}</th>
                          <th>{localize.header_total_delivery}</th>
                          <th>{localize.header_survey}</th>
                          <th>{localize.header_action}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {coupons && coupons.length > 0 ? (
                          coupons.map((coupon, index) => {
                            return (
                              <tr key={index}>
                                <td>{genStatus(coupon)}</td>
                                <td>{genTitleHeadline(coupon, storeProfile, userLanguage)}</td>
                                <td>{genHeadline(coupon, storeProfile, userLanguage)}</td>
                                <td>{moment(coupon.start_at).format(dateFormat+ ' HH:mm')}</td>
                                <td>{moment(coupon.end_at).format(dateFormat+ ' HH:mm')}</td>
                                <td>{coupon.total_delivery}</td>
                                <td>{genSurvey(coupon.is_survey)}</td>
                                <td>
                                  <div className="row">
                                    <div className="col-lg-6">
                                      {genButtonStoppedOrEnable(coupon)}
                                      <button
                                        type="button"
                                        name="button"
                                        className="btn btn-xs btn-rounded btn-info btn-block "
                                        onClick={() =>  {
                                          const history = props.history;
                                          history.push(`/coupons/${coupon.id}`);
                                        }}
                                      >
                                        {localize.action_detail}
                                      </button>
                                      <button
                                        type="button"
                                        name="button"
                                        className="btn btn-xs btn-rounded btn-success btn-block"
                                        disabled={!genStatusButtonEdit(coupon)}
                                        onClick={() =>  {
                                          const history = props.history;
                                          history.push(`/coupons/${coupon.id}/edit`);
                                        }}
                                      >
                                        {localize.action_edit_publish}
                                      </button>
                                    </div>
                                    <div className="col-lg-6">
                                      <button
                                        type="button"
                                        name="button"
                                        className="btn btn-xs btn-rounded btn-outline-info btn-block"
                                        onClick={async (event) => {
                                          dispatch({ type: ACTIVATE_LOADING });
                                          try {
                                            await copyCoupon(storeProfile?.id, coupon.id);
                                            event.preventDefault();
                                            toastr.success(localize._complete_title, localize._complete_message_copy);
                                            const result = await getCoupons(storeProfile?.id);
                                            setData({ isLoadedData: true, items: result?.data?.data });
                                          } catch (err) {
                                            console.error(err);
                                            toastr.error(localize._failed_title, localize._failed_message_copy);
                                          } finally {
                                            dispatch({ type: DEACTIVATE_LOADING });
                                          }
                                        }}
                                      >
                                        {localize.action_duplicate}
                                      </button>
                                      <button
                                        type="button"
                                        name="button"
                                        className="btn btn-xs btn-rounded btn-warning btn-block"
                                        onClick={() =>  {
                                          const history = props.history;
                                          history.push(`/coupons/${coupon.id}/result`);
                                        }}
                                      >
                                        {localize.action_performance}
                                      </button>
                                      <button
                                        type="button"
                                        name="button"
                                        className="btn btn-xs btn-rounded btn-outline-secondary btn-block"
                                        disabled={!genStatusButtonDiscardDraft(coupon)}
                                        onClick={async (event) => {
                                          dispatch({ type: ACTIVATE_LOADING });
                                          try {
                                            await deleteCoupon(storeProfile?.id, coupon.id);
                                            event.preventDefault();
                                            toastr.success(localize._complete_title, localize._complete_message_discard);
                                            const result = await getCoupons(storeProfile?.id);
                                            setData({ isLoadedData: true, items: result?.data?.data });
                                          } catch (err) {
                                            console.error(err);
                                            toastr.error(localize._failed_title, localize._failed_message_discard);
                                          } finally {
                                            dispatch({ type: DEACTIVATE_LOADING });
                                          }
                                        }}
                                      >
                                        {localize.action_discard_draft}
                                      </button>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td className="text-center" colSpan={8}>
                              {localize.record_empty}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

const CouponList = compose(withAuthorization((authUser: any) => !!authUser))(CouponListBase);

export default CouponList;
