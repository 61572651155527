import { Field, Form } from 'formik';
import React, { useEffect, useState } from 'react';
import LocalizedStrings from 'react-localization';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { getCouponResult } from '../../functions';
import { genHeadline, genTitleHeadline, Region } from '../CouponList';
import Footer from '../Footer';
import Header from '../Header';
import { withAuthorization } from '../Session';
import Sidebar from '../Sidebar';
import moment from 'moment';
import { Doughnut } from 'react-chartjs-2';
import StorebgImage from '../../assets/images/background/store-bg.jpg';

const chartState = {
  coupon_used_by_age : [],
  coupon_used_by_gender: [],
};

const CouponResultState = {
  isLoadedData: false,
  data: {},
};

const CouponResultBase = (props: any ): JSX.Element => {
  const couponId = props.match.params.couponId;
  const [data, setData] = useState(CouponResultState);
  const [dataChart, setDataChart] = useState(chartState);
  const [userLanguage, storeProfileData] = useSelector((s: any) => {
    return [ s.userLanguage, s.storeProfile ];
  });
  const storeProfile = storeProfileData.document;

  useEffect(() => {
    const fetchData = async () => {
      if (storeProfile?.id) {
        const result = await getCouponResult(storeProfile?.id, couponId);
        setData({ isLoadedData: true, data: result?.data?.data });
      }
    };

    fetchData();
  }, [storeProfileData.isLoaded, couponId]);

  const couponResultData: any = data?.data || null;

  useEffect(()=> {
    const fetchDataChart = async () => {
      if (couponResultData?.statistics) {
        setDataChart({coupon_used_by_gender: couponResultData?.statistics?.coupon_used_by_gender, coupon_used_by_age: couponResultData?.statistics?.coupon_used_by_age});
      }

    };
    fetchDataChart();
  }, [couponResultData]);


  const localize = new LocalizedStrings({
    jp: {
      title: 'クーポンのデータ',
      title_home: 'Home',
      statistics_distributed: '期間',
      statistics_total_delivery: '配信数',
      statistics_total_used: '使用数',
      statistics_coupon_used_by_gender:'性別ごとの使用数',
      statistics_coupon_used_by_age: '年齢ごとの使用数',
    },
    en: {
      title: 'Coupon Result',
      title_home: 'Home',
      statistics_distributed: 'Distributed',
      statistics_total_delivery: 'Number of deliveries',
      statistics_total_used: 'Number of redemptions',
      statistics_coupon_used_by_gender:'Redemptions by gender',
      statistics_coupon_used_by_age: 'Redemptions by age group',
    },
  });
  localize.setLanguage(userLanguage === 1 ? 'jp' : 'en');
  const dateFormat = userLanguage === Region.jp ? 'YYYY/MM/DD' : 'MM/DD/YYYY';

  

  // Waiting for load data is success
  if (data?.isLoadedData === false) {
    return (
      <div>
        <Header />
      </div>
    );
  }

  const LocalizeGenderChart = {
    jp: {
      male: '男性',
      female: '女性',
      unknown: '不明',
    },
    en: {
      male: 'Male',
      female: 'Female',
      unknown: 'Unknown',
    }
  };
  
  const LocalizeAgeGroupChart = {
    jp: {
      10: '19以下',
      20: '20s',
      30: '30s',
      40: '40s',
      50: '50s',
      60: '60+',
    },
    en: {
      10: 'Teens',
      20: '20s',
      30: '30s',
      40: '40s',
      50: '50s',
      60: '60+',
    }
  };

  const genChartCouponUsedByGender = () => {
    if (dataChart.coupon_used_by_gender.length > 0) {
      const state = {
        labels: ['male', 'female', 'unknown'].map(item => {
          const lang = userLanguage === 1 ? 'jp' : 'en';
          return LocalizeGenderChart[lang][item];
        }),
        datasets: [
          {
            backgroundColor: ['rgb(30, 136, 229)','rgb(255, 51, 153)','rgb(236, 239, 241)'],
            data: dataChart.coupon_used_by_gender
          }
        ]
      };

      return (
        <Doughnut
          id="response-by-gender"
          typeof="doughnut"
          data={state}
          height={244}
          options={{ maintainAspectRatio: false }}
        />
      );
    } else {
      return null;
    }
  };

  const genChartCouponUsedByAgeGroup = () => {
    if (dataChart.coupon_used_by_age.length > 0) {
      const state = {
        labels: ['10', '20', '30', '40', '50', '60'].map(item => {
          const lang = userLanguage === 1 ? 'jp' : 'en';
          return LocalizeAgeGroupChart[lang][item];
        }),
        datasets: [
          {
            backgroundColor: ['rgb(30, 136, 229, 0.2)','rgb(30, 136, 229, 0.4)','rgb(30, 136, 229, 0.6)','rgb(30, 136, 229, 0.8)','rgb(30, 136, 229, 0.9)','rgb(30, 136, 229, 1.0)'],
            data: dataChart.coupon_used_by_age
          }
        ]
      };

      return (
        <Doughnut
          id="response-by-age-group"
          data={state}
          height={244}
          options={{ maintainAspectRatio: false }}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <div>
      <Header />
      <Sidebar />
      <div className="page-wrapper">
        <div className="container-fluid">
          {/* <!-- Bread crumb and right sidebar toggle --> */}
          <div className="row page-titles">
            <div className="col-md-8 align-self-center">
              <h3 className="text-themecolor m-b-0 m-t-0">{localize.title}</h3>
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="javascript:void(0)">{localize.title_home}</a></li>
                <li className="breadcrumb-item active">{localize.title}</li>
              </ol>
            </div>

          </div>
          {/* <!-- End Bread crumb and right sidebar toggle --> */}
          {/* <!-- Start Page Content --> */}

          <div className="row">
            <div className="col-lg-4 col-xlg-3 col-md-5">
              <div className="card">
                <img className="card-img" src={StorebgImage} alt="Card image" />
                <div className="card-img-overlay card-inverse social-profile ">
                  <div className="align-self-center coupon-result-headline">
                    <h4 className="card-title">{genTitleHeadline(couponResultData?.coupon, storeProfile, userLanguage)}</h4>
                    <p className="text-white">{genHeadline(couponResultData?.coupon, storeProfile, userLanguage)}</p>

                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-8 col-xlg-9 col-md-7 text-center">
              <div className="card-group">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <h6 className="card-subtitle m-t-10">{localize.statistics_distributed}</h6>
                        <h4 className="text-ZOOOpink m-t-10">{moment(couponResultData?.coupon?.start_at).format(dateFormat)} - {moment(couponResultData?.coupon?.end_at).format(dateFormat)}</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <i></i>
                        <h2 className="text-ZOOOpink m-t-10">{couponResultData?.statistics?.total_delivery}</h2>
                        <h6 className="card-subtitle">{localize.statistics_total_delivery}</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <h2 className="text-ZOOOpink m-t-10">{couponResultData?.statistics?.total_used}</h2>
                        <h6 className="card-subtitle">{localize.statistics_total_used}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="card">
                    <div className="card-body" style={{minHeight:'350px'}}>
                      <h3 className="card-title m-b-30">{localize.statistics_coupon_used_by_gender}</h3>
                      <div>
                        {genChartCouponUsedByGender()}
                        {/* <canvas id="response-by-gender" height="180"></canvas> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="card">
                    <div className="card-body" style={{minHeight:'350px'}}>
                      <h3 className="card-title m-b-30">{localize.statistics_coupon_used_by_age}</h3>
                      <div>
                        {genChartCouponUsedByAgeGroup()}
                        {/* <canvas id="response-by-age-group" height="200"></canvas> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Row --> */}
          {/* <!-- End Page Content --> */}
        </div>

        <Footer />
      </div>
    </div>
  );
};

const CouponResult = compose(
  withRouter,
  withAuthorization((authUser: any) => !!authUser),
)(CouponResultBase);

export default CouponResult;