import { Field, FormikErrors, FormikTouched } from 'formik';
import React from 'react';
import LocalizedStrings from 'react-localization';
import { useState } from 'react';
// import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import moment from 'moment';
import { useEffect } from 'react';
import DateRangePicker from 'rsuite/DateRangePicker';
import { startOfDay, endOfDay, addDays, addMonths } from 'date-fns';

interface MyProps {
  userLanguage: number;
  values: any,
  touched: FormikTouched<any>,
  errors: FormikErrors<any>,
  setFieldError: (field: string, message: string | undefined) => void,
  nextPage: () => void,
  prevPage: () => void,
  setInputValue?: any,
}

export default function ThirdForm({userLanguage, values, touched, errors, setFieldError, nextPage, prevPage, setInputValue}: MyProps): JSX.Element {
  const [value, setValue] = useState<any>([null, null]);
  useEffect(() => {
    if (!!values.start_at && !!values.end_at) {
      setValue([moment(values.start_at, 'YYYY/MM/DD HH:mm').toDate(), moment(values.end_at, 'YYYY/MM/DD HH:mm').toDate()]);
      setInputValue({...values});
    }
  }, [true]);

  const localize = new LocalizedStrings({
    jp: {
      title: '配信設定',

      active: '利用可能条件',
      after_check_ins: 'チェックインするたび、使用可能となるクーポン',
      after_certain_check_ins_text: 'たとえば、三度目の来店時にのみ利用可能とするクーポンなどが設定できます！',
      after_certain_check_ins: '来店数を指定、そのたび使用可能となるクーポン',
      on_specific_days: '曜日を指定、その日に一度だけ使用可能となるクーポン',

      text_great1: 'ナイス！クーポンは、この配信設定の内容にしたがって利用可能となります。',
      text_great2: '（これは毎日リセットされます。クーポンは、一日に2回以上チェックインしても、一日に1回しか利用できません。）',

      calendar_today_text: 'Today',
      calendar_a_week_text: '7 Days',
      calendar_a_month_text: '1 Month',

      monday: '月曜日',
      tuesday: '火曜日',
      wednesday: '水曜日',
      thursday: '木曜日',
      friday: '金曜日',
      saturday: '土曜日',
      sunday: '日曜日',

      how_range: 'クーポンの配信期間設定 *',

      _invalid_checkins_times: '一つ以上、利用できるチェックイン回数を指定してください（半角数値/カンマが使用可能）',
      _invalid_included_day: '必ず、一個以上のクーポン利用可能曜日を指定してください',
      _invalid_date_range: '必ず、配信の開始と終了を設定してください',
    },
    en: {
      title: 'Distribution Setting',

      active: 'Activate coupons ... *',
      after_check_ins: 'After the shopper checks in every time',
      after_certain_check_ins_text: 'You can be creative with this option! 🖊️ For example, you can set the coupon to be available only on the 3rd check-in, or every other check-ins',
      after_certain_check_ins: 'After the shopper checks in certain number of times',
      on_specific_days: 'On specific day(s) of the week',

      text_great1: 'Great! The coupon will be available to use whenever every time the shopper checks in.',
      text_great2: '(This resets daily. The coupon will be available only once a day even if the shopper checks in more than 2 times a day.)',

      calendar_today_text: 'Today',
      calendar_a_week_text: '7 Days',
      calendar_a_month_text: '1 Month',

      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
      sunday: 'Sunday',

      how_range: 'When does this offer start and end? *',

      _invalid_checkins_times: 'Must be set one or more check-in times (Can be set characters are number(10 or less) or commas)',
      _invalid_included_day: 'Must be set one or more day',
      _invalid_date_range: 'Must be set distributing start and end at',
    },
  });
  localize.setLanguage(userLanguage === 1 ? 'jp' : 'en');

  values.activation_type = values.activation_type + '';
  const times = values.check_in_times;
  if (typeof times !== 'string' && Array.isArray(times) && times.length) {
    values.check_in_times = times.join(',').replace(/ /g, '');
  } else if (typeof times === 'number') {
    values.check_in_times = times + '';
  }

  // Add ranges custom for calendar
  const ranges: any = [
    {
      label: localize.calendar_today_text,
      value: [startOfDay(new Date()), endOfDay(new Date())]
    },
    {
      label: localize.calendar_a_week_text,
      value: [startOfDay(new Date()), endOfDay(addDays(new Date(), 6))]
    },
    {
      label: localize.calendar_a_month_text,
      value: [startOfDay(new Date()), endOfDay(addMonths(new Date(), 1))]
    }
  ];

  return (
    <>
      <div className="content clearfix">
        <section className="body current" role="tabpanel" aria-labelledby="steps-uid-0-h-3" aria-hidden={false}>
          <div className="row">
            <div className="col-md-12">
              <h3 className="card-title m-t-30 m-b-30">{localize.title}</h3>
              <div className="form-group">
                <h4 className="card-title m-t-30 m-b-30">{localize.active}</h4>
                <div className="">
                  <ul className="list-unstyled">
                    <li>
                      <div>
                        <Field id="1" name="activation_type" type="radio" value="1" />
                        <label htmlFor="1">{localize.after_check_ins}</label>
                      </div>
                    </li>
                    <li>
                      <div>
                        <Field id="2" name="activation_type" type="radio" value="2" />
                        <label htmlFor="2">{localize.after_certain_check_ins}</label>
                      </div>
                    </li>
                  </ul>
                </div>

                <hr />
                <div className="tab-content">
                  {
                    values.activation_type === '1' &&
                    (
                      <div>
                        <p className="m-t-30 m-b-30">{localize.text_great1}<br/>{localize.text_great2}</p>
                      </div>
                    )
                  }
                  {
                    values.activation_type === '2' &&
                    (
                      <div className="">
                        <p className="m-t-30 m-b-30">{localize.after_certain_check_ins_text}</p>
                        <Field type="number" name="check_in_times" placeholder="2" className="form-control" />
                        {
                          errors.check_in_times &&
                          <p className="text-danger">{localize._invalid_checkins_times}</p>
                        }
                      </div>
                    )
                  }
                </div>
              </div>

              <div>
                <h4 className="card-title m-t-30 m-b-30">{localize.how_range}</h4>
                <div className="input-group mb-3">
                  <DateRangePicker format="yyyy-MM-dd hh:mm aa"
                    ranges={ranges}
                    value={value}
                    onChange={(_)=>{
                      if (!_ || !Array.isArray(_) || _.length !== 2) {
                        setValue([undefined, undefined]);
                        return;
                      }
                      let [startAt, endAt] = [moment(_[0]), moment(_[1])];
                      if (moment(_[0]) && moment(_[1]) && moment(_[0]) > moment(_[1])) {
                        [startAt, endAt] = [moment(_[1]), moment(_[0])];
                      } 
                      values.start_at = startAt.format('YYYY/MM/DD HH:mm');
                      values.end_at = endAt.format('YYYY/MM/DD HH:mm');
                      setValue(_);
                      setInputValue({...values});
                    }}
                  />
                  {errors.start_at && (
                    <p className="text-danger">{errors.start_at}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/** Actions button */}
      <div className="actions clearfix">
        <ul role="menu" aria-label="Pagination">
          <li className="disabled" aria-disabled="true">
            <a onClick={(_)=>{
              prevPage();
              _.preventDefault();
            }} href="/" role="menuitem" style={{backgroundColor:'#fff',border:'1px solid #d9d9d9',color:'#999',boxShadow:'none'}}>Previous</a>
          </li>
          <li aria-hidden="false" aria-disabled="false">
            <a href="/coupon-preview" role="menuitem" onClick={(e)=>{
              values.activation_type = parseInt(values.activation_type);

              if (!values.start_at || !values.end_at) {
                setFieldError('start_at', localize._invalid_date_range);
                e.preventDefault();
                return;
              }

              if (
                values.activation_type === 2
                && (
                  typeof values.check_in_times !== 'string' ||
                  !values.check_in_times ||
                  !/(?=^[0-9,]+$)(?=[0-9]{1,})/.test(values.check_in_times)
                )
              ) {
                setFieldError('check_in_times', localize._invalid_checkins_times);
                e.preventDefault();
                return;
              } else if (values.activation_type === 2) {
                // eslint-disable-next-line no-irregular-whitespace
                values.check_in_times = values.check_in_times.replace(/ 　/g, '').replace(/,$/, '').split(',');
                for (const index in values.check_in_times) {
                  const i = parseInt(values.check_in_times[index]);
                  if (isNaN(i) || i > 10) {
                    setFieldError('check_in_times', localize._invalid_checkins_times);
                    e.preventDefault();
                    return;
                  }
                  values.check_in_times[index] = i;
                }
              }

              if (values.activation_type === 3 && (!Array.isArray(values.included_day) || !values.included_day.length)) {
                setFieldError('included_day', localize._invalid_included_day);
                e.preventDefault();
                return;
              }

              if (values.activation_type === 1) {
                values.check_in_times = [];
                values.included_day = [];
              } else if (values.activation_type === 2) {
                values.included_day = [];
              } else if (values.activation_type === 3) {
                values.check_in_times = [];
              }
              nextPage();
              e.preventDefault();
            }}>Next</a>
          </li>
          <li aria-hidden="true" style={{display: 'none'}}>
            <a href="/" role="menuitem">Submit</a>
          </li>
        </ul>
      </div>
    </>
  );
}
