import * as Yup from 'yup';
import _ from 'lodash';

const InitState = {
  business_name: '',

  branch_name: '',

  tel_number: '',

  category: '',

  sub_category: '',

  email_address: '',

  homepage: '',

  address1: '',

  address2: '',

  city: '',

  state: '',

  country: '',

  zip_code: '',

  geo_hash: '',

  about_business: '',

  service_disclaimer: '',

  representative_name: '',

  officer_name: '',

  dynamic_link: '',

  covid19_amenity: {
    required_masks: false,
    staff_wears_masks: false,
    staff_wears_gloves: false,
    social_distancing: false,
    limited_capacity: false,
    checks_temperature: false,
    hand_sanitizer: false,
    staff_sanitizes_between_customers: false,
    curbside_pick_up: false,
  },

  fb_amenity: {
    price_range: '1',
    dress_code: '1',
    option_vegetarian: false,
    option_vegan: false,
    happy_hour: false,
    outdoor_seating: false,
    delivery: false,
    pick_up: false,
    takeout: false,
    drive_thru: false,
    sit_down_dining: false,
    waitstaff_service: false,
    full_bar: false,
    sommelier: false,
    private_dining: false,
    good_for_breakfast: false,
    good_for_lunch: false,
    good_for_dinner: false,
    good_for_late_night: false,
    accepts_reservations: false,
  },

  general_amenity: {
    accepts_credit: false,
    accepts_apple_pay: false,
    accepts_google_pay: false,
    pet_friendly: false,
    free_wifi: false,
    good_for_dates: false,
    good_for_kids: false,
    good_for_groups: false,
    street_parking: false,
    garage_parking: false,
    parking_available: false,
    valet_parking: false,
    bike_parking: false,
    smoke_free: false,
    smoking_area_available: false,
    powder_room_available: false,
    wheelchair_accessible: false,
    tv: false,
    gender_natural_restroom_available: false,
    bilingual_staff: false,
    public_transportation_nearby: false,
    quiet_ambiance: false,
  },

  operation_hour: {},
};

const ValidationScheme = Yup.object().shape({
  business_name: Yup.string()
    .min(1, {length: 1, message: 'min'})
    .max(100, {length: 100, message: 'max'})
    .required({message: 'required'}),
  branch_name: Yup.string()
    .min(1, {length: 1, message: 'min'})
    .max(100, {length: 100, message: 'max'})
    .optional(),
  // tel_number: Yup.string()
  //   .matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, {message: 'invalid_phone_number'})
  //   .required({message: 'required'}),
  category: Yup.string()
    .required({message: 'required'}),
  sub_category: Yup.string()
    .required({message: 'required'}),
  email_address: Yup.string()
    .min(5, {length: 5, message: 'min'})
    .max(400, {length: 400, message: 'max'})
    .email({message: 'invalid_email'})
    .required({message: 'required'}),
  homepage: Yup.string()
    .url({message: 'invalid_url'})
    .optional(),

  about_business: Yup.string()
    .transform((value) => {
      return (removeHTML(value)).length > 0 ? removeHTML(value) : undefined;
    })
    .min(5, {length: 5, message: 'min'})
    .max(800, {length: 800, message: 'max'})
    .optional(),
  service_disclaimer: Yup.string()
    .min(1, {length: 1, message: 'min'})
    .max(400, {length: 400, message: 'max'})
    .optional(),

  address1: Yup.string()
    .min(1, {length: 1, message: 'min'})
    .max(100, {length: 100, message: 'max'})
    .required({message: 'required'}),
  address2: Yup.string()
    .min(1, {length: 1, message: 'min'})
    .max(100, {length: 100, message: 'max'})
    .optional(),
  zip_code: Yup.string()
    .min(5, {length: 5, message: 'min'})
    .max(9, {length: 9, message: 'max'})
    .required({message: 'required'}),
  city: Yup.string()
    .min(1, {length: 1, message: 'min'})
    .max(100, {length: 50, message: 'max'})
    .required({message: 'empty_city'}),
  state: Yup.string()
    .min(1, {length: 1, message: 'min'})
    .max(100, {length: 50, message: 'max'})
    .required({message: 'empty_state'}),
  country: Yup.string()
    .min(1, {length: 1, message: 'min'})
    .max(100, {length: 50, message: 'max'})
    .required({message: 'required'}),

  operation_hour: Yup.object().shape({
    monday: Yup.object().shape({
      start_at: Yup.array()
        .of(Yup.string().matches(/^[\d]{1,2}:[\d]{2,2}$/))
        .min(1)
        .max(3)
        .required(),
      end_at: Yup.array()
        .of(Yup.string().matches(/^[\d]{1,2}:[\d]{2,2}$/))
        .min(1)
        .max(3)
        .required(),
      closed_day: Yup.boolean().required('Required field'),
      opened_24h_day: Yup.boolean().required('Required field'),
      // valid_time: Yup.array().test('', {message: 'time_from_greater_time_to'}, (value : any, context) => {
      //   return !_.find(context.parent.end_at, (value, index) => value != '00:00' && value < context.parent.start_at[index]);
      // }),
    }).required(),
    tuesday: Yup.object().shape({
      start_at: Yup.array()
        .of(Yup.string().matches(/^[\d]{1,2}:[\d]{2,2}$/))
        .min(1)
        .max(3)
        .required(),
      end_at: Yup.array()
        .of(Yup.string().matches(/^[\d]{1,2}:[\d]{2,2}$/))
        .min(1)
        .max(3)
        .required(),
      closed_day: Yup.boolean().required('Required field'),
      opened_24h_day: Yup.boolean().required('Required field'),
      // valid_time: Yup.array().test('', {message: 'time_from_greater_time_to'}, (value : any, context) => {
      //   return !_.find(context.parent.end_at, (value, index) => value != '00:00' && value < context.parent.start_at[index]);
      // }),
    }).required(),
    wednesday: Yup.object().shape({
      start_at: Yup.array()
        .of(Yup.string().matches(/^[\d]{1,2}:[\d]{2,2}$/))
        .min(1)
        .max(3)
        .required(),
      end_at: Yup.array()
        .of(Yup.string().matches(/^[\d]{1,2}:[\d]{2,2}$/))
        .min(1)
        .max(3)
        .required(),
      closed_day: Yup.boolean().required('Required field'),
      opened_24h_day: Yup.boolean().required('Required field'),
      // valid_time: Yup.array().test('', {message: 'time_from_greater_time_to'}, (value : any, context) => {
      //   return !_.find(context.parent.end_at, (value, index) => value != '00:00' && value < context.parent.start_at[index]);
      // }),
    }).required(),
    thursday: Yup.object().shape({
      start_at: Yup.array()
        .of(Yup.string().matches(/^[\d]{1,2}:[\d]{2,2}$/))
        .min(1)
        .max(3)
        .required(),
      end_at: Yup.array()
        .of(Yup.string().matches(/^[\d]{1,2}:[\d]{2,2}$/))
        .min(1)
        .max(3)
        .required(),
      closed_day: Yup.boolean().required('Required field'),
      opened_24h_day: Yup.boolean().required('Required field'),
      // valid_time: Yup.array().test('', {message: 'time_from_greater_time_to'}, (value : any, context) => {
      //   return !_.find(context.parent.end_at, (value, index) => value != '00:00' && value < context.parent.start_at[index]);
      // }),
    }).required(),
    friday: Yup.object().shape({
      start_at: Yup.array()
        .of(Yup.string().matches(/^[\d]{1,2}:[\d]{2,2}$/))
        .min(1)
        .max(3)
        .required(),
      end_at: Yup.array()
        .of(Yup.string().matches(/^[\d]{1,2}:[\d]{2,2}$/))
        .min(1)
        .max(3)
        .required(),
      closed_day: Yup.boolean().required('Required field'),
      opened_24h_day: Yup.boolean().required('Required field'),
      // valid_time: Yup.array().test('', {message: 'time_from_greater_time_to'}, (value : any, context) => {
      //   return !_.find(context.parent.end_at, (value, index) => value != '00:00' && value < context.parent.start_at[index]);
      // }),
    }).required(),
    saturday: Yup.object().shape({
      start_at: Yup.array()
        .of(Yup.string().matches(/^[\d]{1,2}:[\d]{2,2}$/))
        .min(1)
        .max(3)
        .required(),
      end_at: Yup.array()
        .of(Yup.string().matches(/^[\d]{1,2}:[\d]{2,2}$/))
        .min(1)
        .max(3)
        .required(),
      closed_day: Yup.boolean().required('Required field'),
      opened_24h_day: Yup.boolean().required('Required field'),
      // valid_time: Yup.array().test('', {message: 'time_from_greater_time_to'}, (value : any, context) => {
      //   return !_.find(context.parent.end_at, (value, index) => value != '00:00' && value < context.parent.start_at[index]);
      // }),
    }).required(),
    sunday: Yup.object().shape({
      start_at: Yup.array()
        .of(Yup.string().matches(/^[\d]{1,2}:[\d]{2,2}$/))
        .min(1)
        .max(3)
        .required(),
      end_at: Yup.array()
        .of(Yup.string().matches(/^[\d]{1,2}:[\d]{2,2}$/))
        .min(1)
        .max(3)
        .required(),
      closed_day: Yup.boolean().required('Required field'),
      opened_24h_day: Yup.boolean().required('Required field'),
      // valid_time: Yup.array().test('', {message: 'time_from_greater_time_to'}, (value : any, context) => {
      //   return !_.find(context.parent.end_at, (value, index) => value != '00:00' && value < context.parent.start_at[index]);
      // }),
    }).required(),
  }).required(),

  covid19_amenity: Yup.object().shape({
    required_masks: Yup.boolean()
      .required('Required field'),
    staff_wears_masks: Yup.boolean()
      .required('Required field'),
    staff_wears_gloves: Yup.boolean()
      .required('Required field'),
    social_distancing: Yup.boolean()
      .required('Required field'),
    limited_capacity: Yup.boolean()
      .required('Required field'),
    checks_temperature: Yup.boolean()
      .required('Required field'),
    hand_sanitizer: Yup.boolean()
      .required('Required field'),
    staff_sanitizes_between_customers: Yup.boolean()
      .required('Required field'),
    curbside_pick_up: Yup.boolean()
      .required('Required field'),
  }).required(),

  fb_amenity: Yup.object().shape({
    price_range: Yup.string()
      .required('Required field'),
    dress_code: Yup.string()
      .required('Required field'),
    option_vegetarian: Yup.boolean()
      .required('Required field'),
    option_vegan: Yup.boolean()
      .required('Required field'),
    happy_hour: Yup.boolean()
      .required('Required field'),
    outdoor_seating: Yup.boolean()
      .required('Required field'),
    delivery: Yup.boolean()
      .required('Required field'),
    pick_up: Yup.boolean()
      .required('Required field'),
    takeout: Yup.boolean()
      .required('Required field'),
    drive_thru: Yup.boolean()
      .required('Required field'),
    sit_down_dining: Yup.boolean()
      .required('Required field'),
    waitstaff_service: Yup.boolean()
      .required('Required field'),
    full_bar: Yup.boolean()
      .required('Required field'),
    sommelier: Yup.boolean()
      .required('Required field'),
    private_dining: Yup.boolean()
      .required('Required field'),
    good_for_breakfast: Yup.boolean()
      .required('Required field'),
    good_for_lunch: Yup.boolean()
      .required('Required field'),
    good_for_dinner: Yup.boolean()
      .required('Required field'),
    good_for_late_night: Yup.boolean()
      .required('Required field'),
    accepts_reservations: Yup.boolean()
      .required('Required field'),
  }).required(),

  general_amenity: Yup.object().shape({
    accepts_credit: Yup.boolean()
      .required('Required field'),
    accepts_apple_pay: Yup.boolean()
      .required('Required field'),
    accepts_google_pay: Yup.boolean()
      .required('Required field'),
    pet_friendly: Yup.boolean()
      .required('Required field'),
    free_wifi: Yup.boolean()
      .required('Required field'),
    good_for_dates: Yup.boolean()
      .required('Required field'),
    good_for_kids: Yup.boolean()
      .required('Required field'),
    good_for_groups: Yup.boolean()
      .required('Required field'),
    street_parking: Yup.boolean()
      .required('Required field'),
    garage_parking: Yup.boolean()
      .required('Required field'),
    parking_available: Yup.boolean()
      .required('Required field'),
    valet_parking: Yup.boolean()
      .required('Required field'),
    bike_parking: Yup.boolean()
      .required('Required field'),
    smoke_free: Yup.boolean()
      .required('Required field'),
    smoking_area_available: Yup.boolean()
      .required('Required field'),
    powder_room_available: Yup.boolean()
      .required('Required field'),
    wheelchair_accessible: Yup.boolean()
      .required('Required field'),
    tv: Yup.boolean()
      .required('Required field'),
    gender_neutral_restroom_available: Yup.boolean()
      .required('Required field'),
    bilingual_staff: Yup.boolean()
      .required('Required field'),
    public_transportation_nearby: Yup.boolean()
      .required('Required field'),
    quiet_ambiance: Yup.boolean()
      .required('Required field'),
  }).required(),
});

const covidAmenityKeys = [
  'required_masks',
  'staff_wears_masks',
  'staff_wears_gloves',
  'social_distancing',
  'limited_capacity',
  'checks_temperature',
  'hand_sanitizer',
  'staff_sanitizes_between_customers',
  'curbside_pick_up',
];

const generalAmenityKeys = [
  'accepts_credit',
  'accepts_apple_pay',
  'accepts_google_pay',
  'pet_friendly',
  'free_wifi',
  'good_for_dates',
  'good_for_kids',
  'good_for_groups',
  'street_parking',
  'garage_parking',
  'parking_available',
  'valet_parking',
  'bike_parking',
  'smoke_free',
  'smoking_area_available',
  'powder_room_available',
  'wheelchair_accessible',
  'tv',
  'gender_neutral_restroom_available',
  'bilingual_staff',
  'public_transportation_nearby',
  'quiet_ambiance',
];

const fbAmenityKeys = [
  'option_vegetarian',
  'option_vegan',
  'happy_hour',
  'outdoor_seating',
  'delivery',
  'pick_up',
  'takeout',
  'drive_thru',
  'sit_down_dining',
  'waitstaff_service',
  'full_bar',
  'sommelier',
  'private_dining',
  'good_for_breakfast',
  'good_for_lunch',
  'good_for_dinner',
  'good_for_late_night',
  'accepts_reservations',
];

const removeHTML = (dataHTML) => {
  const div = document.createElement('div');
  div.innerHTML = dataHTML;
  let text = div.textContent || div.innerText || '';
  text = text.replace(/(\r\n|\n|\r)$/, '');
  return text;
};

export { InitState, ValidationScheme, covidAmenityKeys, generalAmenityKeys, fbAmenityKeys, removeHTML };
