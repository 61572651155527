import { Field, FormikErrors, FormikTouched } from 'formik';
import _ from 'lodash';
import React from 'react';
import LocalizedStrings from 'react-localization';

interface MyProps {
  userLanguage: number;
  values: any,
  touched: FormikTouched<any>,
  errors: FormikErrors<any>,
  setFieldError: (field: string, message: string | undefined) => void,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
  nextPage: () => void,
  unit: '¥' | '$',
  setInputValue?: any,
  inputValue: any
}

export default function FirstForm({ userLanguage, values, touched, errors, setFieldValue, nextPage, setFieldError, unit, setInputValue, inputValue }: MyProps): JSX.Element {
  const numberAnswerMaximum = 4;
  const localize = new LocalizedStrings({
    jp: {
      step1: '1. 提供するクーポンの種類は？ *',
      step2: '2. クーポンの利用規約（任意項目）',
      step3: '3. クーポンの識別用ID（任意項目）',
      step4: '4. アンケートを付けますか？ *',

      step2_text: '利用規約はプロファイルページで設定した内容が反映されます。ここでさらに加筆することが可能で、利用時にユーザーへ知らせます。',
      step3_text: 'ユーザーはクーポン利用時に店員へ画面を提示します。その際、画面に表示されるIDを設定できます。設定することで決済が容易になります。',
      step4_text: 'ここでアンケートを設定すると、このクーポンを利用する際に回答を要求します。回答は任意ですが、ユーザーには回答のお礼に、ZOOOのサウンドポイントで還元されます。',

      no: '付けない',
      yes: '付ける',

      percentage_discount: 'パーセンテージで割引',
      discount_value: '割引率 *: %',
      maximum_discount: '適応される最大金額を設定する（任意項目）: ' + unit,
      percent_off: '% オフ',

      discounting_specific: '割引する額を指定',
      enter_value: '割引額 *: ' + unit,
      minimum_discount: '適応される最小金額を設定する（任意項目）',
      off: 'オフ',

      free_item: '限定で一品、タダ',
      what_offering: 'このクーポンで何を提供しますか？ *',
      enter_item_value: '商品の価格を入力（任意項目）:' + unit,

      survey_title: 'ユーザーのどんな話題について聞きたいですか？',
      maximum_length_100: '最大、100文字の文字数を入力できます',
      answer_options: '回答の選択肢 *',
      maximum_length_40: '最大、40文字の文字数を入力できます',
      can_multiple_answer: '回答は複数選択可能としますか？ *',
      _yes: 'はい',
      _no: 'いいえ',

      next: '次へ',

      required: '必須項目が入力されていません',
      invalid_discount_value: '入力値が無効です。数値で最小値は1です。',
      invalid_maximum_amount: '入力値が無効です。数値で最小値は1です。',
      invalid_minimum_amount: '入力値が無効です。数値で最小値は1です。',
      invalid_free_item: '入力値が無効です。文字である必要があり、空を許容しません。',
      invalid_item_value: '入力値が無効です。数値で最小値は1です。',
      invalid_survey_question: '必須項目。',
      invalid_survey_choices: '回答として選べる選択肢を二つ以上、用意する必要があります。それぞれ、空であると無効です。',
      invalid_survey_choices_answer: '回答を2つ以上入力してください。',
    },
    en: {
      step1: '1. What type of deal are you offering?*',
      step2: '2. Coupon Disclaimer (Optional)',
      step3: '3. Coupon Number (Voucher ID) (Optional)',
      step4: '4. Do you want to attach a survey to this coupon? *',

      step2_text: 'Service disclaimer will be populated from the Business Profile page. If you want to add extra disclaimer specifically for this coupon, please set it up below.',
      step3_text: 'If you want to set up a specific coupon number for your POS, please enter below. If not specified, the ID for this coupon will be [000001]. You will need this ID to apply discount when customers use this coupon.',
      step4_text: 'When you create a survey, this coupon will be locked until the shopper answers the question. As an incentive for providing their input, they will receive a small number of Sound Points for submitting their answer, the cost of which will be covered by ZOOO.',

      no: 'No',
      yes: 'Yes',

      percentage_discount: 'Percentage Discount',
      discount_value: 'Enter the discount value *: %',
      maximum_discount: 'Set the maximum discount value (Optional): ' + unit,
      percent_off: '% off',

      discounting_specific: 'Discounting A Specific Amount',
      enter_value: 'Enter the value *: ' + unit,
      minimum_discount: 'Set minimum purchase amount (Optional): ' + unit,
      off: 'off',

      free_item: 'Free Item',
      what_offering: 'What are you offering? *:',
      enter_item_value: 'Enter the item value (Optional): ' + unit,

      survey_title: 'What do you want to know about your customers? *',
      maximum_length_100: 'Up to 100 characters including spaces',
      answer_options: 'Answer Options *',
      maximum_length_40: 'Up to 40 characters including spaces',
      can_multiple_answer: 'Can the shopper select multiple answers? *',
      _yes: 'Yes',
      _no: 'No',

      next: 'Next',

      required: 'Required items are not entered',
      invalid_discount_value: 'Invalid this value. The minimum value for this value is 1.',
      invalid_maximum_amount: 'Invalid this value. The minimum value for this value is 1.',
      invalid_minimum_amount: 'Invalid this value. The minimum value for this value is 1.',
      invalid_free_item: 'Invalid this value. The value must be string and not empty.',
      invalid_item_value: 'Invalid this value. The minimum value for this value is 1.',
      invalid_survey_question: 'Please enter a survey item.',
      invalid_survey_choices: 'Must be set 2 or more values for answer options this survey. Not empty.',
      invalid_survey_choices_answer: 'Please enter two or more answers.',
    },
  });
  localize.setLanguage(userLanguage === 1 ? 'jp' : 'en');

  values.discount_type = values.discount_type + '';
  values.discount_value = values.discount_value + '';
  values.maximum_amount = values.maximum_amount + '';
  values.minimum_amount = values.minimum_amount + '';
  values.item_value = values.item_value + '';
  values.survey.include_multiple_answers = values.survey.include_multiple_answers + '';
  values.is_survey = values.is_survey + '';
  setInputValue(values);
  return (
    <>
      <div className="content clearfix">
        <section className="body current" role="tabpanel" aria-labelledby="steps-uid-0-h-1" aria-hidden={false}>
          
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <h3 className="card-title m-t-30 m-b-30">{localize.step1}</h3>
                <div className="nav nav-tabs">
                  <ul className="list-unstyled">
                    <li>
                      <div>
                        <Field id="radio1" name="discount_type" type="radio" value="1"
                          onClick={e=> {
                            setInputValue({...values, discount_type: parseInt(e.target.value)});
                          }} />
                        <label htmlFor="radio1">{localize.percentage_discount}</label>
                      </div>
                    </li>

                    <li>
                      <div>
                        <Field id="radio2" name="discount_type" type="radio" value="2" 
                          onClick={e=> {
                            setInputValue({...values, discount_type: parseInt(e.target.value)});
                          }}/>
                        <label htmlFor="radio2">{localize.discounting_specific}</label>
                      </div>
                    </li>

                    <li>
                      <div>
                        <Field id="radio3" name="discount_type" type="radio" value="3" 
                          onClick={e=> {
                            setInputValue({...values, discount_type: parseInt(e.target.value)});
                          }}/>
                        <label htmlFor="radio3">{localize.free_item}</label>
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="">
                  {values.discount_type + '' === '1' && (
                    <div>
                      <div className="row m-t-30">
                        <div className="col-7">
                          <label htmlFor="aaa">{localize.discount_value}</label>{' '}
                        </div>
                        <div className="col-3">
                          <Field type="number" className="form-control required" id="aaa" name="discount_value" min="1" max="100" placeholder="20" 
                            onKeyUp={(e) => {                              
                              setInputValue({...values, discount_value: parseInt(e.target.value)});
                              setFieldValue('headline', inputValue.headline);
                            }}/>
                        </div>
                        <div className="col-2">
                          {localize.percent_off}
                        </div>
                      </div>

                      <div className="row m-t-20">
                        <div className="col-7">
                          <label htmlFor="bbb">{localize.maximum_discount}</label>{' '}
                        </div>
                        <div className="col-3">
                          <Field type="number" className="form-control" id="bbb" name="maximum_amount" min="1" max="100" placeholder="15"
                            onKeyUp={(e) => {
                              setInputValue({...values, maximum_amount: parseInt(e.target.value)});
                              setFieldValue('discount_description', inputValue.discount_description);
                            }} />
                        </div>
                      </div>
                    </div>
                  )}
                  {values.discount_type + '' === '2' && (
                    <div>
                      <div className="row m-t-30">
                        <div className="col-7">
                          <label htmlFor="ccc">{localize.enter_value}</label>
                        </div>
                        <div className="col-3">
                          <Field type="number" className="form-control" id="ccc" name="discount_value" placeholder="20" 
                            onKeyUp={(e) => {
                              setInputValue({...values, discount_value: parseInt(e.target.value)});
                              setFieldValue('headline', inputValue.headline);
                            }} />
                        </div>
                        <div className="col-2">{localize.off}</div>
                      </div>
                      <div className="row m-t-20">
                        <div className="col-7">
                          <label htmlFor="ddd">{localize.minimum_discount}</label>
                        </div>
                        <div className="col-3">
                          <Field type="number" className="form-control" id="ddd" name="minimum_amount" placeholder="75" 
                            onKeyUp={(e) => {
                              setInputValue({...values, minimum_amount: parseInt(e.target.value)});
                              setFieldValue('discount_description', inputValue.discount_description);
                            }} />
                        </div>
                      </div>
                    </div>
                  )}
                  {values.discount_type + '' === '3' && (
                    <div>
                      <div className="row m-t-30">
                        <div className="col-12">
                          <label htmlFor="eee">{localize.what_offering}</label>
                        </div>
                        <div className="col-10">
                          <Field type="text" className="form-control required" id="eee" name="free_item" placeholder="Slice of cheesecake" maxLength={30} onBlur={() => {
                            setFieldValue('headline', inputValue.headline);
                          }} />
                        </div>
                      </div>
                      <div className="row m-t-20">
                        <div className="col-7">
                          <label htmlFor="fff">{localize.enter_item_value}</label>
                        </div>
                        <div className="col-3">
                          <Field type="number" className="form-control required" id="fff" name="item_value" placeholder="10"
                            onKeyUp={e=> {
                              setInputValue({...values, item_value: parseInt(e.target.value)});
                              setFieldValue('discount_description', inputValue.discount_description);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <h3 className="card-title m-t-30 m-b-30">{localize.step2}</h3>
                <p>{localize.step2_text}</p>
                <Field as="textarea" name="disclaimer" row="5" className="form-control" maxLength={400} />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <h3 className="card-title m-t-30 m-b-30">{localize.step3}</h3>
                <p>{localize.step3_text}</p>
                <Field type="number" className="form-control" name="voucher_id" maxLength={9} />
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <h3 className="card-title m-t-30 m-b-30">{localize.step4}</h3>
                <p>{localize.step3_text}</p>
                <div className="nav nav-tabs">
                  <ul className="list-unstyled">
                    <li>
                      <div>
                        <Field id="_no" name="is_survey" type="radio" value="false" />
                        <label htmlFor="_no">{localize.no}</label>
                      </div>
                    </li>
                    <li>
                      <div>
                        <Field id="_yes" name="is_survey" type="radio" value="true" />
                        <label htmlFor="_yes">{localize.yes}</label>
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="">
                  {values.is_survey + '' === 'true' && (
                    <div className="tab-pane">
                      <ul className="list-unstyled">
                        <h3 className="card-title m-t-30 m-b-30">{localize.survey_title}</h3>
                        <li>
                          <small className="text-right">{localize.maximum_length_100}</small>
                          <Field type="text" className="form-control required" name="survey.question" maxLength={100} placeholder="What's your favorite type of food?" />
                        </li>
                        <li>
                          <h3 className="card-title m-t-30 m-b-30">{localize.answer_options}</h3>
                          <small>{localize.maximum_length_40}</small>
                          <div className="row">
                            <div className="col-sm-10">
                              <div className="form-group">
                                <div className="input-group">
                                  <div className="row">
                                    {
                                      values?.survey?.choices.map((v,i)=>(
                                        <div className="col-9 m-t-20" key={i} style={{marginBottom:'0 !important'}}>
                                          <Field type="text" className="form-control" name={`survey.choices[${i}]`} maxLength={40} />
                                        </div>
                                      ))
                                    }
                                    <div className="input-group-append m-t-20 col-3">
                                      <button
                                        type="button"
                                        className="btn btn-success survey-add-option-btn"
                                        onClick={(e)=>{
                                          if ( values?.survey?.choices.length >= numberAnswerMaximum) {
                                            e.preventDefault();
                                            return;
                                          }
                                          values?.survey?.choices.push('');
                                          setFieldValue('survey.choices', values?.survey?.choices);
                                        }}
                                      >
                                        <i className="fa fa-plus" />
                                      </button>
                                    </div>
                                    {errors.survey && <p style={{marginLeft: '15px'}}  className="text-danger">{localize.invalid_survey_choices_answer}</p>}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <h3 className="card-title m-t-30 m-b-30">{localize.can_multiple_answer}</h3>
                          <div className="demo-radio-button">
                            <Field type="radio" name="survey.include_multiple_answers" value="true" id="yes__" />
                            <label htmlFor="yes__">{localize._yes}</label>
                            <Field type="radio" name="survey.include_multiple_answers" value="false" id="no__" />
                            <label htmlFor="no__">{localize._no}</label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

        </section>
      </div>

      {/** Actions button */}
      <div className="actions clearfix">
        <ul role="menu" aria-label="Pagination">
          <li className="disabled" aria-disabled="true" style={{display: 'none'}}>
            <a href="/" role="menuitem">Previous</a>
          </li>
          <li aria-hidden="false" aria-disabled="false">
            <a href="/" role="menuitem" onClick={(e)=>{
              values.discount_type = parseInt(values.discount_type);
              values.is_survey = values.is_survey + '' === 'true';
              values.survey ? values.survey.include_multiple_answers = values?.survey?.include_multiple_answers + '' === 'true' : null;

              if (values.discount_type === 1) {
                values.discount_value = parseInt(values.discount_value);
                values.maximum_amount = parseInt(values.maximum_amount || 0);
                if (isNaN(values.discount_value) || values.discount_value < 1) {
                  setFieldError('discount_value', localize.invalid_discount_value);
                  e.preventDefault();
                  return;
                }
              }

              else if (values.discount_type === 2) {
                values.discount_value = parseInt(values.discount_value);
                values.minimum_amount = parseInt(values.minimum_amount || 0);
                if (isNaN(values.discount_value) || values.discount_value < 1) {
                  setFieldError('discount_value', localize.invalid_discount_value);
                  e.preventDefault();
                  return;
                }
              }
              
              else if (values.discount_type === 3) {
                values.item_value = parseInt(values.item_value);
                // eslint-disable-next-line no-irregular-whitespace
                if (typeof values.free_item !== 'string' || !values.free_item.replace(/[ 　]/g, '')) {
                  setFieldError('free_item', localize.invalid_free_item);
                  e.preventDefault();
                  return;
                }
              }

              if (values.is_survey) {
                if (!values?.survey?.question) {
                  setFieldError('survey.question', localize.invalid_survey_question);
                  e.preventDefault();
                  return;
                }
                let long = 0;
                for (const choice of values?.survey?.choices) {
                  if (!!choice && typeof choice === 'string') {
                    long++;
                  }
                }
                if (long<2) {
                  setFieldError('survey.choices[0]', localize.invalid_survey_choices);
                  e.preventDefault();
                  return;
                }
              }

              e.preventDefault();
              nextPage();
            }}>{localize.next}</a>
          </li>
          <li aria-hidden="true" style={{display: 'none'}}>
            <a href="/" role="menuitem">Submit</a>
          </li>
        </ul>
      </div>
      {_.isEmpty(errors) === false &&  <p style={{textAlign:'end', marginRight: '25px'}} className="text-danger">{localize.required}</p>}
    </>
  );
}
